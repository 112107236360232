<template>
    <div>
        <v-progress-linear indeterminate rounded class="my-2" v-if="loading"></v-progress-linear>

        <ActionForm
            v-if="filter"
            :fn="updateFilter"
            @errors="filterForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12" sm="8" lg="7">
                    <v-switch
                        v-model="allow_bots"
                        hide-details
                        :label="System.lang('filters.types.source.allow_bots')"
                    ></v-switch>
                    <v-switch
                        v-model="allow_direct"
                        hide-details
                        :label="System.lang('filters.types.source.allow_direct')"
                    ></v-switch>
                    <v-switch
                        v-model="allow_sites"
                        hide-details
                        :label="System.lang('filters.types.source.allow_sites')"
                    ></v-switch>
                </v-col>
            </v-row>
            <v-expand-transition>
            <v-row v-if="allow_sites">
                <v-col cols="12" sm="8" lg="7">
                    <v-radio-group 
                        v-model="filter.action" 
                        :label="System.lang('filters.types.source.filterSites')"
                        class="mt-0"
                        hide-details
                        :error-messages="filterForm.errors.action"
                    >
                        <v-radio :label="System.lang('filters.actions.0')" :value="0"></v-radio>
                        <v-radio :label="System.lang('filters.actions.1')" :value="1">
                            <template v-slot:label>
                                <div>
                                    <span>{{System.lang('filters.actions.1')}}</span>
                                    <span class="text-caption grey--text ml-1" v-if="!System.isMobile()"> ({{System.lang(`filters.types.${filterType.code}.wldescr`)}})</span>
                                </div>
                            </template>                            
                        </v-radio>
                        <v-radio :label="System.lang('filters.actions.2')" :value="2">
                            <template v-slot:label>
                                <div>
                                    <span>{{System.lang('filters.actions.2')}}</span>
                                    <span class="text-caption grey--text ml-1" v-if="!System.isMobile()"> ({{System.lang(`filters.types.${filterType.code}.bldescr`)}})</span>
                                </div>
                            </template>                            
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            </v-expand-transition>
            <v-expand-transition>
            <v-row class="mb-2" v-if="filter.action != FILTER_ACTION.NONE && allow_sites">
                <v-col cols="12" sm="8" md="6">
                    <v-simple-table 
                        v-if="websites.length"
                        dense
                        fixed-header
                        class="mb-2"
                        :height="(websites.length > 5 ? 200 : undefined)"
                    >
                        <thead>
                            <tr>
                                <th class="text-left">{{System.lang(`filters.types.source.domains.${filter.action}`)}}</th>
                                <th class="text-right">{{websites.length}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in websites" :key="item.id">
                                <td>{{item.domain}}</td>
                                <td class="text-right">
                                    <IconButton
                                        small
                                        color="error"
                                        :icon="mdiCloseBoxOutline"
                                        :hint="System.lang('buttons.delete')"
                                        :fn="() => {del(item)}"
                                    />
                                </td>
                            </tr>    
                        </tbody>
                    </v-simple-table>
                    <div>
                        <ActionButton 
                            color="primary" 
                            :label="System.lang('filters.types.source.addSite')"
                            small 
                            :fn="() => {this.addDialog.open()}"
                        />
                    </div>
                </v-col>
                <v-col cols="12" v-if="(filter.action == FILTER_ACTION.ALLOW && !websites.length)">
                    <v-alert
                        class="mb-0"
                        text
                        dense
                        type="warning"
                    >
                        {{System.lang('filters.types.source.noSelectedWarn')}}
                    </v-alert>
                </v-col>
            </v-row>
            </v-expand-transition>
        </ActionForm>

        <SimpleDialog
            :dialog="addDialog"
            :title="System.lang('filters.types.source.addDialog.title')"
            :icon="mdiPlusBoxOutline"
            @close="addDialog.close()"
            :width="350"
        >
            <SimpleForm 
                @ref="addForm.ref = $event"
                @input="addForm.valid = $event"
            >
                <v-text-field
                    :label="System.lang('filters.types.source.addDialog.domain')"
                    counter="100"
                    v-model="addForm.values.domain"
                    :rules="addForm.rules.domain"
                    :error-messages="addForm.errors.domain"
                    @click="() => {addForm.resetError('domain')}"
                ></v-text-field>
                <p class="text-caption text-center grey--text mt-4">{{System.lang('filters.types.source.addDialog.helpText')}}</p>
            </SimpleForm>

            <template v-slot:actions>
                <ActionButton
                    text
                    :block="false"
                    color="primary" 
                    :label="System.lang('filters.types.source.addDialog.addButton')"
                    :fn="add"
                    :disabled="!addForm.valid"
                />
                <v-spacer></v-spacer>
                <ActionButton
                    text
                    :block="false"
                    color="grey"
                    :label="System.lang('buttons.cancel')"
                    :fn="() => {addDialog.close()}"
                />
            </template>
        </SimpleDialog>

    </div>
</template>


<script>
import {
    mdiEye,
    mdiPlusBoxOutline,
    mdiCloseBoxOutline,
} from '@mdi/js';

import System from '@/classes/System'
import Interface from '@/classes/Interface'
import Links from '@/classes/Links'
import Api from '@/services/api'
import {Form, Dialog} from '@/classes/Elements'
import {FILTER_ACTION} from '@/constants/system'

import ActionForm from '@/components/forms/ActionForm'
import ActionButton from '@/components/system/ActionButton'
import IconButton from '@/components/system/IconButton'
import SimpleDialog from '@/components/system/SimpleDialog'
import SimpleForm from '@/components/forms/SimpleForm'


export default {
    name: 'FilterSource',
    components: {
        ActionForm,
        ActionButton,
        IconButton,
        SimpleDialog,
        SimpleForm,
    },
    props: {
        link: {
            type: [Object, Boolean],
            default: false,
        },
        group: {
            type: [Object, Boolean],
            default: false,
        },
        filterType: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            FILTER_ACTION,
            // Icons
            mdiEye,
            mdiPlusBoxOutline,
            mdiCloseBoxOutline,
            // Internal
            loading: false,
            // Data
            allow_bots: true,
            allow_direct: true,
            allow_sites: true,
            websites: [],
            filter: undefined,
            // Forms
            filterForm: new Form({
                action: [],
                data: [],
            }),
            addForm: new Form({
                domain: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}.]{1,100}$/u.test(v) || System.lang('val.domainpart'),
                ],
            }),
            // Dialogs
            addDialog: new Dialog(() => {this.addForm.reset()}),
        }
    },
    computed: {
        url () {
            if (this.link) return `/links/${this.link.id}/filter/${this.filterType.type}`;
            return `/groups/${this.group.id}/filter/${this.filterType.type}`;
        },
        filterData () {
            return {
                allow_bots: this.allow_bots,
                allow_direct: this.allow_direct,
                allow_sites: this.allow_sites,
                websites: this.websites,
            }
        },
    },
    methods: {
        init () {
            this.fetchFilter();
        },
        // Data fetch functions
        async fetchFilter () {
            this.loading = true;
            this.filter = undefined;

            return Api.get(this.url)
                .then(data => {
                    this.filter = data;
                    this.websites = data.data?.websites ?? [];
                    this.allow_bots = data.data?.allow_bots ?? true;
                    this.allow_direct = data.data?.allow_direct ?? true;
                    this.allow_sites = data.data?.allow_sites ?? true;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // Data update functions
        async updateFilter () {
            this.filter.data = {};
            Object.assign(this.filter.data, this.filterData);

            return Api.patch(this.url, this.filter)
                .then(data => {
                    this.filter = data;
                    this.websites = data.data?.websites ?? [];
                    this.allow_bots = data.data?.allow_bots ?? true;
                    this.allow_direct = data.data?.allow_direct ?? true;
                    this.allow_sites = data.data?.allow_sites ?? true;
                    return data;
                });
        },
        // Data array functions
        add () {
            if (this.addForm.validate()) {
                this.websites.push({
                    id: Links.generateMaxArrId(this.websites),
                    domain: this.addForm.values.domain,
                });

                this.addDialog.close();
            }
            return;
        },
        del (item) {
            this.websites = this.websites.filter(filter => filter.id != item.id);
            return;
        },
    },
    mounted () {
        this.init();
    },

}
</script>


<style scoped>
</style>
