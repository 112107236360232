<template>
    <v-progress-linear indeterminate rounded class="my-2"></v-progress-linear>
</template>


<script>
import Interface from "@/classes/Interface"
import User from '@/classes/User'
import System from '@/classes/System'

export default {
    name: 'ConfirmView',
    components: {
    },
    data () {
        return {
            // Icons
            // Global
            System,
            // Internal
            // Forms
        }
    },
    methods: {
        async confirm () {
            return User.confirm({code: this.$route.params.code})
                .then(() => {
                    Interface.messageSuccess(System.lang('confirm.messages.CONFIRM_OK'));
                })
                .catch(error => {
                    Interface.popupError(System.lang('confirm.messages.CONFIRM_FAILED'), error.message);
                })
                .finally(() => {
                    // System.redirectToLogin();
                    System.redirectToLogin(false);
                });
        },
    },
    computed: {
    },
    watch: {
    },
    mounted () {
        this.confirm();
    },
}
</script>


<style scoped>
</style>
