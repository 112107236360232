<template>
    <div id="chart" ref="chart"></div>
</template>


<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import System from '@/classes/System'

export default {
    name: 'ClicksMapChart',
    components: {
    },
    props: {
        chartdata: {
            type: Array,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            // Data
            root: undefined,
            observer: undefined,
            bubbleSeries: undefined,
        }
    },
    computed: {
    },
    methods: {
        createChart () {
            let root = am5.Root.new(this.$refs.chart);
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5map.MapChart.new(root, {
                    projection: am5map.geoNaturalEarth1(),
                    wheelY: "none",
                })
            );
            chart.set("zoomControl", am5map.ZoomControl.new(root, {}));
            chart.events.on("wheel", function(ev) {
                if (ev.originalEvent.ctrlKey) {
                    ev.originalEvent.preventDefault();
                    chart.set("wheelY", "zoom");
                }
                else {
                    chart.set("wheelY", "none");
                }
            });

            let polygonSeries = chart.series.push(
                am5map.MapPolygonSeries.new(root, {
                    geoJSON: am5geodata_worldLow,
                    exclude: ["AQ"],
                })
            );
            // polygonSeries.mapPolygons.template.setAll({
            //     tooltipText: `{name}`,
            // });
            polygonSeries.mapPolygons.template.setAll({
                fill: am5.color(0x7986CB),
            });
            // polygonSeries.mapPolygons.template.states.create("hover", {
            //     fill: am5.color(0x9FA8DA)
            // });

            let circleTemplate = am5.Template.new({});

            let bubbleSeries = chart.series.push(
                am5map.MapPointSeries.new(root, {
                    calculateAggregates: true,
                    valueField: "value",
                    polygonIdField: "id"
                })
            );

            bubbleSeries.bullets.push(function(root) {
                var container = am5.Container.new(root, {});

                container.children.push(
                    am5.Circle.new(root, {
                    radius: 20,
                    fillOpacity: 0.6,
                    fill: am5.color(0xff0000),
                    cursorOverStyle: "pointer",
                    tooltipText: `{name_ml}\n[fontSize: 0.6rem]${System.lang('stats.charts.2.success')}:[/] [bold]{approved}[/]\n[fontSize: 0.6rem]${System.lang('stats.charts.2.blocked')}:[/] [bold]{blocked}[/]`
                    }, circleTemplate)
                );

                return am5.Bullet.new(root, {
                    sprite: container,
                    dynamic: true
                });
            });

            bubbleSeries.bullets.push(function(root) {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                    text: "{value}",
                    fill: am5.color(0xffffff),
                    populateText: true,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    textAlign: "center"
                    }),
                    dynamic: true
                });
            });

            bubbleSeries.set("heatRules", [{
                target: circleTemplate,
                dataField: "value",
                min: 15,
                max: 25,
                // minValue: 0,
                // maxValue: 200,
                key: "radius"
            }]);

            if (this.chartdata) {
                bubbleSeries.data.setAll(this.chartdata);
            }

            this.root = root;
            this.bubbleSeries = bubbleSeries;
        },
    },
    mounted () {
        this.observer = new IntersectionObserver(entries => {
            if (!this.root && entries[0].isIntersecting) {
                this.createChart();
            }
        });
        this.observer.observe(this.$refs.chart);

        // this.createChart();
        // this.$nextTick(() => this.createChart());
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
    },
    watch: {
        chartdata () {
            this.bubbleSeries.data.setAll(this.chartdata);
        }
    },
}
</script>


<style scoped>
#chart {
    height: 400px;
    font-size: 0.8rem;
}
</style>
