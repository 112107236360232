<template>
    <div>
        <div class="d-flex justify-center align-center content-wrapper mt-5 mt-sm-16">
            <v-sheet elevation="1" rounded="lg" width="400">
                <div class="ma-8 pt-5">
                    <SimpleForm 
                        @ref="resetForm.ref = $event"
                        @input="resetForm.valid = $event"
                        autocomplete="off"
                    >
                        <p class="text-caption mb-5 mt-0 primary--text text-center">{{System.lang('reset.newPassHint')}}</p>
                        <v-text-field 
                            :label="System.lang('reset.resetForm.password')"
                            :hint="System.lang('reset.resetForm.passwordHint')"
                            :type="show ? 'text' : 'password'"
                            counter="100"
                            autocomplete="off"
                            name="pass1"
                            v-model="resetForm.values.password"
                            :rules="resetForm.rules.password"
                            :error-messages="resetForm.errors.password"
                            :append-icon="show ? mdiEye : mdiEyeOff"
                            @click:append="show = !show"
                            @click="() => {resetForm.resetError('password')}"
                        ></v-text-field>
                        <v-text-field 
                            :disabled="show"
                            :label="System.lang('reset.resetForm.password2')"
                            :hint="System.lang('reset.resetForm.password2Hint')"
                            type="password"
                            counter="100"
                            autocomplete="off"
                            name="pass2"
                            v-model="resetForm.values.password2"
                            :rules="resetForm.rules.password2"
                            :error-messages="resetForm.errors.password2"
                            :append-icon="show ? mdiEye : mdiEyeOff"
                            @click:append="show = !show"
                        ></v-text-field>
                        <v-btn
                            class="mt-8"
                            block
                            color="primary" 
                            @click="reset"
                            :loading="loading"
                        >
                            {{System.lang('reset.setPass')}}
                        </v-btn>
                    </SimpleForm>

                    <p class="mt-8"><router-link :to="{ name: 'login' }" class="text-body-2">{{System.lang('reset.loginExisting')}}</router-link></p>
                </div>
            </v-sheet>
        </div>
    </div>
</template>


<script>
import {
    mdiAt,
    mdiEye,
    mdiEyeOff,
} from '@mdi/js';

import Interface from "@/classes/Interface"
import User from '@/classes/User'
import System from '@/classes/System'
import {Form} from '@/classes/Elements'
// import Api from '@/services/api'

import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'ResetView',
    components: {
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiAt,
            mdiEye,
            mdiEyeOff,
            // Global
            System,
            // Internal
            loading: false,
            show: false,
            // Forms
            resetForm: new Form({
                password: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}!@#$%^&*()_+:;{}<>,.?~-]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => v?.length >= 8 || System.lang('val.longer'),
                    (v) => v?.length <= 100 || System.lang('val.shorter'),
                ],
                password2: [
                    (v) => (this.show || v == this.resetForm.values.password) || System.lang('val.passmatch'),
                ],
                token: [],
                code: [],
            }),
        }
    },
    methods: {
        async reset () {
            if (this.resetForm.validate()) {
                Interface.messageOff();
                this.loading = true;

                this.resetForm.values.token = await System.recaptcha('reset');
                this.resetForm.values.code = this.$route.params.code;

                return User.reset(this.resetForm.values)
                    .then(() => {
                        Interface.messageSuccess(System.lang('reset.messages.SET_OK'));
                        System.redirectToLogin(false);
                    })
                    .catch(error => {
                        Interface.popupError(System.lang('reset.messages.SET_FAILED'), error.message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
    },
    watch: {
        show (newv) {
            if (newv) {
                this.resetForm.values.password2 = '';
                this.resetForm.resetError('password2');
            }
        },
    },
    mounted () {
        System.setTitle('titles.forgotPass');
        Interface.messageOff();
    },
    // beforeDestroy () {
    //     Interface.messageOff();
    // },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
