import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import en from '@/languages/en'
import es from '@/languages/es'
import de from '@/languages/de'
import ru from '@/languages/ru'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    lang: {
        locales: {en, es, de, ru},
        current: 'en',
    },   
    theme: {
        dark: false,
        themes: {
            light: {
                // primary: '#525FE1',
                // secondary: '#F86F03',
            },
            dark: {},
        },
    },

});
