<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingLink"></v-progress-linear>

    <!-- Info -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="link">
        <HeaderPanel
            :title="System.lang('stats.stats')"
            :icon="mdiFinance"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiArrowLeft"
                :hint="System.lang('stats.backHint')"
                :fn="() => {System.redirectTo('links')}"
            />
            <IconButton
                :icon="mdiCogOutline"
                :hint="System.lang('stats.linkSettings')"
                :fn="() => {System.redirectTo('link', {params: {id: linkID}})}"
            />
            <IconButton
                :icon="mdiTextBoxSearchOutline"
                :hint="System.lang('stats.linkLogs')"
                :fn="() => {System.redirectTo('logs', {params: {id: linkID}})}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="refresh"
            />
        </HeaderPanel>
        <LinkInfoBar :link="link" />
        <div class="my-5 ml-2">
            <p class="text-body-2 grey--text mb-2">{{System.lang('link.linkForm.created')}} <span class="font-weight-medium ml-1">{{human(link.created_at)}}</span></p>
            <p class="text-body-2 grey--text mb-2">{{System.lang('link.linkForm.clicks')}} <span class="font-weight-medium blue--text text--lighten-1 ml-1">{{link.clicks}}</span>            </p>
            <p class="text-body-2 grey--text mb-2">{{System.lang('link.linkForm.clicks_ok')}} <span class="font-weight-medium green--text text--lighten-1 ml-1">{{link.clicks_ok}}</span></p>
            <p class="text-body-2 grey--text mb-2">{{System.lang('link.linkForm.clicks_blocked')}} <span class="font-weight-medium red--text text--darken-4 ml-1">{{link.clicks - link.clicks_ok}}</span></p>
        </div>
        <p class="text-caption grey--text mb-2 mt-7">{{System.lang('stats.statsFor')}}</p>
        <div class="d-flex flex-wrap">
            <a href="#" class="text-body-2 mb-3 mb-md-0" @click.prevent="statsToday">{{System.lang('stats.periods.today')}}<span class="mx-2 grey--text">|</span></a>
            <a href="#" class="text-body-2 mb-3 mb-md-0" @click.prevent="statsYday">{{System.lang('stats.periods.yesterday')}}<span class="mx-2 grey--text">|</span></a>
            <v-menu
                v-model="menuCalDate"
                transition="slide-x-reverse-transition"
                :close-on-content-click="false"
                offset-y
                left
                rounded
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <a href="#" class="text-body-2 mb-3 mb-md-0" v-bind="attrs" v-on="on" @click.prevent="">{{System.lang('stats.periods.day')}}<span class="mx-2 grey--text">|</span></a>
                </template>

                <v-date-picker
                    v-model="statDate"
                    first-day-of-week="1"
                    no-title
                    scrollable
                    :max="today"
                    :min="profile.last_hist_date"
                >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="statsDate(); menuCalDate = false" :disabled="!statDate.length">{{System.lang('buttons.ok')}}</v-btn>
                    <v-btn text color="default" @click="statDate = ''; menuCalDate = false">{{System.lang('buttons.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                </v-date-picker>
            </v-menu>
            <a href="#" class="text-body-2 mb-3 mb-md-0" @click.prevent="statsThisMonth">{{System.lang('stats.periods.thisMonth')}}<span class="mx-2 grey--text">|</span></a>
            <a href="#" class="text-body-2 mb-3 mb-md-0" @click.prevent="statsPrevMonth">{{System.lang('stats.periods.prevMonth')}}<span class="mx-2 grey--text">|</span></a>
            <v-menu
                v-model="menuCalMonth"
                transition="slide-x-reverse-transition"
                :close-on-content-click="false"
                offset-y
                left
                rounded
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <a href="#" class="text-body-2 mb-3 mb-md-0" v-bind="attrs" v-on="on" @click.prevent="">{{System.lang('stats.periods.month')}}<span class="mx-2 grey--text">|</span></a>
                </template>

                <v-date-picker
                    v-model="statMonth"
                    first-day-of-week="1"
                    no-title
                    scrollable
                    type="month"
                    :max="today"
                    :min="profile.last_hist_date"
                >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="statsMonth(); menuCalMonth = false" :disabled="!statMonth.length">{{System.lang('buttons.ok')}}</v-btn>
                    <v-btn text color="default" @click="statMonth = ''; menuCalMonth = false">{{System.lang('buttons.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                </v-date-picker>
            </v-menu>
            <v-menu
                v-model="menuCalDates"
                transition="slide-x-reverse-transition"
                :close-on-content-click="false"
                offset-y
                left
                rounded
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <a href="#" class="text-body-2 mb-3 mb-md-0" v-bind="attrs" v-on="on" @click.prevent="">{{System.lang('stats.periods.selectDates')}}</a>
                </template>

                <v-date-picker
                    v-model="statDates"
                    range
                    first-day-of-week="1"
                    no-title
                    scrollable
                    :max="today"
                    :min="profile.last_hist_date"
                >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="statsDates(); menuCalDates = false" :disabled="statDates.length < 2">{{System.lang('buttons.ok')}}</v-btn>
                    <v-btn text color="default" @click="statDates = []; menuCalDates = false">{{System.lang('buttons.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                </v-date-picker>
            </v-menu>
        </div>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingStats && !loadingLink"></v-progress-linear>

    <div v-if="stats && System.isMobile()" class="mt-4 text-center">
        <DatesPreset :presetText="presetText" />
    </div>

    <!-- Number of clicks -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-3" v-if="stats">
        <HeaderPanel
            :title="System.lang('stats.sections.clicks')"
            :icon="mdiCursorDefaultClick"
            color="success"
            class="mb-6"
        >
            <DatesPreset :presetText="presetText" />
        </HeaderPanel>
        <v-row v-if="stats?.clicks?.total">
            <v-col cols="12">
                <div class="d-flex flex-wrap justify-center text-body-2 grey--text text-center">
                    <div class="pa-2">{{System.lang('stats.charts.1.clicks')}} <span class="font-weight-medium blue--text text--lighten-1 ml-1">{{stats?.clicks?.total}}</span></div>
                    <div class="pa-2">{{System.lang('stats.charts.1.clicks_success')}} <span class="font-weight-medium green--text text--lighten-1 ml-1">{{stats.clicks.success}}</span></div>
                    <div class="pa-2">{{System.lang('stats.charts.1.clicks_blocked')}} <span class="font-weight-medium red--text text--darken-4 ml-1">{{stats.clicks.blocked}}</span></div>
                </div>
                <ClicksLineChart :chartdata="stats.click_stats" :from="dateFrom" :to="dateTo" :granula="granula" />
            </v-col>
            <v-col cols="12" sm="6" v-if="stats?.clicks?.total">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.sources')}}</p>
                <GenericPieChart :chartdata="stats.traffic_stats" />
            </v-col>
            <v-col cols="12" sm="6" v-if="stats?.clicks?.total">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.results')}}</p>
                <ClickResultsChart :chartdata="stats.result_stats" />
            </v-col>
        </v-row>
        <div class="my-5 text-center" v-else>
            <v-icon color="grey" size="30">{{mdiWebOff}}</v-icon>
            <p class="text-caption grey--text mt-3">{{System.lang('stats.noStatsHint')}}</p>
        </div>
    </v-sheet>


    <!-- Geography -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-3" v-if="stats && stats?.clicks?.total">
        <HeaderPanel
            :title="System.lang('stats.sections.geo')"
            :icon="mdiMap"
            color="success"
            class="mb-6"
        >
            <DatesPreset :presetText="presetText" />
        </HeaderPanel>
        <v-row>
            <v-col cols="12">
                <ClicksMapChart :chartdata="(showBots ? stats.geo_all_stats : stats.geo_nobot_stats)" />
                <v-switch
                    v-model="showBots"
                    :label="System.lang('stats.sections.showBots')"
                ></v-switch>
            </v-col>
        </v-row>
    </v-sheet>


    <!-- Devices -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-3" v-if="stats && stats?.clicks?.total">
        <HeaderPanel
            :title="System.lang('stats.sections.devices')"
            :icon="mdiDevices"
            color="success"
            class="mb-6"
        >
            <DatesPreset :presetText="presetText" />
        </HeaderPanel>
        <v-row>
            <v-col cols="12" sm="6">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.deviceTypes')}}</p>
                <GenericPieChart :chartdata="stats.device_stats" />
            </v-col>
            <v-col cols="12" sm="6" v-if="stats?.os_stats?.length">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.os')}}</p>
                <GenericPieChart :chartdata="stats.os_stats" />
            </v-col>
            <v-col cols="12" sm="6" v-if="stats?.locale_stats?.length">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.clientLangs')}}</p>
                <GenericPieChart :chartdata="stats.locale_stats" />
            </v-col>
            <v-col cols="12" sm="6">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.clientTypes')}}</p>
                <GenericPieChart :chartdata="stats.clients_stats" />
            </v-col>
            <v-col cols="12" sm="6" v-if="stats?.browser_stats?.length">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.browsers')}}</p>
                <GenericPieChart :chartdata="stats.browser_stats" />
            </v-col>
            <v-col cols="12" sm="6" v-if="stats?.bot_stats?.length">
                <p class="mb-0 mt-8 text-body-2 grey--text text-center">{{System.lang('stats.sections.searchBots')}}</p>
                <GenericPieChart :chartdata="stats.bot_stats" />
            </v-col>
        </v-row>


    </v-sheet>

</div>
</template>


<script>
import {
    mdiRefresh,
    mdiArrowLeft,
    mdiFinance,
    mdiWeb,
    mdiContentCopy,
    mdiCursorDefaultClick,
    mdiCalendarMonth,
    mdiCogOutline,
    mdiTextBoxSearchOutline,
    mdiDevices,
    mdiMap,
    mdiWebOff,
} from '@mdi/js';

import { mapState } from 'vuex'
import System from '@/classes/System';
import Interface from '@/classes/Interface'
import Dates from '@/classes/Dates'
import Links from '@/classes/Links'
import {STATS_PRESET, STATS_GRANULA} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'

import ClicksLineChart from '@/components/charts/ClicksLineChart'
import GenericPieChart from '@/components/charts/GenericPieChart'
import ClickResultsChart from '@/components/charts/ClickResultsChart'
import ClicksMapChart from '@/components/charts/ClicksMapChart'
import DatesPreset from '@/components/charts/DatesPreset'
import LinkInfoBar from '@/components/parts/LinkInfoBar'

export default {
    name: 'StatsView',
    components: {
        HeaderPanel,
        IconButton,
        LinkInfoBar,
        DatesPreset,
        ClicksLineChart,
        GenericPieChart,
        ClickResultsChart,
        ClicksMapChart,
    },
    data () {
        return {
            // Icons
            mdiRefresh,
            mdiArrowLeft,
            mdiWeb,
            mdiFinance,
            mdiContentCopy,
            mdiCursorDefaultClick,
            mdiCalendarMonth,
            mdiCogOutline,
            mdiTextBoxSearchOutline,
            mdiDevices,
            mdiMap,
            mdiWebOff,
            // Globals
            System,
            Links,
            STATS_PRESET,
            STATS_GRANULA,
            // Internal
            loadingLink: false,
            loadingStats: false,
            menuCalDate: false,
            menuCalDates: false,
            menuCalMonth: false,
            showBots: true,
            // Data
            link: undefined,
            stats: undefined,
            dateFrom: '',
            dateTo: '',
            preset: STATS_PRESET.TODAY,
            presetText: '',
            granula: STATS_GRANULA.DAY,
            statDates: [],
            statDate: '',
            statMonth: '',
            // Data structures
            // Forms
            // Tables
            // Dialogs
        }
    },
    computed: {
        ...mapState({
            profile: state => state.user.profile,
        }),
        linkID () {
            return Number(this.$route.params.id);
        },
        lang () {
            return System.getLanguage(this.$vuetify.lang.current);
        },
        today () {
            return Dates.today();
        },
        statsRequest () {
            return {
                from: this.dateFrom,
                to: this.dateTo,
                granula: this.granula,
                tz: new Date().getTimezoneOffset(),
            };
        },
    },
    methods: {
        init () {
            System.setTitle('titles.stats');
            this.fetchLink();
            this.statsThisMonth();
        },
        refresh () {
            this.fetchLink(false);
            this.fetchStats();
        },
        // Data fetch functions
        async fetchLink (rst = true) {
            if (rst) {
                this.link = undefined;
                this.loadingLink = true;
            }

            return Api.get(`/links/${this.linkID}`)
                .then(data => {
                    this.link = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingLink = false;
                });
        },
        async fetchStats () {
            this.stats = undefined;
            this.loadingStats = true;

            return Api.post(`/links/${this.linkID}/stats`, this.statsRequest)
                .then(data => {
                    this.stats = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingStats = false;
                });
        },
        // Stats presets
        statsToday () {
            this.preset = STATS_PRESET.TODAY;
            this.granula = STATS_GRANULA.HOUR;
            this.dateFrom = Dates.todayStart();
            this.dateTo = Dates.todayEnd();
            this.presetText = System.lang('stats.periods.today');

            return this.fetchStats();
        },
        statsYday () {
            this.preset = STATS_PRESET.YESTERDAY;
            this.granula = STATS_GRANULA.HOUR;
            this.dateFrom = Dates.daysAgoStart(1);
            this.dateTo = Dates.daysAgoEnd(1);
            this.presetText = System.lang('stats.periods.yesterday');

            return this.fetchStats();
        },
        statsThisMonth () {
            this.preset = STATS_PRESET.THIS_MONTH;
            this.granula = STATS_GRANULA.DAY;
            this.dateFrom = Dates.thisMonthStart();
            this.dateTo = Dates.thisMonthEnd();
            this.presetText = System.lang('stats.periods.thisMonth');

            return this.fetchStats();
        },
        statsPrevMonth () {
            this.preset = STATS_PRESET.PREV_MONTH;
            this.granula = STATS_GRANULA.DAY;
            this.dateFrom = Dates.prevMonthStart();
            this.dateTo = Dates.prevMonthEnd();
            this.presetText = System.lang('stats.periods.prevMonth');

            return this.fetchStats();
        },
        statsMonth () {
            this.preset = STATS_PRESET.MONTH;
            this.granula = STATS_GRANULA.DAY;
            this.dateFrom = Dates.monthStart(this.statMonth);
            this.dateTo = Dates.monthEnd(this.statMonth);
            this.presetText = `${this.statMonth.replaceAll('-', '/')}`;
            // this.presetText = this.human(this.statMonth, 1);

            return this.fetchStats();
        },
        statsDates () {
            this.preset = STATS_PRESET.DATES;
            this.granula = (Dates.diffDays(this.statDates[0], this.statDates[1]) < 4 ? STATS_GRANULA.HOUR : STATS_GRANULA.DAY);
            this.dateFrom = Dates.dayStart(this.statDates.sort()[0]);
            this.dateTo = Dates.dayEnd(this.statDates.sort()[1]);
            // this.presetText = `${this.statDates.sort()[0].replaceAll('-', '.')} - ${this.statDates.sort()[1].replaceAll('-', '.')}`;
            this.presetText = this.human(this.statDates.sort()[0], 1) + ' - ' + this.human(this.statDates.sort()[1], 1);
            this.statDates = [];

            return this.fetchStats();
        },
        statsDate () {
            this.preset = STATS_PRESET.DATE;
            this.granula = STATS_GRANULA.HOUR;
            this.dateFrom = Dates.dayStart(this.statDate);
            this.dateTo = Dates.dayEnd(this.statDate);
            // this.presetText = `${this.statDate.replaceAll('-', '.')}`;
            this.presetText = this.human(this.statDate, 1);
            this.statDate = '';

            return this.fetchStats();
        },
        // Data update functions
        // Helper functions
        human (date, format = 2) {
            return Dates.human(date, format);
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        linkID () {
            this.init();
        },
        lang () {
            this.fetchStats();
        },
    },
}
</script>


<style scoped>
</style>
