<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingPlans"></v-progress-linear>    
    
    <!-- Plans -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="plans">
        <HeaderPanel
            :title="System.lang('plans.subscription')"
            :icon="mdiCreditCardOutline"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>

        <div v-if="!profile.email_verified" class="mb-5">
            <v-alert
                border="left"
                colored-border
                type="error"
                elevation="2"
            >
                <p>{{System.lang('plans.emailNotConfirmed')}}</p>
                <router-link :to="{ name: 'account' }">{{System.lang('plans.goToSettings')}}</router-link>
            </v-alert>
        </div>

        <div class="mb-5">
            <v-alert
                border="left"
                colored-border
                type="info"
                elevation="2"
            >
                <p>{{System.lang('plans.betaTestingInfo')}}</p>
            </v-alert>
        </div>

        <div class="mt-5">
            <v-row>
                <v-col cols="12" md="4" v-for="p in plans" :key="p.id">
                    <v-card>
                        <v-card-title>
                            <v-icon large left :color="PLANS[p.code].color">{{PLANS[p.code].icon}}</v-icon>
                            <span :class="`text-h6 font-weight-medium ${PLANS[p.code].style}`">{{System.lang(`plans.plans.${p.code}`)}}</span>
                        </v-card-title>
                        <v-card-text class="text-h5 font-weight-bold primary--text text-center" v-if="p.price > 0">
                            {{p.price}} <span class="text-body-2">{{System.lang('plans.curMonth')}}</span>
                        </v-card-text>
                        <v-card-text class="text-h5 font-weight-bold primary--text text-center" v-else>
                            {{System.lang('plans.noCharge')}}
                        </v-card-text>
                        <div class="my-3 pa-3 text-center">
                            <span v-if="p.code == plan.code" class="text-button white--text success lighten-2 py-2 px-8">
                                {{System.lang('plans.active')}}
                            </span>
                            <ActionButton
                                v-else
                                :disabled="!changeAllowed"
                                color="primary" 
                                :label="System.lang('plans.buttons.activate.label')"
                                :hint="System.lang('plans.buttons.activate.hint')"
                                :fn="() => {}"
                            />
                        </div>
                        <div class="my-3 pa-3 text-center">
                            <p class="text-caption grey--text">Features:</p>
                            <v-simple-table dense>
                                <tbody>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.limit_life')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-if="p.limit_life == 0">{{System.lang('plans.unlimited')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-else>{{p.limit_life}} {{System.lang('plans.months')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.limit_history')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-if="p.limit_history == 0">{{System.lang('plans.unlimited')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-else>{{p.limit_history}} {{System.lang('plans.months')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.limit_logs')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-if="p.limit_logs == 0">{{System.lang('plans.unlimited')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-else>{{p.limit_logs}} {{System.lang('plans.days')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.limit_links')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-if="p.limit_links == 0">{{System.lang('plans.unlimited')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-else>{{p.limit_links.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.limit_groups')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-if="p.limit_groups == 0">{{System.lang('plans.unlimited')}}</td>
                                        <td class="text-center font-weight-medium primary--text" v-else>{{p.limit_groups.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.allow_api')}}</td>
                                        <td class="text-center" v-if="p.allow_api"><v-icon color="success">{{mdiCheck}}</v-icon></td>
                                        <td class="text-center" v-else><v-icon color="error">{{mdiClose}}</v-icon></td>
                                    </tr>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.allow_domains')}}</td>
                                        <td class="text-center" v-if="p.allow_domains"><v-icon color="success">{{mdiCheck}}</v-icon></td>
                                        <td class="text-center" v-else><v-icon color="error">{{mdiClose}}</v-icon></td>
                                    </tr>
                                    <tr>
                                        <td class="text-left grey--text">{{System.lang('plans.allow_keys')}}</td>
                                        <td class="text-center" v-if="p.allow_keys"><v-icon color="success">{{mdiCheck}}</v-icon></td>
                                        <td class="text-center" v-else><v-icon color="error">{{mdiClose}}</v-icon></td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <p class="text-center font-weight-medium primary--text my-8">{{System.lang('plans.offer')}}</p>
        </div>
    </v-sheet>


</div>
</template>


<script>
import {
    mdiCreditCardOutline,
    mdiRefresh,
    mdiCheck,
    mdiClose,
} from '@mdi/js';

import { mapState } from 'vuex'
import System from '@/classes/System';
import User from '@/classes/User';
import Dates from '@/classes/Dates';
import Interface from '@/classes/Interface'
import Api from '@/services/api'
import {PLANS} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import ActionButton from '@/components/system/ActionButton'
// import IconButton from '@/components/system/IconButton'
// import IconHint from '@/components/system/IconHint'

export default {
    name: 'PlansView',
    components: {
        HeaderPanel,
        ActionButton,
        // IconButton,
        // IconHint,
    },
    data () {
        return {
            // Icons
            mdiCreditCardOutline,
            mdiRefresh,
            mdiCheck,
            mdiClose,
            // Globals
            System,
            PLANS,
            // Internal
            loadingPlans: false,
            // Data
            plans: undefined,
            // Tables
            // Forms
            // Dialogs
        }
    },
    computed: {
        ...mapState({
            profile: state => state.user.profile,
            plan: state => state.user.plan,
        }),
        changeAllowed () {
            return false;
            // return this.profile.email_verified;
        },
    },
    methods: {
        init () {
            System.setTitle('titles.plans');
            this.fetchPlans();
        },
        // Data fetch functions
        async fetchPlans () {
            this.plans = undefined;
            this.loadingPlans = true;

            return Api.get('/system/plans')
                .then(data => {
                    this.plans = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingPlans = false;
                });
        },
        // Data update functions
        // Helper functions
        human (date, format = 2) {
            return Dates.human(date, format);
        },
    },
    mounted () {
        this.init();
    },
    beforeDestroy () {
        User.resetSaved();
    },
}
</script>


<style scoped>
</style>
