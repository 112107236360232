<template>
    <div>
        <v-progress-linear indeterminate rounded class="my-2" v-if="loading"></v-progress-linear>

        <ActionForm
            v-if="filter"
            :fn="updateFilter"
            @errors="filterForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12" sm="8" lg="7">
                    <v-radio-group 
                        v-model="filter.action" 
                        :label="System.lang('filters.action')"
                        class="mt-0"
                        hide-details
                        :error-messages="filterForm.errors.action"
                    >
                        <v-radio :label="System.lang('filters.actions.0')" :value="0"></v-radio>
                        <v-radio :label="System.lang('filters.actions.1')" :value="1">
                            <template v-slot:label>
                                <div>
                                    <span>{{System.lang('filters.actions.1')}}</span>
                                    <span class="text-caption grey--text ml-1" v-if="!System.isMobile()"> ({{System.lang(`filters.types.${filterType.code}.wldescr`)}})</span>
                                </div>
                            </template>                            
                        </v-radio>
                        <v-radio :label="System.lang('filters.actions.2')" :value="2">
                            <template v-slot:label>
                                <div>
                                    <span>{{System.lang('filters.actions.2')}}</span>
                                    <span class="text-caption grey--text ml-1" v-if="!System.isMobile()"> ({{System.lang(`filters.types.${filterType.code}.bldescr`)}})</span>
                                </div>
                            </template>                            
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-expand-transition>
            <v-row v-if="filter.action != FILTER_ACTION.NONE">
                <v-col cols="12">
                    <v-autocomplete
                        v-model="filter.data"
                        item-value="code"
                        item-text="name_ml"
                        multiple
                        chips
                        deletable-chips
                        hide-details
                        :items="countries"
                        :label="System.lang(`filters.types.country.countries.${filter.action}`)"
                        :error-messages="filterForm.errors.data"
                        :rules="filterForm.rules.data"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="(filter.action == FILTER_ACTION.ALLOW && !filter.data.length)">
                    <v-alert
                        class="mb-0"
                        text
                        dense
                        type="warning"
                    >
                        {{System.lang('filters.types.country.noSelectedWarn')}}
                    </v-alert>
                </v-col>
            </v-row>
            </v-expand-transition>
        </ActionForm>
    </div>
</template>


<script>
import {
    mdiEye,
} from '@mdi/js';

import System from '@/classes/System'
import Interface from '@/classes/Interface'
import Api from '@/services/api'
import Links from '@/classes/Links';
import {Form} from '@/classes/Elements'
import {FILTER_ACTION} from '@/constants/system'

import ActionForm from '@/components/forms/ActionForm'

export default {
    name: 'FilterCountry',
    components: {
        ActionForm,
    },
    props: {
        link: {
            type: [Object, Boolean],
            default: false,
        },
        group: {
            type: [Object, Boolean],
            default: false,
        },
        filterType: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            FILTER_ACTION,
            // Icons
            mdiEye,
            // Internal
            loading: false,
            // Data
            countries: [],
            filter: undefined,
            // Forms
            filterForm: new Form({
                action: [],
                data: [
                    // (v) => v?.length > 0 || System.lang('val.required'),
                ],
            }),
        }
    },
    computed: {
        url () {
            if (this.link) return `/links/${this.link.id}/filter/${this.filterType.type}`;
            return `/groups/${this.group.id}/filter/${this.filterType.type}`;
        },
    },
    methods: {
        init () {
            this.fetchFilter();
            this.fetchCountries();
        },
        // Data fetch functions
        async fetchFilter () {
            this.loading = true;
            this.filter = undefined;

            return Api.get(this.url)
                .then(data => {
                    this.filter = data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async fetchCountries () {
            return Links.getCountries()
                .then(data => {
                    this.countries = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        // Data update functions
        async updateFilter () {
            return Api.patch(this.url, this.filter)
                .then(data => {
                    this.filter = data;
                    return data;
                });
        },
    },
    mounted () {
        this.init();
    },

}
</script>


<style scoped>
</style>
