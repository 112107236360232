<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingLink"></v-progress-linear>

    <!-- Info -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="link">
        <HeaderPanel
            :title="System.lang('logs.logs')"
            :icon="mdiTextBoxSearchOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiArrowLeft"
                :hint="System.lang('logs.backHint')"
                :fn="() => {System.redirectTo('links')}"
            />
            <IconButton
                :icon="mdiFinance"
                :hint="System.lang('logs.linkStats')"
                :fn="() => {System.redirectTo('stats', {params: {id: linkID}})}"
            />
            <IconButton
                :icon="mdiCogOutline"
                :hint="System.lang('logs.linkSettings')"
                :fn="() => {System.redirectTo('link', {params: {id: linkID}})}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="refresh"
            />
        </HeaderPanel>
        <LinkInfoBar :link="link" />
        <p class="text-caption grey--text mb-2 mt-7">{{System.lang('logs.logsFor')}}</p>
        <div class="d-flex flex-wrap">
                <a href="#" class="text-body-2" @click.prevent="logsToday">{{System.lang('logs.periods.today')}}<span class="mx-2 grey--text">|</span></a>
                <a href="#" class="text-body-2" @click.prevent="logsYday">{{System.lang('logs.periods.yesterday')}}<span class="mx-2 grey--text">|</span></a>
                <v-menu
                    v-model="menuCalDate"
                    transition="slide-x-reverse-transition"
                    :close-on-content-click="false"
                    offset-y
                    left
                    rounded
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <a href="#" class="text-body-2" v-bind="attrs" v-on="on" @click.prevent="">{{System.lang('logs.periods.day')}}</a>
                    </template>

                    <v-date-picker
                        v-model="logDate"
                        first-day-of-week="1"
                        no-title
                        scrollable
                        :max="today"
                        :min="profile.last_log_date"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="logsDate(); menuCalDate = false" :disabled="!logDate.length">{{System.lang('buttons.ok')}}</v-btn>
                        <v-btn text color="default" @click="logDate = ''; menuCalDate = false">{{System.lang('buttons.cancel')}}</v-btn>
                        <v-spacer></v-spacer>
                    </v-date-picker>
                </v-menu>
        </div>
    </v-sheet>


    <!-- Logs -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-3" v-if="link">
        <HeaderPanel
            :title="panelTitle"
            :icon="mdiCalendarMonth"
            color="grey"
            class="mb-6"
        >
            <v-text-field
                class="mt-0 pt-0"
                v-model="searchLogs"
                single-line
                hide-details
                :prepend-inner-icon="mdiMagnify"
                clearable
            ></v-text-field>
        </HeaderPanel>
        <DataTable
            :table="logsTable" 
            :search="searchLogs"
            :customFilter="filterLogs"
            :hide-default-header="!logsTable.hasData()"
            dense
        >
            <template v-slot:[`item.created_at`]="{ item }">
                {{human(item.created_at)}}
            </template>

            <template v-slot:[`item.device_type`]="{ item }">
                <IconHint
                    :icon="DEVICE_TYPES[item.device_type].icon"
                    :hint="System.lang(`logs.devices.${item.device_type}`)"
                    :color="DEVICE_TYPES[item.device_type].iconColor"
                />
            </template>

            <template v-slot:[`item.result`]="{ item }">
                <IconHint
                    :icon="CLICK_RESULTS[item.result].icon"
                    :hint="((item.result == 1 ? '' : System.lang('logs.tables.blocked')) + System.lang(`logs.results.${item.result}`))"
                    :color="CLICK_RESULTS[item.result].iconColor"
                />
            </template>

            <template v-slot:[`item.referer`]="{ item }">
                <span v-if="item.is_bot"></span>
                <span v-else-if="item.is_qr" class="orange--text text--darken-3">{{System.lang(`logs.sources.qr`)}}</span>
                <span v-else-if="!item.referer.length" class="green--text text--accent-4">{{System.lang(`logs.sources.direct`)}}</span>
                <span v-else class="indigo--text text--accent-1">{{item.referer}}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconButton
                    :icon="mdiEye"
                    :hint="System.lang('logs.buttons.view.hint')"
                    color="primary"
                    small
                    :fn="() => {logDialog.open(); fetchLog(item.id); return}"
                />
            </template>
        </DataTable>
        
    </v-sheet>


    <SimpleDialog
        :dialog="logDialog"
        :title="System.lang('logs.logDialog')"
        :icon="mdiTextBoxSearchOutline"
        @close="logDialog.close()"
        :width="600"
    >
        <v-progress-linear indeterminate rounded class="my-2" v-if="loadingLog"></v-progress-linear>

        <div v-if="log">
            <v-simple-table 
                dense
                class="mb-2"
            >
                <!-- When -->
                <thead>
                    <tr>
                        <th class="text-left">{{System.lang('logs.tables.when')}}</th>
                        <th class="text-left">{{human(log.created_at)}}</th>
                    </tr>
                </thead>
                <!-- Source -->
                <thead>
                    <tr>
                        <th class="text-left">{{System.lang('logs.tables.source')}}</th>
                        <th class="text-left" v-if="log.is_bot">{{System.lang('logs.sources.bot')}}</th>
                        <th class="text-left" v-else-if="log.is_qr">{{System.lang('logs.sources.qr')}}</th>
                        <th class="text-left" v-else-if="!log.referer_url.length">{{System.lang('logs.sources.direct')}}</th>
                        <th class="text-left" v-else><a class="text-decoration-none" :href="log.referer_url" target="_blank">{{log.referer_url}}</a></th>
                    </tr>
                </thead>
                <!-- Destination -->
                <thead>
                    <tr>
                        <th class="text-left">{{System.lang('logs.tables.destination')}}</th>
                        <th class="text-left" v-if="log.result == 1"><a class="text-decoration-none" :href="log.to" target="_blank">{{log.to}}</a></th>
                        <th class="text-left error--text" v-else>{{System.lang('logs.tables.blocked') + System.lang(`logs.results.${log.result}`)}}</th>
                    </tr>
                </thead>
                <!-- Geo -->
                <thead>
                    <tr>
                        <th class="text-left orange--text" colspan="2">{{System.lang('logs.tables.geo.label')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.geo.ip')}}</td>
                        <td class="text-caption">{{log.ip}}</td>
                    </tr>    
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.geo.country')}}</td>
                        <td class="text-caption">{{log.country_code}}</td>
                    </tr>    
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.geo.coords')}}</td>
                        <td class="text-caption">{{`${log.latitude}, ${log.longitude}`}}</td>
                    </tr>    
                </tbody>
                <!-- Device -->
                <thead v-if="!log.is_bot">
                    <tr>
                        <th class="text-left orange--text" colspan="2">{{System.lang('logs.tables.device.label')}}</th>
                    </tr>
                </thead>
                <tbody v-if="!log.is_bot">
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.device.type')}}</td>
                        <td class="text-caption">{{System.lang(`logs.devices.${log.device_type}`)}}</td>
                    </tr>    
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.device.brand')}}</td>
                        <td class="text-caption">{{log.device_brand}}</td>
                    </tr>    
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.device.model')}}</td>
                        <td class="text-caption">{{log.device_model}}</td>
                    </tr>    
                </tbody>
                <!-- OS -->
                <thead v-if="!log.is_bot">
                    <tr>
                        <th class="text-left orange--text" colspan="2">{{System.lang('logs.tables.os.label')}}</th>
                    </tr>
                </thead>
                <tbody v-if="!log.is_bot">
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.os.name')}}</td>
                        <td class="text-caption">{{log.os_family + (log.os_name == log.os_family ? '' : ` / ${log.os_name}`)}}</td>
                    </tr>    
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.os.version')}}</td>
                        <td class="text-caption">{{log.os_version}}</td>
                    </tr>    
                </tbody>
                <!-- Bot -->
                <thead v-if="log.is_bot">
                    <tr>
                        <th class="text-left orange--text" colspan="2">{{System.lang('logs.tables.bot.label')}}</th>
                    </tr>
                </thead>
                <tbody v-if="log.is_bot">
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.bot.name')}}</td>
                        <td class="text-caption">{{log.bot_name}}</td>
                    </tr>    
                </tbody>
                <!-- Software -->
                <thead v-if="!log.is_bot">
                    <tr>
                        <th class="text-left orange--text" colspan="2">{{System.lang('logs.tables.soft.label')}}</th>
                    </tr>
                </thead>
                <tbody v-if="!log.is_bot">
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.soft.clientType')}}</td>
                        <td class="text-caption">{{System.lang(`logs.clients.${log.client_type}`)}}</td>
                    </tr>    
                    <tr>
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.soft.locale')}}</td>
                        <td class="text-caption">{{log.lang_code}}</td>
                    </tr>    
                    <tr v-if="log.is_browser">
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.soft.browserName')}}</td>
                        <td class="text-caption">{{log.browser_family + (log.browser_name == log.browser_family ? '' : ` / ${log.browser_name}`)}}</td>
                    </tr>    
                    <tr v-if="log.is_browser">
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.soft.browserVer')}}</td>
                        <td class="text-caption">{{log.browser_version}}</td>
                    </tr>    
                    <tr v-if="!log.is_browser">
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.soft.clientName')}}</td>
                        <td class="text-caption">{{log.client_name}}</td>
                    </tr>    
                    <tr v-if="!log.is_browser">
                        <td class="text-caption grey--text text--darken-1">{{System.lang('logs.tables.soft.clientVer')}}</td>
                        <td class="text-caption">{{log.client_version}}</td>
                    </tr>    
                </tbody>

            </v-simple-table>
        </div>

    </SimpleDialog>


</div>
</template>


<script>
import {
    mdiRefresh,
    mdiArrowLeft,
    mdiTextBoxSearchOutline,
    mdiWeb,
    mdiCogOutline,
    mdiFinance,
    mdiContentCopy,
    mdiCalendarMonth,
    mdiMagnify,
    mdiEye,
    mdiWebOff,
} from '@mdi/js';

import { mapState } from 'vuex'
import System from '@/classes/System';
import Interface from '@/classes/Interface'
import Dates from '@/classes/Dates'
import Api from '@/services/api'
import {LOGS_PRESET, DEVICE_TYPES, CLICK_RESULTS} from '@/constants/system'
import {Table, Dialog} from '@/classes/Elements'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import LinkInfoBar from '@/components/parts/LinkInfoBar'
import DataTable from '@/components/system/DataTable'
import IconHint from '@/components/system/IconHint'
import SimpleDialog from '@/components/system/SimpleDialog'

export default {
    name: 'LogsView',
    components: {
        HeaderPanel,
        IconButton,
        LinkInfoBar,
        DataTable,
        IconHint,
        SimpleDialog,
    },
    data () {
        return {
            // Icons
            mdiRefresh,
            mdiArrowLeft,
            mdiTextBoxSearchOutline,
            mdiWeb,
            mdiCogOutline,
            mdiFinance,
            mdiContentCopy,
            mdiCalendarMonth,
            mdiMagnify,
            mdiEye,
            mdiWebOff,
            // Globals
            System,
            LOGS_PRESET,
            DEVICE_TYPES,
            CLICK_RESULTS,
            // Internal
            loadingLink: false,
            loadingLog: false,
            menuCalDate: false,
            showSearch: false,
            // Data
            link: undefined,
            logs: undefined,
            log: undefined,
            dateFrom: '',
            dateTo: '',
            preset: LOGS_PRESET.TODAY,
            presetText: '',
            logDate: '',
            searchLogs: '',
            // Data structures
            // Forms
            // Tables
            logsTable: new Table([
                {
                    text: System.lang('logs.logsTable.when'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'created_at',
                    cellClass: "no-wrap",
                    width: "50px",
                },
                {
                    text: System.lang('logs.logsTable.device'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'device_type',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('logs.logsTable.os'),
                    sortable: false,
                    filterable: true,
                    align: 'left',
                    value: 'os_family',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('logs.logsTable.browser'),
                    sortable: false,
                    filterable: true,
                    align: 'left',
                    value: 'browser_family',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('logs.logsTable.ip'),
                    sortable: false,
                    filterable: true,
                    align: 'left',
                    value: 'ip',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('logs.logsTable.country'),
                    sortable: false,
                    filterable: true,
                    align: 'left',
                    value: 'country_code',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('logs.logsTable.source'),
                    sortable: false,
                    filterable: true,
                    align: 'left',
                    value: 'referer',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('logs.logsTable.result'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'result',
                    cellClass: "no-wrap",
                    width: "50px",
                },
                {
                    text: System.lang('logs.logsTable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '50px',
                },
            ], 'logs-table', this.fetchLogs),
            // Dialogs
            logDialog: new Dialog(() => {}),
        }
    },
    computed: {
        ...mapState({
            profile: state => state.user.profile,
        }),
        linkID () {
            return Number(this.$route.params.id);
        },
        today () {
            return Dates.today();
        },
        panelTitle () {
            if (this.preset == LOGS_PRESET.TODAY) return System.lang('logs.periods.today');
            if (this.preset == LOGS_PRESET.YESTERDAY) return System.lang('logs.periods.yesterday');
            // return this.logDate.replaceAll('-', '.');
            return this.human(this.logDate, 1);
        },
        logsRequest () {
            return {
                from: this.dateFrom,
                to: this.dateTo,
                tz: new Date().getTimezoneOffset(),
            };
        },
        searchIsOn () {
            return false;
        },
    },
    methods: {
        init () {
            System.setTitle('titles.logs');
            this.fetchLink();
            this.logsToday();
        },
        refresh () {
            this.logsTable.load();
        },
        // Data fetch functions
        async fetchLink () {
            this.link = undefined;
            this.loadingLink = true;

            return Api.get(`/links/${this.linkID}`)
                .then(data => {
                    this.link = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingLink = false;
                });
        },
        async fetchLog (id) {
            this.log = undefined;
            this.loadingLog = true;

            return Api.get(`/links/${this.linkID}/logs/${id}`)
                .then(data => {
                    this.log = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingLog = false;
                });
        },
        async fetchLogs () {
            this.logs = [];

            return Api.post(`/links/${this.linkID}/logs`, this.logsRequest)
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        // Stats presets
        logsToday () {
            this.preset = LOGS_PRESET.TODAY;
            this.dateFrom = Dates.todayStart();
            this.dateTo = Dates.todayEnd();
            this.logsTable.load();
        },
        logsYday () {
            this.preset = LOGS_PRESET.YESTERDAY;
            this.dateFrom = Dates.daysAgoStart(1);
            this.dateTo = Dates.daysAgoEnd(1);
            this.logsTable.load();
        },
        logsDate () {
            this.preset = LOGS_PRESET.DATE;
            this.dateFrom = Dates.dayStart(this.logDate);
            this.dateTo = Dates.dayEnd(this.logDate);
            // this.logDate = '';
            this.logsTable.load();
        },
        // Helper functions
        filterLogs (value, search, item) {
            return  value?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.os_family?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.browser_family?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.ip?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.country_code?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.referer?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase())
        },
        human (date, format = 2) {
            return Dates.human(date, format);
        },
        // viewLog (id) {
        //     this.selectedLogId = id;
        //     this.logDialog.open();
        // },
    },
    mounted () {
        this.init();
    },
    watch: {
        linkID () {
            this.init();
        },
        // lang () {
        //     this.fetchLogs();
        // },
    },
}
</script>


<style scoped>
</style>
