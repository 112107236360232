<template>
    <div id="chart" ref="chart"></div>
</template>


<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import System from '@/classes/System'

export default {
    name: 'GenericPieChart',
    components: {
    },
    props: {
        chartdata: {
            type: Array,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            // Data
            root: undefined,
            observer: undefined,
        }
    },
    computed: {
    },
    methods: {
        createChart () {
            let root = am5.Root.new(this.$refs.chart);
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5percent.PieChart.new(root, {})
            );

            let series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    categoryField: "item",
                    valueField: "count",
                })
            );
            series.slices.template.set('tooltipText', '{category}: {value}');
            series.labels.template.set('text', '{category}: {value}');
            
            series.data.setAll(this.chartdata);

            this.root = root;
        },
    },
    mounted () {
        this.observer = new IntersectionObserver(entries => {
            if (!this.root && entries[0].isIntersecting) {
                this.createChart();
            }
        });
        this.observer.observe(this.$refs.chart);

        // this.createChart();
        // this.$nextTick(() => this.createChart());
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
    },
}
</script>


<style scoped>
#chart {
    height: 250px;
    font-size: 0.8rem;
}
</style>
