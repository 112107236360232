import merge from 'lodash/merge';

// Application default settings 

let settings = {
    // Application version
    appVersion: process.env.VUE_APP_VERSION,

    // API URL
    // Type: String ''
    // apiBaseUrl: '/gui',
    // apiBaseUrl: 'http://localhost/urls-account-backend/gui',
    apiBaseUrl: process.env.VUE_APP_API_URL,


    // Public assets URL base
    // Type: String ''
    pubBaseUrl: '/info',
 

    // Application URL base
    // Use if application is not served from the root
    // Type: String ''
    // appBaseUrl: '/',
 

    // Use nice URLs without hash #
    // Requires setting on server side to redirect all requests to app file
    // Type: Boolean true/false
    useNiceUrl: true,


    // Use POST instead of PATCH and DELETE methods
    // Type: Boolean true/false
    usePostMethod: true,


    // Console debug enabled
    // Type: Boolean
    debug: false,

    
    // reCAPTCHA site key
    // Type: String ''
    // captchaSiteKey: 'xxx',
    captchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,

    
};

merge(settings, window.$settings);


export default settings;
