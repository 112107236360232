<template>
    <v-data-table
        :custom-filter="customFilter"
        :ref="table.name"
        :show-select="showSelect"
        class="data-table"
        :headers="table.headers"
        :items="table.data"
        :loading="table.loading"
        :footer-props="{'items-per-page-options': TABLES_PP}"
        :items-per-page.sync="items_pp"
        :mobile-breakpoint="mb"
        :search="search"
        v-on="$listeners"
        v-bind="$attrs"
        hide-default-footer
        :page.sync="currentPage"
        @update:items-per-page="System.saveSetting('tables.' +  table.name + '.items_pp', $event)"
        @input="$emit('selected', $event)"
    >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
            <slot :name="name" v-bind="scope" />
        </template>

        <template v-slot:footer="{props}" v-if="table.hasData()">
            <v-divider class="my-3">{{props}}</v-divider>
            <div class="d-md-flex justify-space-between text-center mb-3">
                <div class="d-flex align-center justify-center mx-2">
                    <span class="text-caption grey--text text--darken-2">{{System.lang('interface.rowsPerPage')}}</span>
                    <v-select
                        class="mt-0 pt-0 ml-2 text-caption"
                        style="max-width: 50px"
                        v-model="items_pp"
                        :items="perPageItems"
                        single-line
                        hide-details
                    >
                    </v-select>
                </div>
                <div class="mt-3 mt-sm-0" v-if="props.pagination.pageCount > 1">
                    <v-pagination
                        v-model="currentPage"
                        :total-visible="totalVisible"
                        :length="props.pagination.pageCount"
                    ></v-pagination>
                </div>
            </div>
        </template>

    </v-data-table>
</template>


<script>
// import {
//     mdiExitToApp,
// } from '@mdi/js';

import { TABLES_PP } from "@/constants/interface";
import System from '@/classes/System'

export default {
    name: 'DataTable',
    props: {
        table: {
            type: Object,
            required: true,
        },
        mb: {
            type: Number,
            required: false,
            default: undefined,
        },
        search: {
            type: String,
            required: false,
        },
        customFilter: {
            required: false,
            default: undefined,
        },
        showSelect: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            // Globals
            TABLES_PP,
            System,
            // Icons
            // mdiExitToApp,
            // Data
            currentPage: 1,
            totalVisible: 10,
            items_pp: undefined,
            perPageItems: [
                {value: 1,   text: '1'},
                {value: 5,   text: '5'},
                {value: 10,  text: '10'},
                {value: 25,  text: '25'},
                {value: 50,  text: '50'},
                {value: 100, text: '100'},
                {value: -1,  text: System.lang('interface.allItems')},
            ],
        }
    },
    methods: {
    },
    mounted () {
        this.items_pp = System.loadSetting('tables.' +  this.table.name + '.items_pp', 10);
    },

}
</script>


<style scoped>
.row-pointer >>> tbody tr:not(.default-pointer) :hover {
    cursor: pointer;
}

.data-table >>> table thead tr th {
    white-space: nowrap;
}

* >>> .no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
