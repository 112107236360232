// Interface constants

const MT = {
    LINK: 1,
    SEPA: 2,
    HEAD: 3,
    GRP:  4,
}

const MSG = {
    INFO:    1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR:   4,
    PLAN:    5,
}

// Table settings
const TABLES_PP = [1, 5, 10, 25, 50, 100, -1];

// Languages
// Default language must be first
// If you need to add new language then it also must be added in:
// - src/languages (new translation file named by locale)
// - src/plugins/vuetify.js (import statement and lang.locales object)
// - public/images/languages (flag image)

const LANGS = [
    { locale: 'en', title: 'English',  image: 'en.png' },
    { locale: 'es', title: 'Español',  image: 'es.png' },
    { locale: 'de', title: 'Deutsch',  image: 'de.png' },
    { locale: 'ru', title: 'Русский',  image: 'ru.png' },
];


// Main menu configuration
// Following types are suported:
// - MT.HEAD - Small subheader in menu
// - MT.LINK - Regular menu item link
// - MT.SEPA - Separator line
// - MT.GRP  - Collapseable group
// Before adding new menu items you have to load corresponding icons below

import {
    mdiDomain,
    mdiAccount,
    mdiCreditCardOutline,
    mdiKeyOutline,
    mdiLink,
    mdiChatQuestion,
} from '@mdi/js';

const MAIN_MENU = [
    {id: 1, type: MT.HEAD, title: 'menu.urls'},
    {id: 2, type: MT.LINK, title: 'menu.myurls', icon: mdiLink, to: {name: 'links'}},
    {id: 3, type: MT.LINK, title: 'menu.domains', icon: mdiDomain, to: {name: 'domains'}},
    {id: 4, type: MT.HEAD, title: 'menu.settings'},
    {id: 5, type: MT.LINK, title: 'menu.account', icon: mdiAccount, to: {name: 'account'}},
    {id: 6, type: MT.LINK, title: 'menu.plans', icon: mdiCreditCardOutline, to: {name: 'plans'}},
    {id: 7, type: MT.LINK, title: 'menu.apikeys', icon: mdiKeyOutline, to: {name: 'api-keys'}},
    {id: 8, type: MT.HEAD, title: 'menu.help'},
    {id: 9, type: MT.LINK, title: 'menu.support', icon: mdiChatQuestion, to: {name: 'support'}},
]



export {MT, MSG, MAIN_MENU, LANGS, TABLES_PP};

