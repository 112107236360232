<template>
    <v-dialog v-model="show" :max-width="width" v-bind="$attrs">
        <v-card>
            <div class="d-flex justify-left align-start pt-2 px-3 px-sm-5 mb-5">
                <v-icon left v-if="icon" :color="iconColor" class="mt-2">{{icon}}</v-icon>
                <div class="ml-2">
                    <p :class="'mb-0 overflow-hidden text-no-wrap text-button ' + titleColor">{{title}}</p>
                    <p class="text-caption grey--text mb-0" style="margin-top: -5px;" v-if="hint">{{hint}}</p>
                </div>
            </div>
            <div class="px-6">
                <slot></slot>
            </div>
            <v-card-actions class="mt-4">
                <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn text min-width="150" :color="iconColor" @click="show = false">
                        {{System.lang('buttons.close')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import System from "@/classes/System";
// import Interface from "@/classes/Interface";

export default {
    name: 'SimpleDialog',
    inheritAttrs: false,
    props: {
        dialog: {
            type: Object,
            required: true,
        },
        iconColor: {
            type: String,
            default: 'primary',
        },
        titleColor: {
            type: String,
            default: 'grey--text text--darken-1',
        },
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: [String],
            default: '',
        },
        hint: {
            type: [String, Boolean],
            default: false,
        },
        width: {
            type: Number,
            default: 600,
        },
    },
    data () {
        return {
            // Globals
            System,
        }
    },
    computed: {
        show: {
            get () { return this.dialog.show; },
            set (value) { this.$emit('close', value); }
        }        
    },
    methods: {
    },
}
</script>


<style scoped>
</style>
