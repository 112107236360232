<template>
    <v-menu open-on-hover left offset-y transition="scroll-y-transition" :close-on-content-click="true" close-delay="200">
        <template v-slot:activator="{on}">
            <v-avatar size="20" class="mr-1"><v-img :src="require(`@/assets/images/languages/${current.image}`)" v-on="on"></v-img></v-avatar>
        </template>

        <v-sheet tile>
            <v-list>
                <template v-for="lang in LANGS">
                    <v-list-item :key="lang.locale" link @click="change(lang.locale)">
                        <v-list-item-avatar size="20">
                            <v-img :src="require(`@/assets/images/languages/${lang.image}`)"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{lang.title}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-sheet>
    </v-menu>
</template>


<script>
import System from '@/classes/System'

import {LANGS} from '@/constants/interface';

export default {
    name: 'LangMenu',
    data () {
        return {
            LANGS,
        }
    },
    computed: {
        current () {
            return System.getLanguage(this.$vuetify.lang.current);
        },
    },
    methods: {
        change (locale) {
            System.setLanguage(locale);
        }
    },
}
</script>


<style scoped>
</style>
