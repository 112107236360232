// function getUnsavedState () {
//     return {
//         selectedGroupId: undefined,
//         searchLinks: undefined,
//         searchLinksStatus: undefined,
//         searchLinksType: undefined,
//         currentPage: undefined,
//         itemsPerPage: undefined,
//     }
// }

function getDefaultState () {
    return {
        profile: undefined,
        plan: undefined,
        links: undefined,
        groups: undefined,
        saveData: undefined,
    }
}

const state = getDefaultState();

const getters = {};

const mutations = {
    resetState (state) {
        Object.assign(state, getDefaultState());
    },
    setProfile (state, data) {
        state.profile = data;
    },
    setPlan (state, data) {
        state.plan = data;
    },
    resetSaved (state) {
        state.saveData = undefined;
    },
    setSaved (state, data) {
        state.saveData = data;
    },
    setConfirmed (state) {
        if (state.profile?.email_verified === false) {
            state.profile.email_verified = true;
        }
    },
    setGroups (state, data) {
        state.groups = data;
    },
    addGroup (state, data) {
        state.groups.push(data);
    },
    setLinks (state, data) {
        state.links = data;
    },
    addLink (state, data) {
        state.links.push(data);
    },
    updateLink (state, data) {
        let link = state.links?.find(l => l.id == data.id);
        if (link) Object.assign(link, data);
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }