<template>
<div>
    <!-- Support -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5">
        <HeaderPanel
            :title="System.lang('support.support')"
            :hint="System.lang('support.supportHint')"
            :icon="mdiChatQuestion"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <div class="mt-3">
            <v-divider class="my-3"></v-divider>
            <div class="d-sm-flex">
                <div class="text-center pa-3">
                    <v-icon x-large color="grey">{{mdiFaceAgent}}</v-icon>
                    <p class="mt-2 mb-1 text-caption grey--text">{{System.lang('support.emailHint')}}</p>
                </div>
                <div class="text-center pa-3">
                    <p class="mt-0 mb-1">
                        <a class="text-decoration-none font-weight-medium" :href="'mailto:' + systemData.support_email">{{systemData.support_email}}</a>
                    </p>
                    <IconButton
                        color="primary"
                        :icon="mdiContentCopy"
                        :hint="System.lang('buttons.copy.hint')"
                        :fn="() => {System.copy(systemData.support_email)}"
                    />
                </div>
            </div>
            <v-divider class="my-3"></v-divider>
        </div>
    </v-sheet>


</div>
</template>


<script>
import {
    mdiChatQuestion,
    mdiFaceAgent,
    mdiContentCopy,
} from '@mdi/js';

import { mapState } from 'vuex'
import System from '@/classes/System';
import User from '@/classes/User';

import HeaderPanel from '@/components/system/HeaderPanel'
// import ActionButton from '@/components/system/ActionButton'
import IconButton from '@/components/system/IconButton'
// import IconHint from '@/components/system/IconHint'

export default {
    name: 'SupportView',
    components: {
        HeaderPanel,
        // ActionButton,
        IconButton,
        // IconHint,
    },
    data () {
        return {
            // Icons
            mdiChatQuestion,
            mdiFaceAgent,
            mdiContentCopy,
            // Globals
            System,
            // Internal
            // Data
            // Tables
            // Forms
            // Dialogs
        }
    },
    computed: {
        ...mapState({
            systemData: state => state.system.data,
        }),
    },
    methods: {
        init () {
            System.setTitle('titles.support');
        },
        // Data fetch functions
        // Data update functions
        // Helper functions
    },
    mounted () {
        this.init();
    },
    beforeDestroy () {
        User.resetSaved();
    },
}
</script>


<style scoped>
</style>
