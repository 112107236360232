<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingGroup"></v-progress-linear>

    <!-- Settings -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="group">
        <HeaderPanel
            :title="System.lang('group.group')"
            :icon="mdiFolderOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiArrowLeft"
                :hint="System.lang('group.backHint')"
                :fn="() => {System.redirectTo('links')}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <ActionForm
            :fn="updateGroup"
            @errors="groupForm.errors = $event"
        >
            <v-row>
                <v-col cols="12" sm="5" md="7">
                    <v-text-field
                        :label="System.lang('group.groupForm.name')"
                        :hint="System.lang('group.groupForm.nameHint')"
                        counter="100"
                        v-model="group.name"
                        :rules="groupForm.rules.name"
                        :error-messages="groupForm.errors.name"
                        :prepend-icon="mdiTextBoxEditOutline"
                        @click="() => {groupForm.resetError('name')}"
                    ></v-text-field>
                </v-col>
                <v-col cols="9" sm="4" md="3">
                    <v-select
                        :items="groupStatusItems"
                        :label="System.lang('group.groupForm.status')"
                        v-model="group.status"
                        :rules="groupForm.rules.status"
                        :error-messages="groupForm.errors.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="item.iconColor">{{item.icon}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:prepend>
                            <v-icon :color="GROUP_STATUSES[group.status].iconColor">{{GROUP_STATUSES[group.status].icon}}</v-icon>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="9" sm="3" md="2" class="d-flex align-center pt-0">
                    <div class="pt-1">
                        <v-icon color="grey darken-1">{{mdiPaletteOutline}}</v-icon>
                    </div>
                    <div class="pl-2 flex-grow-1">
                        <p class="text-caption grey--text text--darken-1 mb-1">{{System.lang('group.groupForm.color')}}</p>
                        <ColorPicker v-model="group.color" :width="'100%'" :height="'28px'" />
                    </div>
                </v-col>
                <v-col cols="12" v-if="(group.status == GROUP_STATUS.DISABLED)">
                    <v-alert
                        class="mb-0"
                        text
                        dense
                        type="warning"
                    >
                        {{System.lang('group.groupForm.disabledWarn')}}
                    </v-alert>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- Filters -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-5" v-if="group">
        <HeaderPanel
            :title="System.lang('group.filters')"
            :icon="mdiTrafficLight"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <v-row>
            <v-col cols="12" sm="5">
                <v-select
                    v-model="selectedFilter"
                    :items="filterTypes"
                    item-value="type"
                    item-text="type"
                    return-object
                    :label="System.lang('group.filterType')"
                    :hint="System.lang(`filters.types.${selectedFilter.code}.descr`)"
                    persistent-hint
                    @change="() => {}"
                >
                    <template v-slot:prepend>
                        <v-icon left :color="selectedFilter.iconColor">{{selectedFilter.icon}}</v-icon>
                    </template>

                    <template v-slot:selection="{ item }">
                        <!-- <v-icon left :color="item.iconColor">{{item.icon}}</v-icon> -->
                        {{System.lang(`filters.types.${item.code}.name`)}}
                    </template>

                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-icon class="mr-3">
                                <v-icon left :color="item.iconColor">{{item.icon}}</v-icon>
                            </v-list-item-icon>                                
                            <v-list-item-content>
                                <v-list-item-title>{{System.lang(`filters.types.${item.code}.name`) || ''}}</v-list-item-title>
                                <v-list-item-subtitle>{{System.lang(`filters.types.${item.code}.descr`) || ''}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <div class="mt-5" style="min-height: 180px;">
            <FilterSource :group="group" :filterType="selectedFilter" v-if="selectedFilter.code == 'source'" />
            <FilterCountry :group="group" :filterType="selectedFilter" v-if="selectedFilter.code == 'country'" />
            <FilterIP :group="group" :filterType="selectedFilter" v-if="selectedFilter.code == 'ip'" />
            <FilterDevice :group="group" :filterType="selectedFilter" v-if="selectedFilter.code == 'device'" />
        </div>
    </v-sheet>


    <!-- Delete -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-5" v-if="group">
        <HeaderPanel
            :title="System.lang('group.delete')"
            :hint="System.lang('group.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :ok="System.lang('group.messages.GROUP_DELETED')"
            :confirm="System.lang('group.confirmDelete')"
            :confirmHint="System.lang('group.confirmDeleteHint')"
            :fn="deleteGroup"
        />
    </v-sheet>

</div>
</template>


<script>
import {
    mdiLink,
    mdiLinkPlus,
    mdiRefresh,
    mdiCogOutline,
    mdiCheck,
    mdiClose,
    mdiMagnify,
    mdiArrowLeft,
    mdiWeb,
    mdiContentCopy,
    mdiArrowDownThick,
    mdiCalendarRemoveOutline,
    mdiTrafficLight,
    mdiFolderOutline,
    mdiTextBoxEditOutline,
    mdiKeyOutline,
    mdiArrowDecisionOutline,
    mdiDeleteForever,
    mdiFinance,
    mdiPaletteOutline,
} from '@mdi/js';

import System from '@/classes/System';
import User from '@/classes/User';
import Interface from '@/classes/Interface'
import Links from '@/classes/Links'
import {Form} from '@/classes/Elements'
import Api from '@/services/api'
import {GROUP_STATUS, GROUP_STATUSES} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import ActionForm from '@/components/forms/ActionForm'
import FilterSource from '@/components/filters/FilterSource'
import FilterCountry from '@/components/filters/FilterCountry'
import FilterIP from '@/components/filters/FilterIP'
import FilterDevice from '@/components/filters/FilterDevice'
import DeleteActionButton from '@/components/parts/DeleteActionButton'
import ColorPicker from '@/components/system/ColorPicker'

export default {
    name: 'GroupView',
    components: {
        HeaderPanel,
        IconButton,
        ActionForm,
        FilterSource,
        FilterCountry,
        FilterIP,
        FilterDevice,
        DeleteActionButton,
        ColorPicker,
    },
    data () {
        return {
            // Icons
            mdiLink,
            mdiLinkPlus,
            mdiRefresh,
            mdiCogOutline,
            mdiCheck,
            mdiClose,
            mdiMagnify,
            mdiArrowLeft,
            mdiWeb,
            mdiContentCopy,
            mdiArrowDownThick,
            mdiCalendarRemoveOutline,
            mdiTrafficLight,
            mdiFolderOutline,
            mdiTextBoxEditOutline,
            mdiKeyOutline,
            mdiArrowDecisionOutline,
            mdiDeleteForever,
            mdiFinance,
            mdiPaletteOutline,
            // Globals
            System,
            GROUP_STATUS,
            GROUP_STATUSES,
            // Internal
            loadingGroup: false,
            // Data
            group: undefined,
            selectedFilter: undefined,
            // Data structures
            // Forms
            groupForm: new Form({
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^.{0,100}$/.test(v) || System.lang('val.shorter'),
                ],
                status: [],
                color: [],
            }),
            // Tables
            // Dialogs
        }
    },
    computed: {
        groupID () {
            return Number(this.$route.params.id);
        },
        groupStatusItems () {
            return [
                {value: 1, text: System.lang('group.status.1'), icon: GROUP_STATUSES[1].icon, iconColor: GROUP_STATUSES[1].iconColor},
                {value: 2, text: System.lang('group.status.2'), icon: GROUP_STATUSES[2].icon, iconColor: GROUP_STATUSES[2].iconColor},
            ]
        },
        filterTypes () {
            return Links.getFilterTypes();
        },
    },
    methods: {
        init () {
            System.setTitle('titles.group');
            this.fetchGroup();
            this.selectedFilter = this.filterTypes[0];
        },
        // Data fetch functions
        async fetchGroup () {
            this.group = undefined;
            this.loadingGroup = true;

            return Api.get(`/groups/${this.groupID}`)
                .then(data => {
                    this.group = data;
                    this.groupForm.resetErrors();
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingGroup = false;
                });
        },
        // Data update functions
        async updateGroup () {
            return Api.patch(`/groups/${this.groupID}`, {...this.group})
                .then(data => {
                    this.group = data;
                    this.groupForm.resetErrors();
                    return data;
                });
        },
        async deleteGroup () {
            return Api.delete(`/groups/${this.groupID}`, {})
                .then(data => {
                    System.redirectTo('links');
                    return data;
                });
        },
        // Helper functions
    },
    mounted () {
        this.init();
    },
    beforeDestroy () {
        User.resetSaved();
    },
    watch: {
        groupID () {
            this.init();
        },
    },
}
</script>


<style scoped>
</style>
