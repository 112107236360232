<template>
<div>
    <div class="d-flex justify-center align-center content-wrapper mt-5 mt-sm-16">
        <v-sheet elevation="1" rounded="lg" width="350">
            <div class="ma-8 pt-10">
                <v-form ref="loginForm">
                    <v-text-field 
                        :label="System.lang('login.username.label')"
                        :hint="System.lang('login.username.hint')"
                        ref="username"
                        type="email"
                        autocomplete="username"
                        required
                        v-model="username"
                        :append-icon="mdiAt"
                        :rules="rules.username"
                    ></v-text-field>
                    <v-text-field 
                        :label="System.lang('login.password.label')"
                        :hint="System.lang('login.password.hint')"
                        ref="password"
                        :type="show ? 'text' : 'password'"
                        autocomplete="current-password"
                        required
                        v-model="password"
                        :append-icon="show ? mdiEye : mdiEyeOff"
                        :rules="rules.password"
                        :error-messages="error"
                        @click:append="show = !show"
                        @keyup.enter="login()"
                    ></v-text-field>
                    <div class="mt-8">
                        <v-btn
                            block
                            color="primary" 
                            :loading="loading"
                            @click="login()"
                        >
                            {{System.lang('login.buttons.login')}}
                        </v-btn>
                    </div>
                    <div class="mt-10">
                        <v-checkbox 
                            v-model="save"
                            :label="System.lang('login.remember')"
                        ></v-checkbox>
                    </div>
                </v-form>
                <p class="mt-3 mb-2 pa-0"><router-link :to="{name: 'remind'}" class="text-body-2">{{System.lang('login.forgot')}}</router-link></p>
                <p class="ma-0 pa-0"><router-link :to="{name: 'register'}" class="text-body-2">{{System.lang('login.register')}}</router-link></p>
            </div>
        </v-sheet>
    </div>
</div>
</template>


<script>
import {
    mdiAccount,
    mdiAt,
    mdiEye,
    mdiEyeOff,
} from '@mdi/js';

import Interface from "@/classes/Interface";
import User from '@/classes/User'
import System from '@/classes/System'

export default {
    name: 'LoginView',
    data () {
        return {
            // Icons
            mdiAccount,
            mdiAt,
            mdiEye,
            mdiEyeOff,
            // Objects
            System,
            // Internal
            show: false,
            loading: false,
            // Forms
            error: [],
            rules: {
                username: [
                    (v) => !!v || System.lang('login.username.errors[0]'),
                    (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || System.lang('val.email'),
                    // (v) => /.+@.+\..+/.test(v) || System.lang('login.username.errors[1]'),
                ],
                password: [
                    (v) => !!v || System.lang('login.password.errors[0]'),
                    (v) => /^[\p{L}\p{N}!@#$%^&*()_+:;{}<>,.?~-]*$/u.test(v) || System.lang('val.invalid'),
                ],
            },
            // Data
            username: '',
            password: '',
            save: false,
            token: '',
        }
    },
    methods: {
        async login () {
            if (this.$refs.loginForm.validate()) {
                this.$refs.loginForm.resetValidation();
                this.error = [] 
                this.loading = true;
                
                this.token = await System.recaptcha('login');

                return User.login(this.loginForm)
                    .then(() => {
                        System.restoreRoute('home');
                    })
                    .catch(error => {
                        if (['LOGIN_FAILED'].includes(error.code)) {
                            this.error.push(System.lang('login.errors[0]'));
                            this.$refs.password.focus();
                        } else {
                            Interface.popupError(System.lang('login.errors[1]'), error.message);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
        loginForm () {
            return {
                username: this.username,
                password: this.password,
                save: this.save,
                token: this.token,
            }
        }
    },
    mounted () {
        System.setTitle('titles.login');
    },
    beforeDestroy () {
        Interface.messageOff();
    },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
