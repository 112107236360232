<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingGroups"></v-progress-linear>

    <!-- Group Selector -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="groups">
        <v-row>
            <v-col cols="12" sm="6">
                <div class="d-flex">
                    <v-autocomplete
                        v-model="selectedGroup"
                        :items="groups"
                        item-text="name"
                        return-object
                        :label="System.lang('groups.groups')"
                        hide-details
                        dense
                        outlined
                        class="mr-1"
                        @change="changeGroup"
                    >
                        <template v-slot:prepend>
                            <v-icon left :color="selectedGroup?.color ?? 'success'">{{mdiFolderOutline}}</v-icon>
                        </template>

                        <template v-slot:selection="{ item }">
                            {{groupName(item)}}
                        </template>

                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-icon>
                                    <v-icon :color="item.color ?? 'success'">{{mdiFolderOutline}}</v-icon>
                                </v-list-item-icon>                                
                                <v-list-item-content>
                                    <v-list-item-title>{{groupName(item)}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <IconButton
                        v-if="System.isMobile()"
                        :icon="mdiDotsHorizontal"
                        :fn="() => {showGroupButtons = !showGroupButtons}"
                    />
                </div>
            </v-col>
            <v-col class="text-right" v-if="!System.isMobile() || showGroupButtons">
                <IconButton
                    color="primary"
                    :icon="mdiFolderPlusOutline"
                    :hint="System.lang('groups.newGroupHint')"
                    :fn="() => {newGroupDialog.open()}"
                />
                <IconButton
                    v-if="!selectedGroup?.is_main"
                    color="orange"
                    :icon="mdiCogOutline"
                    :hint="System.lang('groups.groupSettingsHint')"
                    :fn="() => {System.redirectTo('group', {params: {id: selectedGroup.id}})}"
                />
            </v-col>
        </v-row>
    </v-sheet>

    <!-- Links -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-3" v-if="selectedGroup">
        <HeaderPanel
            :title="`${System.lang('links.links')}`"
            :icon="mdiLink"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiLinkPlus"
                :hint="System.lang('links.buttons.new.hint')"
                :fn="() => {newLinkDialog.open()}"
            />
            <IconButton
                v-if="links?.length"
                :color="searchLinksIsOn ? 'orange' : showLinkSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('links.buttons.search.hint')"
                :fn="() => {showLinkSearch = !showLinkSearch}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="fetchLinks"
            />
        </HeaderPanel>
        <v-alert
            v-if="selectedGroup?.status == GROUP_STATUS.DISABLED"
            class=""
            text
            dense
            type="warning"
        >
            {{System.lang('groups.disabled')}}
        </v-alert>
        <v-data-table
            v-if="types"
            class="data-table"
            v-model="selectedLinks"
            :items="links"
            :headers="linksHeaders" 
            :search="searchLinks"
            :loading="loadingLinks"
            :show-select="true"
            :custom-filter="filterLinksText"
            :page.sync="currentPage"
            :sort-by.sync="currentSortBy"
            :sort-desc.sync="currentSortDesc"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{'items-per-page-options': TABLES_PP}"
            :hide-default-header="!links?.length || System.isMobile()"
            hide-default-footer
            @update:items-per-page="System.saveSetting('tables.links-table.items_pp', $event)"
        >
            <template v-slot:[`item`]="{ item, isSelected, select }" v-if="System.isMobile()">
                <div class="pt-3 pb-2 d-flex align-start" style="border-top: 1px solid #EEEEEE">
                    <v-icon class="mt-1" left :color="LINK_TYPES[typeById(item.link_type_id).code].iconColor">{{LINK_TYPES[typeById(item.link_type_id).code].icon}}</v-icon>
                    <div>
                        <p class="my-0 primary--text text-no-wrap text-body-2 font-weight-medium"><a :href="item.from" target="_blank" class="text-decoration-none">{{item.from}}</a></p>
                        <p class="my-0 text-caption grey--text" v-if="item.descr">{{item.descr}}</p>
                        <p class="my-0 text-caption grey--text">{{System.lang('links.linksTable.clicks')}}: {{item.clicks}}</p>
                        <div class="mt-2 pa-2 rounded grey lighten-5">
                            <IconButton
                                :icon="mdiContentCopy"
                                :hint="System.lang('buttons.copy.hint')"
                                color="primary"
                                :fn="() => {System.copy(item.from)}"
                            />
                            <IconButton
                                :icon="mdiQrcode"
                                :hint="System.lang('links.linksTable.qrHint')"
                                color="primary"
                                :fn="() => {openQr(item)}"
                            />
                            <IconButton
                                :icon="mdiFinance"
                                :hint="System.lang('links.linksTable.statsHint')"
                                color="primary"
                                :fn="() => {System.redirectTo('stats', {params: {id: item.id}})}"
                            />
                            <IconButton
                                :icon="mdiTextBoxSearchOutline"
                                :hint="System.lang('links.linksTable.logHint')"
                                color="orange"
                                :fn="() => {System.redirectTo('logs', {params: {id: item.id}})}"
                            />
                            <IconButton
                                :icon="mdiCogOutline"
                                :hint="System.lang('buttons.config.hint')"
                                color="orange"
                                :fn="() => {System.redirectTo('link', {params: {id: item.id}})}"
                            />
                        </div>
                    </div>
                    <div class="ml-auto mr-3">
                        <v-icon :color="LINK_STATUSES[item['status']].iconColor">{{LINK_STATUSES[item['status']].icon}}</v-icon>
                    </div>
                    <div>
                        <v-checkbox
                            class="mt-0"
                            :input-value="isSelected"
                            @change="select"
                        ></v-checkbox>
                    </div>
                </div>
            </template>

            <template v-slot:[`body.prepend`]="{isMobile}" v-if="links?.length && showLinkSearch">
                <div v-if="isMobile" class="pb-3 px-3">
                    <v-text-field
                        v-model="searchLinks"
                        :label="System.lang('links.linksTable.search')"
                        single-line
                        hide-details
                        dense
                        clearable
                        outlined
                    ></v-text-field>
                </div>
                <tr v-else>
                    <td class="px-1"></td>
                    <td class="px-1">
                        <v-select
                            v-model="searchLinksType"
                            :items="linkTypeItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        >
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <v-list-item-icon class="mr-3">
                                        <v-icon :color="item.iconColor">{{item.icon}}</v-icon>
                                    </v-list-item-icon>                                
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.text}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>

                            <template v-slot:selection="{ item }">
                                <span v-if="item.value == 0">{{item.text}}</span>
                                <v-icon v-else :color="item.iconColor">{{item.icon}}</v-icon>
                            </template>
                        </v-select>
                    </td>
                    <td colspan="2" class="px-1">
                        <v-text-field
                            v-model="searchLinks"
                            :label="System.lang('links.linksTable.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="searchLinksStatus"
                            :items="linkStatusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        >
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <v-list-item-icon class="mr-3">
                                        <v-icon :color="LINK_STATUSES[item.value].iconColor">{{LINK_STATUSES[item.value].icon}}</v-icon>
                                    </v-list-item-icon>                                
                                    <v-list-item-content>
                                        <v-list-item-title>{{System.lang(`links.status.${item.value}`) || ''}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </td>
                    <td>
                        <IconButton
                            v-if="searchLinksIsOn"
                            :icon="mdiCloseCircle"
                            :hint="System.lang('links.linksTable.resetSearch')"
                            :fn="resetLinksSearch"
                        />
                    </td>
                </tr>
            </template>

            <template v-slot:[`item.link_type_id`]="{ item }">
                <IconHint
                    :icon="LINK_TYPES[typeById(item.link_type_id).code].icon"
                    :hint="System.lang(`links.types.${typeById(item.link_type_id).code}.name`)"
                    :color="LINK_TYPES[typeById(item.link_type_id).code].iconColor"
                />
            </template>

            <template v-slot:[`item.from`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <p v-bind="attrs" v-on="on" class="my-0 primary--text text-no-wrap font-weight-medium"><a :href="item.from" target="_blank" class="text-decoration-none">{{item.from}}</a></p>
                    </template>
                    <span>{{ item.to }}</span>
                </v-tooltip>
                <p class="my-0 text-caption grey--text">{{item.descr}}&nbsp;</p>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="LINK_STATUSES[item['status']].icon"
                    :hint="System.lang('links.status.' + item['status'])"
                    :color="LINK_STATUSES[item['status']].iconColor"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconButton
                    :icon="mdiContentCopy"
                    :hint="System.lang('buttons.copy.hint')"
                    color="primary"
                    :fn="() => {System.copy(item.from)}"
                />
                <IconButton
                    :icon="mdiQrcode"
                    :hint="System.lang('links.linksTable.qrHint')"
                    color="primary"
                    :fn="() => {openQr(item)}"
                />
                <IconButton
                    :icon="mdiFinance"
                    :hint="System.lang('links.linksTable.statsHint')"
                    color="primary"
                    :fn="() => {System.redirectTo('stats', {params: {id: item.id}})}"
                />
                <IconButton
                    :icon="mdiTextBoxSearchOutline"
                    :hint="System.lang('links.linksTable.logHint')"
                    color="orange"
                    :fn="() => {System.redirectTo('logs', {params: {id: item.id}})}"
                />
                <IconButton
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="orange"
                    :fn="() => {System.redirectTo('link', {params: {id: item.id}})}"
                />
            </template>

            <template v-slot:no-data>
                <div class="my-3">
                    <v-divider class="my-3"></v-divider>
                    <v-icon color="grey" size="30">{{mdiLinkPlus}}</v-icon>
                    <p class="mt-3 mb-1 text-caption grey--text">{{System.lang('links.linksTable.noData1')}}</p>
                    <p class="text-caption primary--text">{{System.lang('links.linksTable.noData2')}}</p>
                </div>
            </template>

            <template v-slot:no-results>
                <div class="my-5">
                    <v-icon color="grey" size="30">{{mdiSelectionSearch}}</v-icon>
                    <p class="mt-3 mb-1 text-caption grey--text">{{System.lang('links.linksTable.noResults')}}</p>
                </div>
            </template>

            <template v-slot:loading>
                <div class="my-5">
                    <v-icon color="grey" size="30">{{mdiDownloadOutline}}</v-icon>
                    <p class="mt-3 mb-1 text-caption grey--text">{{System.lang('links.linksTable.loading')}}</p>
                </div>
            </template>

            <template v-slot:footer="{props}" v-if="links?.length">
                <v-divider class="my-3">{{props}}</v-divider>
                <div class="d-md-flex justify-space-between text-center mb-3">
                    <div class="d-flex align-center justify-center mx-2">
                        <span class="text-caption grey--text text--darken-2">{{System.lang('interface.rowsPerPage')}}</span>
                        <v-select
                            class="mt-0 pt-0 ml-2 text-caption"
                            style="max-width: 50px"
                            v-model="itemsPerPage"
                            :items="perPageItems"
                            single-line
                            hide-details
                        >
                        </v-select>
                    </div>
                    <div class="mt-3 mt-sm-0" v-if="props.pagination.pageCount > 1">
                        <v-pagination
                            v-model="currentPage"
                            :total-visible="totalVisible"
                            :length="props.pagination.pageCount"
                        ></v-pagination>
                    </div>
                </div>
            </template>
        </v-data-table>

        <div v-if="someLinksSelected">
            <v-divider class="my-3"></v-divider>
            <span class="text-caption grey--text text--darken-2 mt-5 mt-sm-0 mx-2 text-center d-block d-sm-inline">
                {{System.lang('links.selected')}} (<span class="font-weight-medium primary--text">{{selectedLinks.length}}</span>):
            </span>
            <ActionButton
                text
                :icon="mdiFolderArrowLeftRightOutline"
                color="primary" 
                :label="System.lang('links.buttons.move.label')"
                :hint="System.lang('links.buttons.move.hint')"
                :fn="() => {moveDialog.open()}"
            />
            <ActionButton
                v-if="canEnableSelected"
                text
                :icon="mdiCheck"
                color="primary" 
                :label="System.lang('links.buttons.enable.label')"
                :hint="System.lang('links.buttons.enable.hint')"
                :confirm="System.lang('links.confirmEnable')"
                :confirmHint="System.lang('links.confirmEnableHint')"
                :fn="enableLinks"
            />
            <ActionButton
                v-if="canDisableSelected"
                text
                :icon="mdiClose"
                color="orange" 
                :label="System.lang('links.buttons.disable.label')"
                :hint="System.lang('links.buttons.disable.hint')"
                :confirm="System.lang('links.confirmDisable')"
                :confirmHint="System.lang('links.confirmDisableHint')"
                confirmColor="orange"
                :confirmIcon="mdiClose"
                :fn="disableLinks"
            />
            <ActionButton
                text
                :icon="mdiDeleteOutline"
                color="error" 
                :label="System.lang('links.buttons.delete.label')"
                :hint="System.lang('links.buttons.delete.hint')"
                :confirm="System.lang('links.confirmDelete')"
                :confirmHint="System.lang('links.confirmDeleteHint')"
                confirmColor="error"
                :confirmIcon="mdiDeleteOutline"
                :fn="deleteLinks"
            />
        </div>
        <div>
            <v-divider class="my-3"></v-divider>
            <ActionButton
                :icon="mdiLinkPlus"
                color="primary" 
                :label="System.lang('links.buttons.new.label')"
                :hint="System.lang('links.buttons.new.hint')"
                :fn="() => {newLinkDialog.open()}"
            />
        </div>
    </v-sheet>


    <SimpleDialog
        :dialog="newLinkDialog"
        :title="System.lang('links.newLinkDialog')"
        :icon="mdiLinkPlus"
        @close="newLinkDialog.close()"
        :width="800"
        v-if="types && domains"
    >
        <SimpleForm 
            @ref="newLinkForm.ref = $event"
            @input="newLinkForm.valid = $event"
        >
            <v-row class="mt-5">
                <v-col cols="12" sm="4">
                    <v-select
                        :disabled="showAddedLink"
                        v-model="selectedType"
                        :items="types"
                        item-value="id"
                        item-text="id"
                        return-object
                        :label="System.lang('links.newLinkForm.type')"
                        :error-messages="newLinkForm.errors.link_type_id"
                        hide-details
                        @change="() => {this.$refs.newlinkdata.resetValidation(); this.newLinkData = ''; this.$refs.newlinkdata.focus()}"
                        @click="() => {this.$refs.newlinkdata.resetValidation()}"
                    >
                        <template v-slot:prepend>
                            <v-icon left :color="LINK_TYPES[selectedType.code].iconColor">{{LINK_TYPES[selectedType.code].icon}}</v-icon>
                        </template>

                        <template v-slot:selection="{ item }">
                            <span>{{System.lang(`links.types.${item.code}.name`)}}</span>
                        </template>

                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-icon class="mr-3">
                                    <v-icon :color="LINK_TYPES[item.code].iconColor">{{LINK_TYPES[item.code].icon}}</v-icon>
                                </v-list-item-icon>                                
                                <v-list-item-content>
                                    <v-list-item-title>{{System.lang(`links.types.${item.code}.name`) || ''}}</v-list-item-title>
                                    <v-list-item-subtitle>{{System.lang(`links.types.${item.code}.descr`) || ''}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        :disabled="showAddedLink"
                        ref="newlinkdata"
                        autofocus
                        :label="System.lang(`links.types.${selectedType?.code}.dataName`) || ''"
                        :hint="System.lang(`links.types.${selectedType?.code}.dataHint`) || ''"
                        counter="500"
                        v-model="newLinkData"
                        :rules="Links.type(selectedType?.id).rules"
                        :error-messages="newLinkForm.errors.data"
                        @click="() => {newLinkForm.resetError('data')}"
                    ></v-text-field>
                </v-col>
            </v-row>

            <div class="text-center my-2 pt-5" v-if="addedLink" v-show="showAddedLink">
                <p class="text-caption grey--text mb-3">{{ System.lang('links.newLinkForm.yourNewLink') }}</p>
                <p class="font-weight-medium"><a :href="addedLink.from" target="_blank" class="text-decoration-none">{{addedLink.from}}</a></p>
                <div>
                    <IconButton
                        color="primary"
                        :icon="mdiContentCopy"
                        :hint="System.lang('buttons.copy.hint')"
                        :fn="() => {System.copy(addedLink.from)}"
                    />
                    <IconButton
                        :icon="mdiCogOutline"
                        :hint="System.lang('buttons.config.hint')"
                        color="orange"
                        :fn="() => {System.redirectTo('link', {params: {id: addedLink.id}})}"
                    />
                </div>
            </div>

            <div class="my-8 my-sm-5" v-show="!showAddedLink">
                <v-checkbox
                    v-model="showNewLinkAdv"
                    :label="System.lang('links.newLinkForm.advanced')"
                    :hint="System.lang('links.newLinkForm.advancedHint')"
                    persistent-hint
                    @change="resetNewLinkAdv"
                ></v-checkbox>
            </div>
            <v-expand-transition>
            <div v-show="showNewLinkAdv && !showAddedLink">
                <div>
                    <v-row>
                        <v-col cols="12" sm="7">
                            <v-text-field
                                :label="System.lang('links.newLinkForm.descr')"
                                :hint="System.lang('links.newLinkForm.descrHint')"
                                counter="100"
                                v-model="newLinkForm.values.descr"
                                :rules="newLinkForm.rules.descr"
                                :prepend-icon="mdiTextBoxEditOutline"
                                :error-messages="newLinkForm.errors.descr"
                                @click="() => {newLinkForm.resetError('descr')}"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="menuLinkExpires"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="newLinkForm.values.valid_till"
                                        :label="System.lang('links.newLinkForm.expiry')"
                                        :hint="System.lang('links.newLinkForm.expiryHint')"
                                        :prepend-icon="mdiCalendarRemoveOutline"
                                        clearable
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="() => {newLinkForm.resetError('expiry')}"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="newLinkForm.values.valid_till"
                                    first-day-of-week="1"
                                    :min="currentDate"
                                    no-title
                                    scrollable
                                >
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menuLinkExpires = false"
                                    >
                                        {{System.lang('buttons.ok')}}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="success"
                                        @click="newLinkForm.values.valid_till = undefined; menuLinkExpires = false"
                                    >
                                        {{System.lang('links.buttons.unsetDate')}}
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="newLinkForm.values.domain_id"
                                :items="domains"
                                item-value="id"
                                item-text="domain"
                                :label="System.lang('links.newLinkForm.domain')"
                                :error-messages="newLinkForm.errors.domain_id"
                                hide-details
                                :prepend-icon="mdiDomain"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                :disabled="!plan.allow_keys"
                                :label="System.lang('links.newLinkForm.key')"
                                :hint="System.lang('links.newLinkForm.keyHint')"
                                counter="25"
                                v-model="newLinkForm.values.key"
                                :rules="newLinkForm.rules.key"
                                :prepend-icon="mdiSlashForward"
                                :error-messages="newLinkForm.errors.key"
                                @click="() => {newLinkForm.resetError('key')}"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                counter="25"
                                :prepend-icon="mdiKeyOutline"
                                v-model="newLinkForm.values.password"
                                :label="System.lang('links.newLinkForm.password')"
                                :hint="System.lang('links.newLinkForm.passwordHint')"
                                :error-messages="newLinkForm.errors.password"
                                :rules="newLinkForm.rules.password"
                                @click="() => {newLinkForm.resetError('password')}"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </div>
            </v-expand-transition>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                v-if="showAddedLink"
                text
                :block="false"
                color="primary" 
                :label="System.lang('links.buttons.addmore')"
                :fn="resetNewLinkFormMore"
            />
            <ActionButton
                v-else
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addNewLink"
                :disabled="!newLinkForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="showAddedLink ? System.lang('buttons.close') : System.lang('buttons.cancel')"
                :fn="() => {newLinkDialog.close()}"
            />
        </template>
    </SimpleDialog>
    
    
    <SimpleDialog
        :dialog="newGroupDialog"
        :title="System.lang('groups.newGroupDialog')"
        :hint="System.lang('groups.newGroupDialogHint')"
        :icon="mdiFolderPlusOutline"
        @close="newGroupDialog.close()"
        :width="500"
    >
        <SimpleForm 
            @ref="newGroupForm.ref = $event"
            @input="newGroupForm.valid = $event"
        >
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-text-field
                        autofocus
                        :label="System.lang('groups.newGroupForm.name')"
                        :hint="System.lang('groups.newGroupForm.nameHint')"
                        counter="100"
                        v-model="newGroupForm.values.name"
                        :rules="newGroupForm.rules.name"
                        :prepend-icon="mdiFolderOutline"
                        :error-messages="newGroupForm.errors.name"
                        @click="() => {newGroupForm.resetError('name')}"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addNewGroup"
                :disabled="!newGroupForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newGroupDialog.close()}"
            />
        </template>
    </SimpleDialog>


    <SimpleDialog
        :dialog="moveDialog"
        :title="System.lang('links.moveDialog')"
        :hint="System.lang('links.moveDialogHint')"
        :icon="mdiFolderArrowLeftRightOutline"
        @close="moveDialog.close()"
        :width="500"
    >
        <SimpleForm 
            @ref="moveForm.ref = $event"
            @input="moveForm.valid = $event"
        >
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-select
                        v-model="moveForm.values.link_group_id"
                        :items="moveGroups"
                        item-value="id"
                        item-text="name"
                        :label="System.lang('groups.groups')"
                        :rules="moveForm.rules.link_group_id"
                        :error-messages="moveForm.errors.link_group_id"
                        :prepend-icon="mdiFolderOutline"
                        hide-details
                    >
                        <template v-slot:selection="{ item }">
                            {{groupName(item)}}
                        </template>

                        <template v-slot:item="{ item }">
                            {{groupName(item)}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-text-field
                        v-if="moveForm.values.link_group_id == 0"
                        :label="System.lang('links.moveForm.name')"
                        :hint="System.lang('links.moveForm.nameHint')"
                        counter="100"
                        v-model="moveForm.values.name"
                        :rules="moveForm.rules.name"
                        :error-messages="moveForm.errors.name"
                        :prepend-icon="mdiTextBoxEditOutline"
                        @click="() => {moveForm.resetError('name')}"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('links.buttons.move.label')"
                :fn="moveLinks"
                :disabled="(!moveForm.valid || selectedGroup.id == moveForm.values.link_group_id)"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {moveDialog.close()}"
            />
        </template>
    </SimpleDialog>


    <!-- QR code -->
    <SimpleDialog
        :dialog="qrDialog"
        :title="System.lang('qr.qrDialog')"
        :icon="mdiQrcode"
        @close="qrDialog.close()"
        :width="800"
    >
        <v-row>
            <v-col cols="12" sm="5">
                <SimpleForm 
                    @ref="qrForm.ref = $event"
                    @input="qrForm.valid = $event"
                >
                    <v-select
                        class="mb-2"
                        v-model="qrForm.values.ecc"
                        :items="qrEccLevels"
                        :label="System.lang('qr.eccLevel')"
                        hide-details
                        :prepend-icon="mdiDataMatrixScan"
                        @change="debouncedFetchQr"
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content>
                                    <v-list-item-title>{{System.lang(`qr.ecc.${item.value}.label`)}}</v-list-item-title>
                                    <v-list-item-subtitle>{{System.lang(`qr.ecc.${item.value}.hint`)}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                    <v-select
                        class="mb-2"
                        v-model="qrForm.values.style"
                        :items="qrStyles"
                        :label="System.lang('qr.style')"
                        hide-details
                        :prepend-icon="mdiDotsSquare"
                        @change="debouncedFetchQr"
                    >
                    </v-select>
                    <v-select
                        class="mb-2"
                        v-model="qrForm.values.color"
                        :items="qrColors"
                        :label="System.lang('qr.color')"
                        hide-details
                        :prepend-icon="mdiPalette"
                        @change="debouncedFetchQr"
                    >
                    </v-select>
                    <div class="mb-2 mt-6 ml-8">
                        <ColorPicker v-model="qrForm.values.color1" :marginRight="'8px'" v-if="[2,3,4].includes(qrForm.values.color)" @change="debouncedFetchQr" />
                        <ColorPicker v-model="qrForm.values.color2" :marginRight="'8px'" v-if="[3,4].includes(qrForm.values.color)" @change="debouncedFetchQr"/>
                        <ColorPicker v-model="qrForm.values.color3" :marginRight="'8px'" v-if="[4].includes(qrForm.values.color)" @change="debouncedFetchQr"/>
                    </div>
                </SimpleForm>
            </v-col>
            <v-col cols="12" sm="6" offset-sm="1">
                <div class="d-flex align-center justify-center" style="height: 300px">
                    <v-img v-if="qrImage" :src="qrImage" :aspect-ratio="1/1" :max-height="300"></v-img>
                    <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                </div>
            </v-col>
        </v-row>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.close')"
                :fn="() => {qrDialog.close()}"
            />
            <v-spacer></v-spacer>
            <v-menu offset-y top left :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        text
                        :min-width="150"
                        :disabled="!qrImage"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{System.lang('qr.buttons.save.label')}}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item link @click="downloadQr('svg')">
                        <v-list-item-icon class="mr-4">
                            <v-icon color="blue">{{mdiFileCodeOutline}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>SVG</v-list-item-title>
                            <v-list-item-subtitle>{{System.lang('qr.saveSVG')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="downloadQr('png-small')">
                        <v-list-item-icon class="mr-4">
                            <v-icon color="green">{{mdiFileImageOutline}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>PNG (500 x 500)</v-list-item-title>
                            <v-list-item-subtitle>{{System.lang('qr.savePNG1')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="downloadQr('png-large')">
                        <v-list-item-icon class="mr-4">
                            <v-icon color="green darken-1">{{mdiFileImageOutline}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>PNG (2500 x 2500)</v-list-item-title>
                            <v-list-item-subtitle>{{System.lang('qr.savePNG2')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SimpleDialog>


</div>
</template>


<script>
import {
    mdiFolderOutline,
    mdiFolderPlusOutline,
    mdiDotsHorizontal,
    mdiLink,
    mdiLinkPlus,
    mdiSelectionSearch,
    mdiDownloadOutline,
    mdiRefresh,
    mdiCogOutline,
    mdiCheck,
    mdiClose,
    mdiMagnify,
    mdiServerNetwork,
    mdiCalendarRemoveOutline,
    mdiContentCopy,
    mdiQrcode,
    mdiFinance,
    mdiTextBoxSearchOutline,
    mdiCloseCircle,
    mdiFolderArrowLeftRightOutline,
    mdiDeleteOutline,
    mdiTextBoxEditOutline,
    mdiKeyOutline,
    mdiSlashForward,
    mdiDataMatrixScan,
    mdiDotsSquare,
    mdiPalette,
    mdiDomain,
    mdiFileCodeOutline,
    mdiFileImageOutline,
} from '@mdi/js';

// import { mapState } from 'vuex'
import debounce from "lodash/debounce";
import System from '@/classes/System';
import User from '@/classes/User';
import Interface from '@/classes/Interface'
import Dates from '@/classes/Dates'
import Links from '@/classes/Links'
import {Dialog, Form} from '@/classes/Elements'
import {LINK_TYPES, LINK_STATUSES, GROUP_STATUS, LINK_STATUS} from '@/constants/system'
import {TABLES_PP} from "@/constants/interface";
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import ActionButton from '@/components/system/ActionButton'
import IconButton from '@/components/system/IconButton'
// import IconLink from '@/components/system/IconLink'
// import IconAction from '@/components/system/IconAction'
import IconHint from '@/components/system/IconHint'
import ColorPicker from '@/components/system/ColorPicker'
import SimpleDialog from '@/components/system/SimpleDialog'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'LinksView',
    components: {
        HeaderPanel,
        ActionButton,
        IconButton,
        IconHint,
        ColorPicker,
        SimpleDialog,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiFolderOutline,
            mdiFolderPlusOutline,
            mdiDotsHorizontal,
            mdiLink,
            mdiLinkPlus,
            mdiSelectionSearch,
            mdiDownloadOutline,
            mdiRefresh,
            mdiCogOutline,
            mdiCheck,
            mdiClose,
            mdiMagnify,
            mdiServerNetwork,
            mdiCalendarRemoveOutline,
            mdiContentCopy,
            mdiQrcode,
            mdiFinance,
            mdiTextBoxSearchOutline,
            mdiCloseCircle,
            mdiFolderArrowLeftRightOutline,
            mdiDeleteOutline,
            mdiTextBoxEditOutline,
            mdiKeyOutline,
            mdiSlashForward,
            mdiDataMatrixScan,
            mdiDotsSquare,
            mdiPalette,
            mdiDomain,
            mdiFileCodeOutline,
            mdiFileImageOutline,
            // Globals
            System,
            Links,
            LINK_TYPES,
            LINK_STATUSES,
            GROUP_STATUS,
            TABLES_PP,
            // Internal
            loadingGroups: false,
            loadingLinks: false,
            loadingQr: false,
            showGroupButtons: false,
            showLinkSearch: false,
            showNewLinkAdv: false,
            showAddedLink: false,
            menuLinkExpires: false,
            menuColor1: false,
            menuColor2: false,
            menuColor3: false,
            searchLinks: '',
            searchLinksStatus: 0,
            searchLinksType: 0,
            currentPage: 1,
            currentSortDesc: undefined,
            currentSortBy: undefined,
            itemsPerPage: undefined,
            totalVisible: 10,
            scrollTop: undefined,
            // Data
            types: undefined,
            domains: undefined,
            // groups: undefined,
            selectedGroup: undefined,
            selectedType: undefined,
            addedLink: undefined,
            selectedLinks: [],
            selectedLink: undefined,
            newLinkData: '',
            qrImage: undefined,
            // Data structures
            linkStatusItems: [
                {value: 0, text: System.lang('links.status.0')},
                {value: 1, text: System.lang('links.status.1')},
                {value: 2, text: System.lang('links.status.2')},
                {value: 3, text: System.lang('links.status.3')},
                {value: 4, text: System.lang('links.status.4')},
            ],
            qrEccLevels: [
                {value: 1, text: System.lang('qr.ecc.1.label')},
                {value: 2, text: System.lang('qr.ecc.2.label')},
                {value: 3, text: System.lang('qr.ecc.3.label')},
                {value: 4, text: System.lang('qr.ecc.4.label')},
            ],
            qrStyles: [
                {value: 1, text: System.lang('qr.styles.1')},
                {value: 2, text: System.lang('qr.styles.2')},
                {value: 3, text: System.lang('qr.styles.3')},
            ],
            qrColors: [
                {value: 1, text: System.lang('qr.colors.1')},
                {value: 2, text: System.lang('qr.colors.2')},
                {value: 3, text: System.lang('qr.colors.3')},
                {value: 4, text: System.lang('qr.colors.4')},
            ],
            perPageItems: [
                {value: 1,   text: '1'},
                {value: 5,   text: '5'},
                {value: 10,  text: '10'},
                {value: 25,  text: '25'},
                {value: 50,  text: '50'},
                {value: 100, text: '100'},
                {value: -1,  text: System.lang('interface.allItems')},
            ],
            // Forms
            newLinkForm: new Form({
                link_type_id: [
                    (v) => !!v || System.lang('val.required'),
                ],
                domain_id: [
                    (v) => !!v || System.lang('val.required'),
                ],
                key: [
                    (v) => /^[a-zA-Z0-9_-]*$/.test(v) || System.lang('val.linkkey'),
                    (v) => /^.{0,25}$/.test(v) || System.lang('val.shorter'),
                ],
                descr: [
                    (v) => /^.{0,100}$/.test(v) || System.lang('val.shorter'),
                ],
                password: [
                    (v) => /^[\p{L}\p{N}]*$/u.test(v) || System.lang('val.alphanumeric'),
                    (v) => /^.{0,25}$/.test(v) || System.lang('val.shorter'),
                ],
                valid_till: [],
                data: [],
            }),
            newGroupForm: new Form({
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^.{0,100}$/.test(v) || System.lang('val.shorter'),
                ],
            }),
            moveForm: new Form({
                link_group_id: [
                    (v) => (v !== undefined) || System.lang('val.required'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^.{0,100}$/.test(v) || System.lang('val.shorter'),
                ],
            }),
            qrForm: new Form({
                ecc: [],
                style: [],
                color: [],
                color1: [],
                color2: [],
                color3: [],
            }),
            // Tables
            linksHeaders: [
                {
                    text: System.lang('links.linksTable.type'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'link_type_id',
                    width: '90px',
                    filter: this.filterLinkType,
                },
                {
                    text: System.lang('links.linksTable.url'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'from',
                    // cellClass: "no-wrap",
                },
                {
                    text: System.lang('links.linksTable.clicks'),
                    sortable: true,
                    filterable: false,
                    align: 'right',
                    value: 'clicks',
                    width: '100px',
                },
                {
                    text: System.lang('links.linksTable.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    cellClass: "no-wrap",
                    width: '120px',
                    filter: this.filterLinkStatus,
                },
                {
                    text: System.lang('links.linksTable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '220px',
                },
            ],
            // Dialogs
            newLinkDialog: new Dialog(() => {this.resetNewLinkForm()}),
            newGroupDialog: new Dialog(() => {this.newGroupForm.reset()}),
            moveDialog: new Dialog(() => {this.moveForm.reset({link_group_id: this.selectedGroup.id})}),
            qrDialog: new Dialog(),
        }
    },
    computed: {
        plan () {
            return this.$store.state.user.plan;
        },
        groups: {
            get () {
                return this.$store.state.user.groups;
            },
            set (data) {
                this.$store.commit('user/setGroups', data);
            }
        },
        links: {
            get () {
                return this.$store.state.user.links;
            },
            set (data) {
                this.$store.commit('user/setLinks', data);
            }
        },
        saveData: {
            get () {
                return this.$store.state.user.saveData;
            },
            set (data) {
                this.$store.commit('user/setSaved', data);
            }
        },
        currentDate () {
            return Dates.today();
        },
        newLinkFormData () {
            return {
                link_group_id: this.selectedGroup.id,
                link_type_id: this.selectedType.id,
                domain_id: this.newLinkForm.values.domain_id,
                key: this.newLinkForm.values.key,
                data: this.newLinkData,
                descr: this.newLinkForm.values.descr,
                password: this.newLinkForm.values.password,
                valid_till: this.newLinkForm.values.valid_till,
            }
        },
        linkTypeItems () {
            return [
                {value: 0, text: System.lang('links.types.all.name'), ...LINK_TYPES['all']},
                ...this.types?.map(t => {return {value: t.id, text: System.lang(`links.types.${t.code}.name`), ...LINK_TYPES[t.code]}}),
            ];
        },
        searchLinksIsOn () {
            if ((!this.searchLinks || this.searchLinks.trim() == '') 
                && !this.searchLinksStatus
                && !this.searchLinksType)
                return false;
            else
                return true;
        },
        someLinksSelected () {
            return this.selectedLinks?.length;
        },
        canDisableSelected () {
            return this.selectedLinks.find(l => l.status == LINK_STATUS.ON);
        },
        canEnableSelected () {
            return this.selectedLinks.find(l => l.status == LINK_STATUS.OFF);
        },
        moveGroups () {
            if (!this.groups) return [];
            return [
                ...this.groups,
                {divider: true},
                {id: 0, name: System.lang('links.moveForm.newGroup')},
            ];
        },
    },
    methods: {
        async init () {
            System.setTitle('titles.links');
            this.fetchLinkTypes();
            this.fetchDomains();

            if (this.saveData) {
                this.restoreState();
                this.refreshGroups();
                this.refreshLinks();
            } else {
                await this.fetchGroups();
                await this.fetchLinks();
            }

            return;
        },
        // Data fetch functions
        async fetchGroups () {
            this.loadingGroups = true;
            this.groups = undefined;
            this.links = undefined;
            this.selectedGroup = undefined;

            return Api.get(`/groups`)
                .then(data => {
                    this.groups = data;
                    this.selectedGroup = this.restoreSelectedGroup();
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingGroups = false;
                });
        },
        async refreshGroups () {
            return Api.get(`/groups`)
                .then(data => {
                    this.groups = data;
                    return data;
                });
        },
        async fetchLinks () {
            this.loadingLinks = true;
            this.links = undefined;
            this.selectedLinks = [];

            return Api.get(`/groups/${this.selectedGroup.id}/links`)
                .then(data => {
                    this.links = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingLinks = false;
                });
        },
        async refreshLinks () {
            return Api.get(`/groups/${this.selectedGroup.id}/links`)
                .then(data => {
                    this.links = data;
                    return data;
                });
        },
        async fetchLinkTypes () {
            return Links.getLinkTypes()
                .then(data => {
                    this.types = data;
                    this.selectedType = this.types[0];
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async fetchDomains () {
            return Api.get(`/system/domains`)
                .then(data => {
                    this.domains = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async fetchQr () {
            if (this.loadingQr) return;
            
            this.qrImage = undefined;
            this.loadingQr = true;
            this.saveQrForm();

            return Api.post(`/links/${this.selectedLink.id}/qr`, this.qrForm.values)
                .then(data => {
                    this.qrImage = data.base64;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingQr = false;
                });
        },
        debouncedFetchQr: debounce(function () {
            return this.fetchQr();
        }, 500),
        async downloadQr (format = 'svg') {
            if (format == 'svg') {
                System.saveFile('qr.svg', this.qrImage);
            } else if (format == 'png-small') {
                System.svgToImage(this.qrImage, 500, 500, 'png', (pngData) => {
                    System.saveFile('qr.png', pngData);
                });
            } else if (format == 'png-large') {
                System.svgToImage(this.qrImage, 2500, 2500, 'png', (pngData) => {
                    System.saveFile('qr.png', pngData);
                });
            }
        },
        // Data update functions
        async addNewLink () {
            if (this.newLinkForm.validate()) {
                return Api.post(`/groups/${this.selectedGroup.id}/links`, this.newLinkFormData)
                    .then(data => {
                        this.addedLink = data;
                        this.showAddedLink = true;
                        User.addLink(data);
                        Interface.snackSuccess(System.lang('links.messages.LINK_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newLinkForm.catch(error)) {
                            if (error.code == 'PLAN_LIMIT') {
                                Interface.popupPlan(System.lang('messages.PLAN_LIMIT'));
                            } else {
                                Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                            }
                        }
                    });
            }
        },
        async addNewGroup () {
            if (this.newGroupForm.validate()) {
                return Api.post(`/groups`, this.newGroupForm.values)
                    .then(data => {
                        User.addGroup(data);
                        this.selectedGroup = data;
                        this.changeGroup();
                        this.newGroupDialog.close();
                        Interface.snackSuccess(System.lang('groups.messages.GROUP_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newGroupForm.catch(error)) {
                            if (error.code == 'PLAN_LIMIT') {
                                Interface.popupPlan(System.lang('messages.PLAN_LIMIT'));
                            } else {
                                Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                            }
                        }
                    });
            }
        },
        async deleteLinks () {
            if (!this.selectedLinks.length) return;

            return Api.post(`/groups/${this.selectedGroup.id}/delete`, {links: this.selectedLinks.map(l => l.id)})
                .then(data => {
                    this.selectedLinks = [];
                    this.links = data;
                    Interface.snackSuccess(System.lang('links.messages.LINKS_DELETED'));
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async disableLinks () {
            if (!this.selectedLinks.length) return;

            return Api.post(`/groups/${this.selectedGroup.id}/disable`, {links: this.selectedLinks.map(l => l.id)})
                .then(data => {
                    this.selectedLinks = [];
                    this.links = data;
                    Interface.snackSuccess(System.lang('links.messages.LINKS_DISABLED'));
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async enableLinks () {
            if (!this.selectedLinks.length) return;

            return Api.post(`/groups/${this.selectedGroup.id}/enable`, {links: this.selectedLinks.map(l => l.id)})
                .then(data => {
                    this.selectedLinks = [];
                    this.links = data;
                    Interface.snackSuccess(System.lang('links.messages.LINKS_ENABLED'));
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async moveLinks () {
            if (!this.selectedLinks.length) return;
            if (!this.moveForm.validate()) return;

            return Api.post(`/groups/${this.selectedGroup.id}/move`, {...this.moveForm.values, links: this.selectedLinks.map(l => l.id)})
                .then(data => {
                    this.selectedLinks = [];
                    this.links = data.links;
                    if (data.group) User.addGroup(data.group);
                    this.moveDialog.close();
                    Interface.snackSuccess(System.lang('links.messages.LINKS_MOVED'));
                    return data;
                })
                .catch(error => {
                    if (!this.moveForm.catch(error)) {
                        if (error.code == 'PLAN_LIMIT') {
                            Interface.popupPlan(System.lang('messages.PLAN_LIMIT'));
                        } else {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    }
                });
        },
        // Form reset functions
        resetNewLinkForm () {
            this.newLinkForm.reset();
            this.selectedType = this.types[0];
            this.newLinkData = '';
            this.showNewLinkAdv = false;
            this.addedLink = undefined;
            this.showAddedLink = false;
            this.newLinkForm.values.domain_id = this.domains[0]?.id;
            return
        },
        resetNewLinkFormMore () {
            this.showAddedLink = false;
            this.addedLink = undefined;
            this.newLinkData = undefined;
            this.newLinkForm.values.descr = undefined;
            this.newLinkForm.values.password = undefined;
            this.newLinkForm.values.key = undefined;
            this.newLinkForm.resetErrors();
            this.$nextTick(() => {this.$refs.newlinkdata.focus()});
            return
        },
        resetNewLinkAdv () {
            this.newLinkForm.values.domain_id = this.domains[0]?.id;
            this.newLinkForm.values.descr = undefined;
            this.newLinkForm.values.password = undefined;
            this.newLinkForm.values.key = undefined;
            this.newLinkForm.values.valid_till = undefined;
            return
        },
        resetLinksSearch () {
            this.searchLinks = ''; 
            this.searchLinksStatus = 0;
            this.searchLinksType = 0;
            return
        },
        // Filter functions
        filterLinkType (value) {
            return !this.searchLinksType || value == this.searchLinksType;
        },
        filterLinkStatus (value) {
            return !this.searchLinksStatus || value == this.searchLinksStatus;
        },
        filterLinksText (value, search, item) {
            return  value?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.from?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.to?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.descr?.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase())
        },
        // Helper functions
        typeById (id) {
            return this.types?.find(t => t.id == id);
        },
        groupName (group) {
            if (group.is_main) return System.lang('groups.default');
            return group.name;
        },
        changeGroup () {
            if (this.selectedGroup?.id) {
                System.saveSetting('selected-link-group', this.selectedGroup?.id);
                this.fetchLinks();
            }
        },
        onScroll () {
            this.scrollTop = window.top.scrollY;
        },
        openQr (link) {
            this.selectedLink = link;
            this.restoreQrForm();
            this.fetchQr();
            this.qrDialog.open();
        },
        // Restore functions
        restoreSelectedGroup () {
            if (!this.groups.length) return undefined;
            let id = System.loadSetting('selected-link-group', this.groups[0].id);
            let group = this.groups.find(group => group.id == id);
            return (group ?? this.groups[0]);
        },
        saveState () {
            User.setSaved({
                selectedGroupId: this.selectedGroup?.id, 
                searchLinks: this.searchLinks,
                searchLinksStatus: this.searchLinksStatus,
                searchLinksType: this.searchLinksType,
                currentPage: this.currentPage,
                currentSortDesc: this.currentSortDesc,
                currentSortBy: this.currentSortBy,
                itemsPerPage: this.itemsPerPage,
                showLinkSearch: this.showLinkSearch,
                scrollTop: this.scrollTop,
            });
        },
        restoreState () {
            this.selectedGroup = this.groups.find(group => group.id == this.saveData.selectedGroupId);
            this.searchLinks = this.saveData.searchLinks;
            this.searchLinksStatus = this.saveData.searchLinksStatus;
            this.searchLinksType = this.saveData.searchLinksType;
            this.currentPage = this.saveData.currentPage;
            this.currentSortDesc = this.saveData.currentSortDesc;
            this.currentSortBy = this.saveData.currentSortBy;
            this.itemsPerPage = this.saveData.itemsPerPage;
            this.showLinkSearch = this.saveData.showLinkSearch;
            this.$nextTick(() => {this.$vuetify.goTo(this.saveData.scrollTop ?? 0, {duration: 0})});
        },
        restoreQrForm () {
            this.qrForm.values.ecc = System.loadSetting('qr-form-ecc', 1);
            this.qrForm.values.style = System.loadSetting('qr-form-style', 2);
            this.qrForm.values.color = System.loadSetting('qr-form-color', 4);
            this.qrForm.values.color1 = System.loadSetting('qr-form-color1', '#4C58F3');
            this.qrForm.values.color2 = System.loadSetting('qr-form-color2', '#A80A8F');
            this.qrForm.values.color3 = System.loadSetting('qr-form-color3', '#FA9C6C');
        },
        saveQrForm () {
            System.saveSetting('qr-form-ecc', this.qrForm.values.ecc);
            System.saveSetting('qr-form-style', this.qrForm.values.style);
            System.saveSetting('qr-form-color', this.qrForm.values.color);
            System.saveSetting('qr-form-color1', this.qrForm.values.color1);
            System.saveSetting('qr-form-color2', this.qrForm.values.color2);
            System.saveSetting('qr-form-color3', this.qrForm.values.color3);
        },
    },
    mounted () {
        window.addEventListener("scroll", this.onScroll);
        this.itemsPerPage = System.loadSetting('tables.links-table.items_pp', 10);
        this.init();
    },
    beforeDestroy () {
        window.removeEventListener("scroll", this.onScroll);

        if (['link', 'stats', 'logs'].includes(this.$route.name)) this.saveState();
        else User.resetSaved();
    },
}
</script>


<style scoped>
</style>
