// import System from '@/classes/System';
import Api from '@/services/api'
import {LINK_TYPES, FILTER_TYPES, RULE_TYPES} from '@/constants/system'


class Links {
    constructor () {
        this.linkTypes = [];
        this.filterTypes = [];
        this.ruleTypes = [];
        this.countries = [];
        this.langs = [];
        this.deviceTypes = [];
        this.os = [];
        this.browsers = [];
    }


    // Data fetch functions

    async fetchLinkTypes () {
        return Api.get(`/system/link-types`)
            .then(data => {
                this.linkTypes = data.map(t => ({
                    ...t, 
                    ...LINK_TYPES[t.code],
                }));
                
                return this.linkTypes;
            });
    }

    async fetchFilterTypes () {
        return Api.get(`/system/filter-types`)
            .then(data => {
                this.filterTypes = data.map(t => ({
                    ...t, 
                    ...FILTER_TYPES[t.code],
                }));
                
                return this.filterTypes;
            });
    }

    async fetchCountries () {
        return Api.get(`/system/countries`)
            .then(data => {
                this.countries = data;
                return this.countries;
            });
    }

    async fetchLangs () {
        return Api.get(`/system/langs`)
            .then(data => {
                this.langs = data;
                return this.langs;
            });
    }

    async fetchDeviceTypes () {
        return Api.get(`/system/device-types`)
            .then(data => {
                this.deviceTypes = data;
                return this.deviceTypes;
            });
    }

    async fetchOs () {
        return Api.get(`/system/os-families`)
            .then(data => {
                this.os = data;
                return this.os;
            });
    }

    async fetchBrowsers () {
        return Api.get(`/system/browser-families`)
            .then(data => {
                this.browsers = data;
                return this.browsers;
            });
    }

    // Getter functions

    getLinkTypes () {
        if (this.linkTypes.length) {
            return Promise.resolve(this.linkTypes);
        } else {
            return this.fetchLinkTypes();
        }
    }

    getFilterTypes () {
        if (this.filterTypes.length) return this.filterTypes;

        Object.keys(FILTER_TYPES).forEach(code => {
            this.filterTypes.push({
                code: code,
                ...FILTER_TYPES[code],
            });
        });

        return this.filterTypes;
    }

    getRuleTypes () {
        if (this.ruleTypes.length) return this.ruleTypes;

        Object.keys(RULE_TYPES).forEach(code => {
            this.ruleTypes.push({
                code: code,
                ...RULE_TYPES[code],
            });
        });

        return this.ruleTypes;
    }

    getCountries () {
        if (this.countries.length) {
            return Promise.resolve(this.countries);
        } else {
            return this.fetchCountries();
        }
    }

    getLangs () {
        if (this.langs.length) {
            return Promise.resolve(this.langs);
        } else {
            return this.fetchLangs();
        }
    }

    getDeviceTypes () {
        if (this.deviceTypes.length) {
            return Promise.resolve(this.deviceTypes);
        } else {
            return this.fetchDeviceTypes();
        }
    }

    getOs () {
        if (this.os.length) {
            return Promise.resolve(this.os);
        } else {
            return this.fetchOs();
        }
    }

    getBrowsers () {
        if (this.browsers.length) {
            return Promise.resolve(this.browsers);
        } else {
            return this.fetchBrowsers();
        }
    }

    type (id) {
        return this.linkTypes.find(t => t.id == id);
    }

    typecode (id) {
        return this.linkTypes.find(t => t.id == id)?.code ?? '';
    }

    langname (code) {
        return this.langs.find(l => l.code == code)?.name_ml ?? '';
    }

    countryname (code) {
        return this.countries.find(c => c.code == code)?.name_ml ?? '';
    }


    // Helper functions

    generateUniqueArrId (arr) {
        const randomId = Math.random().toString(36).substring(2, 8);

        if (arr.find(e => e.id == randomId)) {
            return this.generateUniqueId();
        }

        return randomId;
    }

    generateMaxArrId (arr) {
        let max = 0;
        
        arr.forEach(e => {
            if (e.id > max) max = e.id;
        });

        return max + 1;
    }

    sortPriority (arr) {
        return arr.sort((a, b) => {
            if (a.priority !== b.priority) {
                return b.priority - a.priority;
            }
            return a.id - b.id;
        });
    }

    createEmptyRule (link_type_id = 0) {
        let rule = {
            id: 0,
            link_type_id: link_type_id,
            data: '',
            priority: 0,
            rule_lang: [],
            rule_country: [],
            rule_device: [],
            rule_os: [],
            rule_browser: [],
        };

        return rule;
    }


}


export default new Links();