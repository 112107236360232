<template>
    <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
            <v-card-text class="pa-0">
                <v-color-picker v-model="selectedColor" mode="hexa" flat @update:color="() => {if (instant) save()}" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <ActionButton
                    text
                    color="primary"
                    :label="System.lang('buttons.ok')"
                    :fn="() => {if (!instant) save(); menu = false}"
                />
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>


<script>
// import {
//     mdiTranslateVariant,
// } from '@mdi/js';

import System from '@/classes/System'
import ActionButton from '@/components/system/ActionButton'

export default {
    name: 'ColorPicker',
    props: {
        value: {
            type: [Object, String],
            required: true,
        },
        instant: {
            type: Boolean,
            default: true,
        },
        height: {
            type: String,
            default: '50px',
        },
        width: {
            type: String,
            default: '50px',
        },
        marginRight: {
            type: String,
            default: '0px',
        },
        marginLeft: {
            type: String,
            default: '0px',
        },
        borderRadius: {
            type: String,
            default: '5px',
        },
    },
    components: {
        ActionButton,
    },
    data () {
        return {
            //Icons
            // Globals
            System,
            // Internal
            menu: false,
            // Data
            selectedColor: undefined,
        }
    },
    computed: {
        swatchStyle () {
            return {
                display: 'inline-block',
                backgroundColor: this.selectedColor?.hex ?? this.selectedColor,
                cursor: 'pointer',
                height: this.height,
                width: this.width,
                marginRight: this.marginRight,
                marginLeft: this.marginLeft,
                borderRadius: this.menu ? '50%' : this.borderRadius,
                transition: 'border-radius 200ms ease-in-out'
            }
        },
    },
    methods: {
        init () {
            this.selectedColor = this.value;
        },
        save () {
            this.$emit('input', this.selectedColor.hex ?? this.selectedColor);
            this.$emit('change', this.selectedColor.hex ?? this.selectedColor);
        },
    },
    mounted () {
        this.init();
    },
}
</script>


<style scoped>
</style>
