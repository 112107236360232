<template>
<div>
    
    <!-- Account Data -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5">
        <HeaderPanel
            :title="System.lang('account.account')"
            :icon="mdiAccount"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <v-alert
            v-if="user.profile?.status == USER_STATUS.DELETED"
            class=""
            text
            dense
            type="error"
        >
            {{System.lang('account.markedDeletion')}}
        </v-alert>
        <ActionForm
            :fn="updateUser"
            @errors="accountForm.errors = $event"
        >
            <v-row>
                <v-col cols="12" sm="8" md="7">
                    <v-text-field
                        disabled
                        v-model="accountForm.values.email"
                        :label="System.lang('account.accountForm.email')"
                        :prepend-icon="mdiAt"
                        hide-details
                    >
                        <template v-slot:append-outer>
                            <IconHint 
                                :color="(user.profile?.email_verified ? 'success' : 'error')"
                                :icon="(user.profile?.email_verified ? mdiCheckCircle : mdiEmailAlert)"
                                :hint="(user.profile?.email_verified ? System.lang('account.emailOk') : System.lang('account.emailNok'))"
                            />
                        </template>
                    </v-text-field>
                    <div v-if="!user.profile?.email_verified" class="ml-8">
                        <p class="error--text text-caption mt-2 mb-2">{{System.lang('account.emailNokHint')}}</p>
                        <ActionButton 
                            v-if="!resent"
                            :block="false"
                            color="primary" 
                            :label="System.lang('account.buttons.resend')"
                            small 
                            :fn="resendEmail"
                        />
                    </div>
                </v-col>
                <v-col cols="12" sm="8" md="7">
                    <v-text-field
                        clearable
                        counter="100"
                        v-model="accountForm.values.name"
                        :label="System.lang('account.accountForm.name')"
                        :hint="System.lang('account.accountForm.nameHint')"
                        :prepend-icon="mdiCardAccountDetailsOutline"
                        :error-messages="accountForm.errors.name"
                        :rules="accountForm.rules.name"
                        @click="() => {accountForm.resetError('name')}"
                    ></v-text-field>
                </v-col>
            </v-row>

            <template v-slot:actions>
                <ActionButton 
                    color="orange" 
                    text
                    :icon="mdiKeyOutline"
                    :label="System.lang('account.buttons.chpass')"
                    :fn="() => {passDialog.open()}"
                />
            </template>
        </ActionForm>
    </v-sheet>


    <!-- Delete -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-5" v-if="user.profile?.status == USER_STATUS.OK">
        <HeaderPanel
            :title="System.lang('account.delete')"
            :hint="System.lang('account.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :ok="System.lang('account.messages.USER_DELETED')"
            :confirm="System.lang('account.confirmDelete')"
            :confirmHint="System.lang('account.confirmDeleteHint')"
            :fn="deleteUser"
        />
    </v-sheet>


    <!-- Change password -->
    <SimpleDialog
        :dialog="passDialog"
        :title="System.lang('account.passDialog')"
        :icon="mdiKeyOutline"
        @close="passDialog.close()"
        :width="400"
    >
        <SimpleForm 
            @ref="passForm.ref = $event"
            @input="passForm.valid = $event"
        >
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-text-field 
                        :label="System.lang('login.password.label')"
                        name="current"
                        :type="showc ? 'text' : 'password'"
                        autocomplete="current-password"
                        required
                        v-model="passForm.values.current"
                        :append-icon="showc ? mdiEye : mdiEyeOff"
                        :rules="passForm.rules.current"
                        :error-messages="passForm.errors.current"
                        @click:append="showc = !showc"
                        @click="() => {passForm.resetError('current')}"
                    ></v-text-field>
                    <v-text-field 
                        :label="System.lang('account.passForm.password')"
                        :type="show ? 'text' : 'password'"
                        counter="100"
                        autocomplete="new-password"
                        name="password"
                        v-model="passForm.values.password"
                        :rules="passForm.rules.password"
                        :error-messages="passForm.errors.password"
                        :append-icon="show ? mdiEye : mdiEyeOff"
                        @click:append="show = !show"
                        @click="() => {passForm.resetError('password')}"
                    ></v-text-field>
                    <v-text-field 
                        :disabled="show"
                        :label="System.lang('account.passForm.password2')"
                        type="password"
                        counter="100"
                        autocomplete="new-password"
                        name="password2"
                        v-model="passForm.values.password2"
                        :rules="passForm.rules.password2"
                        :error-messages="passForm.errors.password2"
                        :append-icon="show ? mdiEye : mdiEyeOff"
                        @click:append="show = !show"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('account.buttons.change')"
                :fn="changePass"
                :disabled="!passForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {passDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiAccount,
    mdiAt,
    mdiCardAccountDetailsOutline,
    mdiCheckCircle,
    mdiEmailAlert,
    mdiRefresh,
    mdiKeyOutline,
    mdiDeleteForever,
    mdiEye,
    mdiEyeOff,
} from '@mdi/js';

import { mapState } from 'vuex'

import System from '@/classes/System';
import User from '@/classes/User';
import Interface from '@/classes/Interface'
import {Dialog, Form} from '@/classes/Elements'
import {USER_STATUS} from '@/constants/system'
// import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import ActionForm from '@/components/forms/ActionForm'
import SimpleForm from '@/components/forms/SimpleForm'
import IconButton from '@/components/system/IconButton'
// import IconLink from '@/components/system/IconLink'
import IconHint from '@/components/system/IconHint'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import DeleteActionButton from '@/components/parts/DeleteActionButton'

export default {
    name: 'AccountView',
    components: {
        HeaderPanel,
        ActionForm,
        IconButton,
        // IconLink,
        IconHint,
        SimpleDialog,
        ActionButton,
        DeleteActionButton,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiAccount,
            mdiAt,
            mdiCardAccountDetailsOutline,
            mdiCheckCircle,
            mdiEmailAlert,
            mdiRefresh,
            mdiKeyOutline,
            mdiDeleteForever,
            mdiEye,
            mdiEyeOff,
            // Globals
            System,
            USER_STATUS,
            // Internal
            resent: false,
            show: false,
            showc: false,
            // Data
            // Forms
            accountForm: new Form({
                email: [],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => v?.length <= 100 || System.lang('val.shorter'),
                ],
            }),
            passForm: new Form({
                current: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}!@#$%^&*()_+:;{}<>,.?~-]*$/u.test(v) || System.lang('val.invalid'),
                ],
                password: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}!@#$%^&*()_+:;{}<>,.?~-]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => v?.length >= 8 || System.lang('val.longer'),
                    (v) => v?.length <= 100 || System.lang('val.shorter'),
                ],
                password2: [
                    (v) => (this.show || v == this.passForm.values.password) || System.lang('val.passmatch'),
                ],
            }),
            // Dialogs
            passDialog: new Dialog(() => {this.passForm.reset()}),
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    methods: {
        init () {
            System.setTitle('titles.account');
            this.accountForm.values.email = this.user.profile?.email;
            this.accountForm.values.name = this.user.profile?.name;
        },
        async updateUser () {
            return User.update(this.accountForm.values);
        },
        async resendEmail () {
            return User.resend()
                .then(() => {
                    Interface.snackSuccess(System.lang('account.messages.EMAIL_RESENT'));
                    this.resent = true;
                })
                .catch(error => {
                    Interface.popupError(System.lang('account.messages.EMAIL_FAILED'), error.message);
                });
        },
        async deleteUser () {
            return User.delete()
                .then(() => {
                    Interface.popupSuccess(System.lang('account.messages.USER_DELETED'));
                });
        },
        async changePass () {
            if (!this.passForm.validate()) return;

            return User.chpass(this.passForm.values)
                .then(() => {
                    this.passDialog.close();
                    Interface.snackSuccess(System.lang('account.messages.PASSWORD_CHANGED'));
                })
                .catch(error => {
                    if (!this.passForm.catch(error)) {
                        Interface.popupError(System.lang('account.messages.PASSWORD_FAILED'), error.message);
                    }
                });
        },
    },
    watch: {
        show (newv) {
            if (newv) {
                this.passForm.values.password2 = '';
                this.passForm.resetError('password2');
            }
        },
    },
    mounted () {
        this.init();
    },
    beforeDestroy () {
        User.resetSaved();
    },
}
</script>


<style scoped>
</style>
