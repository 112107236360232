<template>
    <div>
        <div class="d-flex justify-center align-center content-wrapper mt-5 mt-sm-16">
            <v-sheet elevation="1" rounded="lg" width="400">
                <div class="ma-8 pt-5">
                    <SimpleForm 
                        @ref="remindForm.ref = $event"
                        @input="remindForm.valid = $event"
                    >
                        <v-text-field 
                            :label="System.lang('remind.remindForm.email')"
                            :hint="System.lang('remind.remindForm.emailHint')"
                            type="email"
                            counter="100"
                            autocomplete="username"
                            v-model="remindForm.values.username"
                            :rules="remindForm.rules.username"
                            :error-messages="remindForm.errors.username"
                            :append-icon="mdiAt"
                            @click="() => {remindForm.resetError('username')}"
                        ></v-text-field>
                        <p class="text-caption my-5 primary--text text-center">{{System.lang('remind.sendRemindHint')}}</p>
                        <v-btn
                            class="mt-8"
                            block
                            color="primary" 
                            @click="remind"
                            :loading="loading"
                        >
                            {{System.lang('remind.sendRemind')}}
                        </v-btn>
                    </SimpleForm>

                    <p class="mt-8"><router-link :to="{ name: 'login' }" class="text-body-2">{{System.lang('remind.loginExisting')}}</router-link></p>
                </div>
            </v-sheet>
        </div>
    </div>
</template>


<script>
import {
    mdiAt,
} from '@mdi/js';

import Interface from "@/classes/Interface"
import User from '@/classes/User'
import System from '@/classes/System'
import {Form} from '@/classes/Elements'
// import Api from '@/services/api'

import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'RemindView',
    components: {
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiAt,
            // Global
            System,
            // Internal
            loading: false,
            // Forms
            remindForm: new Form({
                username: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || System.lang('val.email'),
                    (v) => v?.length <= 100 || System.lang('val.shorter'),
                ],
                token: [],
            }),
        }
    },
    methods: {
        async remind () {
            if (this.remindForm.validate()) {
                Interface.messageOff();
                this.loading = true;

                this.remindForm.values.token = await System.recaptcha('remind');

                return User.remind(this.remindForm.values)
                    .then(() => {
                        Interface.messageSuccess(System.lang('remind.messages.SEND_OK'));
                        System.redirectToLogin(false);
                    })
                    .catch(error => {
                        if (!this.remindForm.catch(error)) {
                            Interface.popupError(System.lang('remind.messages.SEND_FAILED'), error.message);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
    },
    watch: {
    },
    mounted () {
        System.setTitle('titles.forgotPass');
        Interface.messageOff();
    },
    // beforeDestroy () {
    //     Interface.messageOff();
    // },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
