import {de} from 'vuetify/lib/locale'

export default {
    ...de,

    // Errors
    errors: {
        'DEFAULT': 'Fehlercode {0}',
        '0': 'Netzwerk- oder Verbindungsfehler',
        '403': 'Zugriff verweigert',
        '404': 'Angefragte Ressource nicht gefunden',
        '422': 'Formular enthält Fehler',
        '500': 'Fehler vom Backend-Server',
        'UNKNOWN': 'Unbekannter Fehler',
        'NETWORK': 'Netzwerk- oder Verbindungsfehler',
        'BACKEND_FAILED': 'Verbindung zum Backend fehlgeschlagen',
        'LOGIN_FAILED': 'Anmeldung fehlgeschlagen',
    },

    // Global messages
    messages: {
        'REQUEST_FAILED': 'Anforderung fehlgeschlagen',
        'SAVE_OK': 'Gespeichert',
        'SAVE_ERROR': 'Speichern fehlgeschlagen',
        'SESSION_EXPIRED': 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
        'CLIPBOARD_COPIED': 'In die Zwischenablage kopiert',
        'CLIPBOARD_FAILED': 'Kopieren fehlgeschlagen',
        'CLIPBOARD_NOT_AVAIL': 'Zwischenablage nicht verfügbar',
        'PLAN_LIMIT': 'Sie haben das Limit Ihres Abonnementplans erreicht',
        'PLAN_ALLOWED': 'Funktion in Ihrem Abonnementplan nicht verfügbar',
    },

    // Global buttons
    buttons: {
        ok: 'OK',
        cancel: 'Abbrechen',
        close: 'Schließen',
        delete: 'Löschen',
        copy: {
            label: 'Kopieren',
            hint: 'In die Zwischenablage kopieren',
        },
        confirm: 'Bestätigen',
        refresh: 'Aktualisieren',
        add: 'Hinzufügen',
        save: {
            label: 'Speichern',
            hint: 'Änderungen speichern',
        },
        help: {
            label: 'Hilfe',
            hint: 'Hilfe anzeigen',
        },
        config: {
            label: 'Konfigurieren',
            hint: 'Konfigurieren'
        },
        translate: {
            label: 'Übersetzen',
            hint: 'Übersetzungen'
        },
        upgrade: 'Upgrade',
    },

    // Interface
    interface: {
        logout: 'Abmelden',
        info: 'Information',
        success: 'Erfolg',
        warning: 'Warnung',
        error: 'Fehler',
        rowsPerPage: 'Pro Seite:',
        allItems: 'Alle',
    },

    // Page titles
    titles: {
        common: 'shorten-url',
        account: 'Kontoeinstellungen',
        api: 'API-Zugriff',
        domains: 'Meine Domains',
        group: 'Gruppeneinstellungen',
        links: 'Meine Links',
        link: 'Link-Einstellungen',
        logs: 'Link-Protokolle',
        plans: 'Abonnement-Einstellungen',
        login: 'Mein Konto',
        register: 'Neues Konto erstellen',
        forgotPass: 'Passwort zurücksetzen',
        stats: 'Link-Statistiken',
        support: 'Support',
    },

    // Main menu
    menu: {
        urls: 'Kurzlinks',
        myurls: 'Meine Links',
        domains: 'Meine Domains',
        settings: 'Einstellungen',
        account: 'Mein Konto',
        plans: 'Mein Abonnement',
        apikeys: 'API-Zugriff',
        help: 'Hilfe',
        support: 'Support',
    },

    // Form validation messages
    val: {
        invalid: 'Ungültige Zeichen',
        required: 'Feld erforderlich',
        shorter: 'Muss kürzer sein',
        longer: 'Muss länger sein',
        less: 'Muss weniger sein',
        more: 'Muss mehr sein',
        numeric: 'Muss numerisch sein',
        alphanumeric: 'Nur Buchstaben und Zahlen',
        url: 'Muss eine gültige URL sein',
        ip: 'Muss eine gültige IP sein',
        domain: 'Muss eine gültige Domain sein',
        domainpart: 'Muss ein gültiger Domainname oder dessen Teil sein',
        phone: 'Muss eine gültige Telefonnummer sein',
        email: 'Muss eine gültige E-Mail-Adresse sein',
        linkkey: 'Nur Buchstaben, Zahlen, - und _ Symbole',
        passmatch: 'Passwörter stimmen nicht überein',
        agree: 'Sie müssen den Servicebedingungen zustimmen',
        telegram: 'Muss ein gültiger Telegram-Benutzername sein',
        whatsapp: 'Muss eine gültige Whatsapp-Telefonnummer sein',
        instagram: 'Muss ein gültiger Instagram-Benutzername sein',
        linkedin: 'Muss ein gültiger LinkedIn-Benutzername sein',
        youtube: 'Muss eine gültige YouTube-Kanal-ID oder Handle sein',
        facebook: 'Muss ein gültiger Facebook-Profilname sein',
    },

    // Login screen
    login: {
        username: {
            label: 'E-Mail',
            hint: 'Geben Sie Ihre Login-E-Mail ein',
            errors: [
                'Geben Sie Ihre Login-E-Mail ein',
                'Muss eine gültige E-Mail-Adresse sein',
            ],
        },
        password: {
            label: 'Passwort',
            hint: 'Geben Sie Ihr Passwort ein',
            errors: [
                'Geben Sie Ihr Passwort ein',
            ],
        },
        buttons: {
            login: "Anmelden",
        },
        errors: [
            'Falsches Passwort',
            'Anmeldung fehlgeschlagen',
        ],
        remember: 'Angemeldet bleiben',
        forgot: 'Passwort vergessen?',
        register: 'Neues Konto registrieren',
        user: 'Benutzer',
    },

    // Register
    register: {
        regForm: {
            email: 'E-Mail',
            emailHint: 'E-Mail wird für die Anmeldung verwendet',
            name: 'Dein Name',
            nameHint: 'Wie sollen wir dich nennen?',
            password: 'Passwort',
            passwordHint: 'Wähle dein Passwort',
            password2: 'Passwort Bestätigung',
            password2Hint: 'Bestätige dein Passwort',
        },
        createNew: 'Neues Konto erstellen',
        agreeTerms: 'Servicebedingungen und Datenschutzbestimmungen akzeptieren',
        termsHint: 'Durch die Nutzung dieses Dienstes stimmen Sie den <a href="https://shorten-url.com/terms" target="_blank">Nutzungsbedingungen</a> und der <a href="https://shorten-url.com/policy" target="_blank">Datenschutzerklärung</a> zu.',
        loginExisting: 'Mit vorhandenem Konto anmelden',
        messages: {
            CAPTCHA_FAILED: 'Captcha-Validierung fehlgeschlagen',
            REG_SUCCESS: 'Überprüfen Sie Ihre E-Mails, um die Registrierung abzuschließen...',
            REG_FAILED: 'Registrierung fehlgeschlagen',
            REG_OK: 'Überprüfen Sie Ihre E-Mails, um die Registrierung abzuschließen...',
        },
    },

    // Remind password
    remind: {
        remindForm: {
            email: 'E-Mail',
            emailHint: 'Ihre Login-E-Mail',
        },
        messages: {
            SEND_OK: 'Bitte überprüfen Sie Ihr Postfach auf eine E-Mail zum Zurücksetzen des Passworts',
            SEND_FAILED: 'Senden fehlgeschlagen',
        },
        sendRemind: 'Senden Sie den Zurücksetzungslink',
        sendRemindHint: 'Wir senden Ihnen einen Link zum Zurücksetzen des Passworts und Anweisungen, wenn die eingegebene E-Mail korrekt ist.',
        loginExisting: 'Zurück zur Anmeldung',
    },

    // Reset password
    reset: {
        resetForm: {
            password: 'Passwort',
            passwordHint: 'Wähle dein neues Passwort',
            password2: 'Passwort Bestätigung',
            password2Hint: 'Bestätige dein Passwort',
        },
        messages: {
            SET_OK: 'Ihr Passwort wurde geändert. Verwenden Sie es, um sich anzumelden',
            SET_FAILED: 'Fehler beim Festlegen des neuen Passworts',
        },
        setPass: 'Setzen Sie ein neues Passwort',
        newPassHint: 'Bitte setzen Sie Ihr neues Passwort',
        loginExisting: 'Zurück zur Anmeldung',
    },

    // Confirm new user email
    confirm: {
        messages: {
            CONFIRM_OK: 'Ihre E-Mail wurde erfolgreich bestätigt.',
            CONFIRM_FAILED: 'Fehler beim Bestätigen der E-Mail',
        },
    },

    // Groups
    groups: {
        groups: 'Gruppen',
        default: 'Standardgruppe',
        disabled: 'Gruppe ist deaktiviert. Alle Links in dieser Gruppe werden inaktiv sein, bis Sie die Gruppe aktivieren.',
        newGroupHint: 'Neue Gruppe erstellen',
        groupSettingsHint: 'Gruppeneinstellungen',
        newGroupDialog: 'Neue Gruppe',
        newGroupDialogHint: 'Erstellen Sie neue Linkgruppen, um Ihre Links besser zu verwalten. Die Gruppierung ermöglicht eine bessere Kontrolle über eine Gruppe von Links.',
        messages: {
            GROUP_ADDED: 'Gruppe erstellt',
        },
        newGroupForm: {
            name: 'Gruppenname',
            nameHint: 'Geben Sie der Gruppe einen beliebigen Namen',
        },
    },

    // Group screen
    group: {
        backHint: 'Zurück zu den Links',
        group: 'Gruppeneinstellungen',
        groupHint: 'Verwalten Sie Gruppeneinstellungen, ändern Sie den Gruppennamen und den Status. Wenn Sie die Gruppe deaktivieren, werden alle Links in dieser Gruppe ebenfalls deaktiviert.',
        filters: 'Verkehrsfilter',
        filtersHint: 'Filtern Sie unerwünschte Besucher nach Land, IP-Adresse oder nach Gerät und Software, die der Besucher verwendet. Filter basieren auf den vom Besucher verwendeten Software-Daten und können ungenau sein.',
        filterType: 'Gruppenfiltertyp:',
        messages: {
            GROUP_DELETED: 'Gruppe gelöscht',
        },
        status: {
            0: 'Alle',
            1: 'Aktiviert',
            2: 'Deaktiviert',
        },
        groupForm: {
            name: 'Gruppenname',
            nameHint: 'Ihr Gruppenname',
            color: 'Farbe',
            status: 'Gruppenstatus',
            disabledWarn: 'Alle Links in dieser Gruppe werden inaktiv, wenn Sie die Gruppe deaktivieren.',
        },
        delete: 'Löschen',
        deleteHint: 'Löschen Sie diese Gruppe, alle Links in dieser Gruppe und die zugehörigen Statistiken.',
        confirmDelete: 'Gruppe und ihre Links löschen?',
        confirmDeleteHint: 'Dies löscht diese Gruppe und alle Links in dieser Gruppe',
    },

    // Links screen
    links: {
        links: 'Meine Links',
        linksHint: 'Verwalten Sie Ihre Kurzlinks in dieser Gruppe. Drücken Sie die Buttons rechts, um die Klickstatistiken des Kurzlinks, das Protokoll der Rohanforderungen und den QR-Code für Ihren Link anzuzeigen. Wählen Sie Links mit dem Kontrollkästchen links aus, um Links zu löschen und zu deaktivieren oder sie in eine andere Gruppe zu verschieben.',
        newLinkDialog: 'Neuer Kurzlink',
        newLinkDialogHint: 'Erstellen Sie einen neuen Kurzlink. Klicken Sie auf erweiterte Einstellungen, um Ihre Linkbeschreibung, Ablauf, Zugangspasswort und Domain festzulegen.',
        selected: 'Ausgewählt',
        newLinkForm: {
            type: 'Typ',
            advanced: 'Erweiterte Einstellungen',
            advancedHint: 'Fügen Sie eine Linkbeschreibung hinzu, richten Sie ein Zugangspasswort ein, konfigurieren Sie das Ablaufdatum und mehr...',
            domain: 'Kurz-URL-Domain',
            key: 'Kurz-URL-Schlüssel (optional)',
            keyHint: 'Leer lassen für einen zufälligen Schlüssel',
            expiry: 'Ablaufdatum (optional)',
            expiryHint: 'Leer lassen für nie',
            descr: 'Beschreibung (optional)',
            descrHint: 'Fügen Sie sich selbst eine kurze Notiz hinzu',
            password: 'Zugangspasswort (optional)',
            passwordHint: 'Schützen Sie den Link mit einem Passwort',
            yourNewLink: 'Ihr neuer Kurzlink:',
        },
        linksTable: {
            type: 'Typ',
            url: 'Link-URL',
            clicks: 'Klicks',
            status: 'Status',
            actions: 'Aktionen',
            qrHint: 'QR-Code erstellen',
            statsHint: 'Statistiken anzeigen',
            logHint: 'Protokoll anzeigen',
            search: 'Suche',
            resetSearch: 'Suchparameter löschen',
            noData1: 'Keine Links in dieser Gruppe...',
            noData2: 'Drücken Sie die Schaltfläche "Neu erstellen...", um einen neuen Kurzlink zu erstellen.',
            noResults: 'Keine Links entsprechen Ihrer Suche...',
            loading: 'Daten werden geladen...',
        },
        buttons: {
            unsetDate: "Nie abläuft",
            addmore: "Mehr hinzufügen",
            move: {
                label: 'Verschieben',
                hint: 'Verschieben Sie ausgewählte Links in eine andere Gruppe',
            },
            disable: {
                label: 'Deaktivieren',
                hint: 'Temporäres Deaktivieren ausgewählter Links',
            },
            delete: {
                label: 'Löschen',
                hint: 'Ausgewählte Links löschen',
            },
            new: {
                label: 'Neu erstellen...',
                hint: 'Einen neuen Kurzlink erstellen',
            },
            search: {
                label: 'Suche',
                hint: 'Links durchsuchen',
            },
            enable: {
                label: 'Aktivieren',
                hint: 'Aktivieren Sie ausgewählte Links',
            },
        },
        messages: {
            LINK_ADDED: 'Neuer Kurzlink erstellt',
            LINKS_DELETED: 'Links gelöscht',
            LINKS_DISABLED: 'Links deaktiviert',
            LINKS_ENABLED: 'Links aktiviert',
            LINKS_MOVED: 'Links verschoben',
        },
        status: {
            0: 'Alle',
            1: 'Aktiviert',
            2: 'Deaktiviert',
            3: 'Abgelaufen',
            4: 'Inaktiv',
        },
        types: {
            all: {
                name: 'Alle',
            },
            web: {
                name: 'Webseite',
                descr: 'Link zu jeder Webseite',
                dataName: 'URL',
                dataHint: 'Geben Sie die Seiten-URL mit http:// oder https:// ein',
            },
            phone: {
                name: 'Telefonnummer',
                descr: 'Link zu einer Telefonnummer',
                dataName: 'Telefonnummer',
                dataHint: 'Geben Sie die vollständige Telefonnummer ohne Leerzeichen und Bindestriche ein',
            },
            email: {
                name: 'E-Mail-Adresse',
                descr: 'Link zu einer E-Mail-Adresse',
                dataName: 'E-Mail',
                dataHint: 'Geben Sie die E-Mail-Adresse ein',
            },
            telegram: {
                name: 'Telegramm',
                descr: 'Link zu einem Telegramm-Profil',
                dataName: 'Telegramm-Benutzername',
                dataHint: 'Geben Sie den Telegramm-Benutzernamen ein',
            },
            whatsapp: {
                name: 'WhatsApp',
                descr: 'Link zu einem WhatsApp-Profil',
                dataName: 'Telefonnummer',
                dataHint: 'Geben Sie die vollständige WhatsApp-Telefonnummer ohne Leerzeichen und Bindestriche ein',
            },
            instagram: {
                name: 'Instagram',
                descr: 'Link zu einem Instagram-Profil',
                dataName: 'Instagram-Benutzername',
                dataHint: 'Geben Sie den Instagram-Profilnamen ein',
            },
            linkedin: {
                name: 'LinkedIn',
                descr: 'Link zu einem LinkedIn-Profil',
                dataName: 'Profilname',
                dataHint: 'Geben Sie den LinkedIn-Profilnamen ein',
            },
            youtube: {
                name: 'YouTube',
                descr: 'Link zu einem YouTube-Kanal',
                dataName: 'Kanal-ID oder Handle',
                dataHint: 'Geben Sie die YouTube-Kanal-ID oder den Handle ein, beginnend mit @',
            },
            facebook: {
                name: 'Facebook',
                descr: 'Link zu einem Facebook-Profil',
                dataName: 'Profilname',
                dataHint: 'Geben Sie den Facebook-Profilnamen ein',
            },
        },
        confirmDelete: 'Ausgewählte Links löschen?',
        confirmDeleteHint: 'Dies löscht ausgewählte Kurzlinks und alle zugehörigen Statistiken',
        confirmDisable: 'Ausgewählte Links deaktivieren?',
        confirmDisableHint: 'Dies deaktiviert ausgewählte Kurzlinks, ohne sie zu löschen',
        confirmEnable: 'Ausgewählte Links aktivieren?',
        confirmEnableHint: 'Dies aktiviert ausgewählte Kurzlinks',
        moveDialog: 'Links verschieben',
        moveDialogHint: 'Verschieben Sie ausgewählte Links in eine andere Gruppe.',
        moveForm: {
            name: 'Gruppenname',
            nameHint: 'Geben Sie der Gruppe einen beliebigen Namen',
            newGroup: 'Neue Gruppe erstellen...',
        },
    },

    // Link screen
    link: {
        backHint: 'Zurück zu den Links',
        link: 'Link-Einstellungen',
        linkHint: 'Verwalten Sie Linkdaten und -einstellungen.',
        filters: 'Verkehrsfilter',
        filtersHint: 'Filtern Sie unerwünschte Besucher nach Land, IP-Adresse oder nach Gerät und Software, die der Besucher verwendet. Filter basieren auf den vom Besucher verwendeten Software-Daten und können ungenau sein.',
        filterType: 'Filtertyp:',
        deactivated: 'Link ist deaktiviert',
        linkForm: {
            created: 'Link erstellt:',
            clicks: 'Gesamtklicks:',
            clicks_ok: 'Insgesamt genehmigt:',
            clicks_blocked: 'Insgesamt blockiert:',
            type: 'Typ:',
            descr: 'Beschreibung (optional)',
            descrHint: 'Fügen Sie sich selbst eine kurze Notiz hinzu',
            group: 'Gruppe',
            status: 'Status',
            expiry: 'Ablaufdatum (optional)',
            expiryHint: 'Leer lassen für nie',
            password: 'Zugangspasswort (optional)',
            passwordHint: 'Schützen Sie den Link mit einem Passwort',
        },
        buttons: {
            unsetDate: "Nie abläuft",
        },
        messages: {
            LINK_DELETED: 'Kurzlink gelöscht',
        },
        delete: 'Löschen',
        deleteHint: 'Löschen Sie diesen Link und alle gesammelten Statistiken.',
        confirmDelete: 'Kurzlink löschen?',
        confirmDeleteHint: 'Dies löscht Ihren Kurzlink und alle zugehörigen Statistiken',
    },


    // Filters
    filters: {
        types: {
            all: {
                name: 'Alle',
            },
            source: {
                name: 'Traffic-Quelle',
                descr: 'Blockiere Bots, direkten Traffic und Links von Websites',
                allow_bots: 'Suchmaschinen-Bots erlauben',
                allow_direct: 'Direkten Traffic erlauben',
                allow_sites: 'Klicks von anderen Websites erlauben',
                filterSites: 'Referrer-Websites filtern:',
                addSite: 'Website hinzufügen',
                wldescr: 'Alle Domains blockieren, außer den unten aufgeführten',
                bldescr: 'Alle Domains erlauben, außer den unten aufgeführten',
                domains: {
                    0: '',
                    1: 'Erlaubte Website-Domains:',
                    2: 'Blockierte Website-Domains:',
                },
                addDialog: {
                    title: 'Website hinzufügen',
                    hint: 'Fügen Sie eine neue Website-Domäne zur Liste hinzu',
                    addButton: 'Hinzufügen',
                    domain: 'Website-Domäne',
                    helpText: 'Geben Sie den vollständigen Domainnamen einer Website oder einen Teil davon ein. Zum Beispiel: "google.com", "google", ".com" ... Wenn Sie nur einen Teil der Domain eingeben, betrifft dies alle Domains, die diesen Teil enthalten. Zum Beispiel: ".com" bedeutet alle Domains in der .com-Zone.',
                },
                noSelectedWarn: 'Fügen Sie erlaubte Websites zur Liste hinzu, sonst werden alle Anfragen blockiert.',
            },
            country: {
                name: 'Länder',
                descr: 'Bestimmte Länder blockieren',
                wldescr: 'Alle Länder blockieren, außer den ausgewählten',
                bldescr: 'Alle Länder erlauben, außer den ausgewählten',
                countries: {
                    0: 'Länder',
                    1: 'Erlaubte Länder:',
                    2: 'Blockierte Länder:',
                },
                noSelectedWarn: 'Fügen Sie erlaubte Länder zur Liste hinzu, sonst werden alle Anfragen blockiert.',
            },
            ip: {
                name: 'IP-Adresse',
                descr: 'Bestimmte IP-Adressbereiche blockieren',
                wldescr: 'Alle IP-Adressen blockieren, außer den unten aufgeführten',
                bldescr: 'Alle IP-Adressen erlauben, außer den unten aufgeführten',
                addresses: {
                    0: '',
                    1: 'Erlaubte IP-Adressen:',
                    2: 'Blockierte IP-Adressen:',
                },
                addIP: 'IP-Adresse hinzufügen',
                addDialog: {
                    title: 'IP-Adresse hinzufügen',
                    hint: 'Neue IP-Adresse oder Bereich zur Liste hinzufügen',
                    addButton: 'Hinzufügen',
                    single: 'Einzelne IP-Adresse',
                    range: 'IP-Adressbereich',
                    from: 'Bereichsstart',
                    to: 'Bereichsende',
                    ip: 'IP-Adresse',
                    mustBeLarger: 'Muss größer als der Bereichsstart sein',
                },
                noSelectedWarn: 'Fügen Sie erlaubte IP-Adressen zur Liste hinzu, sonst werden alle Anfragen blockiert.',
            },
            device: {
                name: 'Gerätetyp',
                descr: 'Bestimmte Gerätetypen blockieren',
                allowed: 'Erlaubte Geräte:',
                allow_desktop: 'Desktops und Notebooks',
                allow_mobile: 'Mobile Geräte',
                allow_tv: 'TV, Autoradios, Konsolen',
                allow_wear: 'Wearables und Peripheriegeräte',
                allow_other: 'Andere und unbekannte',
            },
            software: {
                name: 'Gerätesoftware',
                descr: 'Betriebssysteme und Browser blockieren',
            },
        },
        action: 'Aktion:',
        actions: {
            0: 'Nicht filtern',
            1: 'Whitelist',
            2: 'Blacklist',
        },
    },

    // Rules
    rules: {
        rules: 'Umleitungsregeln',
        rulesHint: 'Erstellen Sie alternative Linkziele abhängig von der Browsersprache des Besuchers, dem Land des Besuchers und der Browsersoftware.',
        noRulesHint: 'Drücken Sie die Schaltfläche "Neue Regel", um Ihre erste Regel zu erstellen...',
        buttons: {
            add: {
                label: 'Neue Regel',
                hint: 'Neue Umleitungsregel erstellen',
            },
        },
        ruleDialog: {
            titleAdd: 'Neue Regel hinzufügen',
            hintAdd: 'Fügen Sie der Liste eine neue Regel hinzu',
            titleEdit: 'Regel ändern',
            hintEdit: 'Einstellungen der Regel ändern',
            priority: 'Priorität',
        },
        ruleForm: {
            type: 'Typ',
            priority: 'Regel-Priorität:',
            if: 'Wenn...',
            select: 'Auswählen:',
        },
        rulesTable: {
            priority: 'P-ty',
            if: 'Wenn...',
            to: 'Umleiten zu',
            actions: 'Aktionen',
            never: 'Nie',
        },
        confirmDel: 'Regel löschen?',
        redirectto: 'Umleiten zu:',
        types: {
            all: {
                name: 'Alle',
            },
            lang: {
                name: 'Browsersprache',
            },
            country: {
                name: 'Besucherland',
            },
            device: {
                name: 'Traffic-Quelle',
            },
            os: {
                name: 'Betriebssystem',
            },
            browser: {
                name: 'Browser',
            },
        },
        devices: {
            bot: 'Suchmaschinen-Bots',
            desktop: 'Desktops und Notebooks',
            mobile: 'Mobile Geräte',
            tv: 'TV, Autoradios, Konsolen',
            wear: 'Wearables und Peripheriegeräte',
        },
    },

    // Link-Statistik-Bildschirm
    stats: {
        backHint: 'Zurück zu den Links',
        linkSettings: 'Link-Einstellungen',
        linkLogs: 'Link-Rohprotokolle',
        stats: 'Link-Statistiken',
        statsHint: 'Zeigen Sie die Klickstatistiken für den Link an. Wählen Sie einen Zeitraum aus, für den Sie die Statistiken anzeigen möchten, indem Sie unten auf Voreinstellungen klicken.',
        noStatsHint: 'Nichts für diesen Zeitraum',
        statsFor: 'Statistiken anzeigen für:',
        periods: {
            today: 'Heute',
            yesterday: 'Gestern',
            day: 'Datum...',
            thisMonth: 'Dieser Monat',
            prevMonth: 'Vorheriger Monat',
            month: 'Monat...',
            selectDates: 'Datumsauswahl...',
        },
        sections: {
            clicks: 'Klicks',
            clicksHint: 'Sehen Sie, wie viele Klicks Sie haben, wie viele davon genehmigt wurden und aufgrund von Filtern oder Passwörtern blockiert wurden. Sehen Sie die Traffic-Quellen, von denen Ihre Benutzer kommen.',
            sources: 'Traffic-Quellen:',
            // sourcesHint: 'Diese Grafik zeigt, von welchen Websites Ihre Link-Besucher kommen, Links von anderen Websites, QR-Code oder direkt eingegebene Adressen.',
            results: 'Genehmigt / Blockiert:',
            devices: 'Geräte und Software',
            devicesHint: 'Sehen Sie, welche Geräte und Software Ihre Besucher verwenden.',
            deviceTypes: 'Gerätetypen:',
            os: 'Betriebssysteme:',
            browsers: 'Browser:',
            clientTypes: 'Client-Typen:',
            searchBots: 'Suchbots:',
            clientLangs: 'Browsersprachen:',
            geo: 'Geografie',
            geoHint: 'Geografie der Klicks. Sehen Sie, aus welchen Ländern Ihre Benutzer kommen. Diese Informationen können ungenau sein, wenn ein VPN-Dienst verwendet wird.',
            showBots: 'Suchbots anzeigen',
        },
        charts: {
            1: {
                clicks: 'Klicks:',
                clicks_success: 'Genehmigt:',
                clicks_blocked: 'Blockiert:',
                success: 'Genehmigt',
                blocked: 'Blockiert',
            },
            2: {
                success: 'genehmigt',
                blocked: 'blockiert',
            },
        },
    },


    // Logs screen
    logs: {
        backHint: 'Zurück zu den Links',
        linkSettings: 'Link-Einstellungen',
        linkStats: 'Link-Statistiken',
        logs: 'Link-Protokolle',
        logsHint: 'Zeigen Sie die Rohprotokolle des Links an. Wählen Sie einen Zeitraum aus, für den Sie die Protokolle anzeigen möchten, indem Sie unten auf Voreinstellungen klicken.',
        logsFor: 'Protokolle anzeigen für:',
        logDialog: 'Vollständiges Protokoll',
        periods: {
            today: 'Heute',
            yesterday: 'Gestern',
            day: 'Datum...',
        },
        buttons: {
            search: {
                label: 'Suche',
                hint: 'Protokolleinträge suchen',
            },
            view: {
                label: 'Anzeigen',
                hint: 'Vollständigen Protokolleintrag anzeigen',
            },
        },
        logsTable: {
            when: 'Wann',
            ip: 'IP',
            country: 'Land',
            locale: 'Sprachregion',
            source: 'Quelle',
            device: 'Gerät',
            os: 'Betriebssystem',
            browser: 'Browser',
            result: 'Ergebnis',
            actions: 'Aktionen',
        },
        devices: {
            0: 'Unbekannt',
            1: 'Suchbot',
            2: 'Computer/Notebook',
            3: 'Smartphone',
            4: 'Tablet',
            5: 'Feature-Phone',
            6: 'Konsole',
            7: 'TV',
            8: 'Autobrowser',
            9: 'Smart Display',
            10: 'Kamera',
            11: 'Mediaplayer',
            12: 'Phablet',
            13: 'Smart Speaker',
            14: 'Wearable',
            15: 'Peripheriegerät',
        },
        clients: {
            0: 'Unbekannt',
            1: 'Suchbot',
            2: 'Browser',
            3: 'Reader',
            4: 'Bibliothek',
            5: 'Mediaplayer',
            6: 'Mobile App',
            7: 'Messenger',
        },
        results: {
            1: 'Genehmigt',
            2: 'Kein Passwort',
            3: 'Falsches Passwort',
            11: 'Länderfilter',
            12: 'Trafficfilter',
            13: 'Gerätefilter',
            14: 'IP-Filter',
        },
        sources: {
            bot: 'Suchbot',
            direct: 'Direkter Verkehr',
            qr: 'QR-Code',
        },
        tables: {
            when: 'Wann:',
            source: 'Quelle:',
            destination: 'Umgeleitet nach:',
            blocked: 'Blockiert: ',
            device: {
                label: 'Gerät',
                type: 'Gerätetyp:',
                brand: 'Geräte-Marke:',
                model: 'Gerätemodell:',
            },
            os: {
                label: 'Betriebssystem',
                name: 'Name:',
                version: 'Version:',
            },
            geo: {
                label: 'Geo IP',
                ip: 'IP-Adresse:',
                country: 'Ländercode:',
                coords: 'Koordinaten:',
            },
            soft: {
                label: 'Software',
                clientType: 'Client-Typ:',
                locale: 'Sprachregion:',
                browserName: 'Browser-Name:',
                browserVer: 'Browser-Version:',
                clientName: 'Client-Name:',
                clientVer: 'Client-Version:',
            },
            bot: {
                label: 'Suchbot',
                name: 'Bot-Name:',
            },
        },
    },


    // API keys screen
    apikeys: {
        apikeys: 'API-Schlüssel',
        apikeysHint: 'Verwalten Sie Ihre API-Schlüssel. Ein API-Schlüssel ist erforderlich, um auf Ihr Konto über die API zuzugreifen. API-Zugriff ermöglicht es Ihnen, Ihre Links und Gruppen auf programmatische Weise zu verwalten. Jeder API-Schlüssel kann auch auf schreibgeschützten Zugriff beschränkt werden. Bewahren Sie Ihre Schlüssel geheim auf.',
        noKeys1: 'API-Schlüssel sind erforderlich, um auf Ihr Konto über die API zuzugreifen.',
        noKeys2: 'Drücken Sie die Schaltfläche "Neu erstellen...", um Ihren ersten Schlüssel zu erstellen...',
        neverExpires: 'Läuft nie ab',
        newKeyDialog: 'Neuen Schlüssel erstellen',
        newKeyDialogHint: 'Einen neuen API-Schlüssel erstellen.',
        showKeyDialog: 'Schlüssel anzeigen',
        showKeyDialogHint: 'Hier können Sie den API-Schlüssel anzeigen und in die Zwischenablage kopieren. Bewahren Sie ihn an einem sicheren Ort auf.',
        confirmDel: 'API-Schlüssel löschen?',
        viewDoc: 'API-Dokumentation ansehen',
        status: {
            0: 'Alle',
            1: 'Aktiviert',
            2: 'Deaktiviert',
            3: 'Abgelaufen',
        },
        buttons: {
            new: {
                label: 'Neu erstellen...',
                hint: 'Neuen API-Schlüssel erstellen',
            },
            view: {
                label: 'Schlüssel anzeigen',
                hint: 'API-Schlüssel anzeigen',
            },
        },
        messages: {
            KEY_ADDED: 'API-Schlüssel erstellt',
            KEY_DELETED: 'API-Schlüssel gelöscht',
        },
        keysTable: {
            name: 'Schlüsselname',
            created: 'Erstellt',
            permission: 'Berechtigung',
            expires: 'Läuft ab',
            status: 'Status',
            actions: 'Aktionen',
            fullPerm: 'Vollzugriff',
            roPerm: 'Nur Lesen',
        },
        newKeyForm: {
            name: 'Schlüsselname',
            nameHint: 'Geben Sie dem neuen Schlüssel einen Namen',
            readonly: 'Nur-Lesen-Berechtigung',
            expiry: 'Ablaufdatum',
            expiryHint: 'Leer lassen für unbegrenzt',
        },
    },

    // Domains screen
    domains: {
        domains: 'Meine Domains',
        domainsHint: 'Sie können eigene benutzerdefinierte Domains für Ihre Kurzlinks verwenden. Mit einer benutzerdefinierten Domain haben Sie die volle Kontrolle über Ihre Link-URLs und Schlüssel und stärken Ihr Markenmarketing. Sie müssen der Eigentümer der benutzerdefinierten Domain sein, die Sie hinzufügen möchten. Änderungen an der Namensserver-Konfiguration sind erforderlich, um Ihre Domain auf unsere Server zu verweisen.',
        noDomains1: 'Sie können eigene benutzerdefinierte Domains für Ihre Kurzlinks hinzufügen. ',
        noDomains2: 'Die benutzerdefinierte Domain steht nur Ihnen zur Verfügung und bietet eine exklusive Kontrolle über die Kurzlinkschlüssel. Sie können Ihre Kurzlinks dann als https://deine_domain/dein_schlüssel erstellen.',
        noDomains3: 'Sie müssen der Eigentümer der Domain sein, die Sie verwenden möchten, und die Domain darf in keiner anderen Website verwendet werden. Sie müssen Ihre Domain so konfigurieren, dass sie auf unsere Namenserver zeigt, um zu starten.',
        noDomains5: 'Drücken Sie die Schaltfläche "Domain hinzufügen", um mit dem Hinzufügen Ihrer benutzerdefinierten Domain zu beginnen...',
        newDomainDialog: 'Domain hinzufügen',
        newDomainDialogHint: 'Fügen Sie Ihre neue benutzerdefinierte Domain hinzu.',
        confirmDel: 'Anforderung zur Löschung der Domain und aller ihrer Links?',
        nsHint: 'Um mit der Hinzufügung einer neuen Domain fortzufahren, konfigurieren Sie bitte folgende Namensserver bei Ihrem Domain-Registrar DNS:',
        status: {
            0: 'Alle',
            1: 'Aktiviert',
            2: 'Deaktiviert',
            3: 'In Bearbeitung',
            4: 'In Löschung',
        },
        buttons: {
            new: {
                label: 'Domain hinzufügen',
                hint: 'Fügen Sie Ihre benutzerdefinierte Domain hinzu',
            },
        },
        messages: {
            DOMAIN_ADDED: 'Domain hinzugefügt',
            DOMAIN_DELETED: 'Zur Löschung markiert',
        },
        domainsTable: {
            domain: 'Domain',
            added: 'Hinzugefügt',
            count: 'Links',
            status: 'Status',
            actions: 'Aktionen',
        },
        newDomainForm: {
            domain: 'Domainname',
            domainHint: 'Ihr benutzerdefinierter Domainname',
            helpText: 'Sie müssen Eigentümer der Domain sein, die Sie hinzufügen möchten. Die hinzugefügte Domain kann nur für den Kurzlinkdienst verwendet werden. Die Domain erfordert eine Änderung der Namensserverkonfiguration im Registrar-DNS.',
        },
    },


    // Account screen
    account: {
        account: 'Mein Konto',
        accountHint: 'Zeigen Sie Ihre Kontoeinstellungen an, ändern Sie Ihren Namen und Ihr Passwort.',
        accountForm: {
            email: 'E-Mail',
            name: 'Name',
            nameHint: 'Teilen Sie uns mit, wie wir Sie nennen sollen',
        },
        passForm: {
            current: 'Aktuelles Passwort',
            password: 'Neues Passwort',
            password2: 'Neues Passwort bestätigen',
        },
        buttons: {
            resend: 'E-Mail erneut senden',
            chpass: 'Passwort ändern',
            change: 'Ändern',
        },
        messages: {
            EMAIL_RESENT: 'E-Mail gesendet',
            EMAIL_FAILED: 'Senden der E-Mail fehlgeschlagen',
            USER_DELETED: 'Konto ist zur Löschung markiert und wird in wenigen Tagen gelöscht.',
            PASSWORD_CHANGED: 'Passwort geändert',
            PASSWORD_FAILED: 'Passwortänderung fehlgeschlagen',
        },
        emailOk: 'E-Mail bestätigt',
        emailNok: 'E-Mail nicht bestätigt',
        emailNokHint: 'E-Mail nicht bestätigt. Bitte überprüfen Sie Ihr Postfach auf die Bestätigungs-E-Mail.',
        delete: 'Konto löschen',
        deleteHint: 'Anforderung zur vollständigen Löschung des Kontos und aller Ihrer Daten.',
        confirmDelete: 'Ihr Konto löschen?',
        confirmDeleteHint: 'Dadurch werden Ihr Konto, alle Ihre Daten und Statistiken gelöscht. Das Konto kann nach der Löschung nicht wiederhergestellt werden.',
        markedDeletion: 'Dieses Konto ist zur Löschung markiert und wird in wenigen Tagen entfernt. Kontaktieren Sie den Support, wenn diese Aktion abgebrochen werden muss. Eine Wiederherstellung ist nicht möglich, sobald das Konto gelöscht wurde.',
        passDialog: 'Passwort ändern',
    },

    // Plans
    plans: {
        betaTestingInfo: 'Dieser Service befindet sich in einem Aktionszeitraum. Wir werden die Gebühren später aktivieren. Bis dahin können Sie den PRO-Plan kostenlos nutzen...',
        upgradeMsg: 'Bitte wechseln Sie zum nächsten Abo-Plan, um diese Funktion freizuschalten...',
        emailNotConfirmed: 'Ihre E-Mail-Adresse ist nicht bestätigt. Um Ihren Abo-Plan zu ändern, müssen Sie Ihre E-Mail-Adresse bestätigen. Bitte überprüfen Sie Ihr Postfach auf die Bestätigungs-E-Mail oder senden Sie diese erneut von Ihren Kontoeinstellungen...',
        goToSettings: 'Öffnen Sie die Kontoeinstellungen',
        subscription: 'Mein Abo',
        subscriptionHint: 'Verwalten Sie Ihren Abo-Plan. Drücken Sie die Schaltfläche "Aktivieren" des Abo-Plans, wenn Sie zu diesem wechseln möchten.',
        curMonth: 'EUR/Monat',
        noCharge: 'KOSTENLOS',
        active: 'Aktiv',
        unlimited: 'Unbegrenzt',
        months: 'Monate',
        days: 'Tage',
        plans: {
            free: 'Kostenlos',
            basic: 'Basic',
            pro: 'Pro',
            system: 'System',
        },
        buttons: {
            activate: {
                label: 'Aktivieren',
                hint: 'Ändern Sie Ihren Abo-Plan',
            },
        },
        limit_links: 'Gesamtlinks:',
        limit_groups: 'Gesamtgruppen:',
        limit_life: 'Link-Lebensdauer:',
        limit_history: 'Statistikverlauf:',
        limit_logs: 'Verlaufsprotokoll:',
        allow_api: 'API-Zugriff:',
        allow_domains: 'Benutzerdefinierte Domains:',
        allow_keys: 'Benutzerdefinierte Link-Schlüssel:',
        offer: 'Wenn Sie größere Limits, einen unbegrenzten Plan oder eine Enterprise-Support-Option benötigen, kontaktieren Sie uns per Support-E-Mail, um einen individuellen Vertragsvorschlag zu erhalten.',
    },

    // Support
    support: {
        support: 'Support',
        supportHint: 'Kontaktieren Sie den Support bei Problemen oder Fragen unter der unten angegebenen E-Mail-Adresse. Senden Sie Ihre E-Mail von der Adresse, die Sie für die Erstellung Ihres Kontos verwendet haben, und wir werden so schnell wie möglich antworten.',
        emailHint: 'Support-E-Mail:',
    },

    // Qr
    qr: {
        qrDialog: 'QR-Code',
        qrDialogHint: 'Erstellen Sie einen QR-Code für Ihren Link. Wählen Sie verschiedene Einstellungen, um das Aussehen Ihres QR-Codes zu ändern. Klicken Sie auf die Schaltfläche "Datei speichern", um das QR-Bild auf Ihrer Festplatte zu speichern.',
        eccLevel: 'Fehlerkorrektur',
        ecc: {
            1: { label: 'Level L (Niedrig)', hint: '7 % der Datenbytes können wiederhergestellt werden' },
            2: { label: 'Level M (Mittel)', hint: '15 % der Datenbytes können wiederhergestellt werden' },
            3: { label: 'Level Q (Quartil)', hint: '25 % der Datenbytes können wiederhergestellt werden' },
            4: { label: 'Level H (Hoch)', hint: '30 % der Datenbytes können wiederhergestellt werden' },
        },
        style: 'Stil',
        styles: {
            1: 'Klassisch',
            2: 'Gerundete Punkte',
            3: 'Vollständig gerundet',
        },
        color: 'Farbe',
        colors: {
            1: 'Schwarz',
            2: 'Einfarbige',
            3: '2-farbige Verlauf',
            4: '3-farbige Verlauf',
        },
        buttons: {
            save: {
                label: 'Datei speichern',
                hint: 'QR-Code auf Ihrer Festplatte als SVG-Datei speichern',
            },
        },
        saveSVG: 'Als SVG-Datei',
        savePNG1: 'Als kleine PNG-Datei',
        savePNG2: 'Als große PNG-Datei',
    },

}