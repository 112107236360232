import {
    mdiCloseCircleOutline,
    mdiAlertOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiInformationOutline,
    mdiCreditCardOutline,
} from '@mdi/js';

// import goTo from 'vuetify/es5/services/goto'
import store from '@/store'

import {MSG} from '@/constants/interface' 

class Interface {

// Helpers

    humanFileSize (size) {
        if (size > 0) {
            let i = Math.floor(Math.log(size) / Math.log(1024));
            return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
        } else {
            return '0';
        }
    }

    humanDate (date, format = 1) {
        let dt = new Date(date);
        
        switch (format) {
            // 'Date + Time / auto'
            case 1: return dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString();
            default: return '';
        }
    }

    messageIcon (type) {
        switch (type) {
            case MSG.SUCCESS: return mdiCheckboxMarkedCircleOutline;
            case MSG.WARNING: return mdiAlertOutline;
            case MSG.ERROR:   return mdiCloseCircleOutline;
            case MSG.PLAN:    return mdiCreditCardOutline;
            default: return mdiInformationOutline;
        }
    }
    
    messageColor (type) {
        switch (type) {
            case MSG.SUCCESS: return 'success';
            case MSG.WARNING: return 'warning';
            case MSG.ERROR:   return 'error';
            case MSG.PLAN:    return 'info';
            default: return 'info';
        }
    }

// Popup messages

    popupInfo (text, descr = false) {
        store.commit('system/popupOn', {type: MSG.INFO, text, descr});
    }

    popupSuccess (text, descr = false) {
        store.commit('system/popupOn', {type: MSG.SUCCESS, text, descr});
    }

    popupWarning (text, descr = false) {
        store.commit('system/popupOn', {type: MSG.WARNING, text, descr});
    }        

    popupError (text, descr = false) {
        store.commit('system/popupOn', {type: MSG.ERROR, text, descr});
    }

    popupPlan (text, descr = false) {
        store.commit('system/popupOn', {type: MSG.PLAN, text, descr});
    }


// Snack messages

    snackInfo (text) {
        store.commit('system/snackOn', {type: MSG.INFO, text});
    }

    snackSuccess (text) {
        store.commit('system/snackOn', {type: MSG.SUCCESS, text});
    }    

    snackWarning (text) {
        store.commit('system/snackOn', {type: MSG.WARNING, text});
    }    

    snackError (text) {
        store.commit('system/snackOn', {type: MSG.ERROR, text});
    }    

// Top messages

    messageInfo (text) {
        store.commit('system/messageOn', {type: MSG.INFO, text});
    }

    messageSuccess (text) {
        store.commit('system/messageOn', {type: MSG.SUCCESS, text});
    }

    messageWarning (text) {
        store.commit('system/messageOn', {type: MSG.WARNING, text});
    }

    messageError (text) {
        store.commit('system/messageOn', {type: MSG.ERROR, text});
    }

    messageOff () {
        store.commit('system/messageOff');
    }

}

export default new Interface();