import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Settings from '@/constants/settings'

import LoginView from '@/components/views/LoginView'
import RegisterView from '@/components/views/RegisterView'
import RemindView from '@/components/views/RemindView'
import ResetView from '@/components/views/ResetView'
import ConfirmView from '@/components/views/ConfirmView'
import LinksView from '@/components/views/LinksView'
import LinkView from '@/components/views/LinkView'
import StatsView from '@/components/views/StatsView'
import LogsView from '@/components/views/LogsView'
import GroupView from '@/components/views/GroupView'
import ApiKeysView from '@/components/views/ApiKeysView'
import DomainsView from '@/components/views/DomainsView'
import AccountView from '@/components/views/AccountView'
import PlansView from '@/components/views/PlansView'
import SupportView from '@/components/views/SupportView'
import LoginsView from '@/components/views/LoginsView'

Vue.use(VueRouter)

function user() {
    return store.state.user.profile;
}

// Does not work during page reloads 
// user is undefined at this stage
function onlyNonAuth(to, from, next) {
    if (user()) {
        next({name: 'home'});
    } else {
        next();
    }
}

const routes = [

    // Home
    {
        path: '/',
        name: 'home',
        redirect: {name: 'links'},
    },

    // My URLs
    {
        path: '/links',
        name: 'links',
        component: LinksView,
    },

    // Link settings
    {
        path: '/links/:id/settings',
        name: 'link',
        component: LinkView,
    },

    // Link statistics
    {
        path: '/links/:id/stats',
        name: 'stats',
        component: StatsView,
    },

    // Link logs
    {
        path: '/links/:id/logs',
        name: 'logs',
        component: LogsView,
    },

    // Group settings
    {
        path: '/links/group/:id',
        name: 'group',
        component: GroupView,
    },

    // API Keys
    {
        path: '/api-keys',
        name: 'api-keys',
        component: ApiKeysView,
    },

    // Domains
    {
        path: '/domains',
        name: 'domains',
        component: DomainsView,
    },

    // Login
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        beforeEnter: onlyNonAuth,
    },

    // Super login
    {
        path: '/login-super',
        name: 'login-super',
        component: LoginsView,
        beforeEnter: onlyNonAuth,
    },

    // Register
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
        beforeEnter: onlyNonAuth,
    },

    // Remind password
    {
        path: '/remind',
        name: 'remind',
        component: RemindView,
        beforeEnter: onlyNonAuth,
    },

    // Reset password
    {
        path: '/reset/:code',
        name: 'reset',
        component: ResetView,
        beforeEnter: onlyNonAuth,
    },

    // Confirm email
    {
        path: '/confirm/:code',
        name: 'confirm',
        component: ConfirmView,
    },

    // Account settings
    {
        path: '/account',
        name: 'account',
        component: AccountView,
    },

    // Subscription settings
    {
        path: '/plans',
        name: 'plans',
        component: PlansView,
    },

    // Support
    {
        path: '/support',
        name: 'support',
        component: SupportView,
    },

    // Default
    {
        path: '*',
        redirect: {name: 'home'},
    },
];

const router = new VueRouter({
    mode: (Settings.useNiceUrl ? 'history' : 'hash'),
    base: process.env.BASE_URL,
    routes,
})

export default router
