<template>
    <v-row dense>
        <v-col cols="12" md="6" lg="6">
            <div class="rounded px-3 py-1 grey lighten-4">
                <v-input
                    hide-details
                >
                    <template v-slot:prepend v-if="!System.isMobile()">
                        <v-icon left color="orange" class="mt-1 ml-1" size="30">{{mdiWeb}}</v-icon>
                    </template>

                    <template v-slot:append>
                        <IconButton
                            color="primary"
                            :icon="mdiContentCopy"
                            :hint="System.lang('buttons.copy.hint')"
                            :fn="() => {System.copy(link.from)}"
                        />
                    </template>

                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p class="font-weight-medium mt-3 mb-3"><a v-bind="attrs" v-on="on" :href="link.from" target="_blank" class="text-decoration-none">{{link.from}}</a></p>
                            </template>
                            <span>{{ link.to }}</span>
                        </v-tooltip>
                        <!-- <p class="text-caption grey--text mb-1">{{System.lang('link.linkForm.created')}} <span class="blue--text text--lighten-1">{{human(link.created_at)}}</span></p>
                        <p class="text-caption grey--text mb-2">
                            {{System.lang('link.linkForm.clicks')}} <span class="blue--text text--lighten-1 mr-4">{{link.clicks}}</span>
                            {{System.lang('link.linkForm.clicks_ok')}} <span class="green--text text--lighten-1">{{link.clicks_ok}}</span>
                        </p> -->
                    </div>
                </v-input>
            </div>
        </v-col>
    </v-row>
</template>


<script>
import {
    mdiContentCopy,
    mdiWeb,
} from '@mdi/js';

import System from '@/classes/System'
import Dates from '@/classes/Dates'

import IconButton from '@/components/system/IconButton'

export default {
    name: 'LinkInfoBar',
    components: {
        IconButton,
    },
    props: {
        link: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiContentCopy,
            mdiWeb,
            // Data
        }
    },
    computed: {
    },
    methods: {
        human (date, format = 2) {
            return Dates.human(date, format);
        },
    },
    mounted () {
    },
}
</script>


<style scoped>
</style>
