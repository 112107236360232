<template>
    <span>
        <v-icon left color="grey">{{mdiCalendarMonth}}</v-icon>
        <span class="text-overline grey--text">{{presetText}}</span>
    </span>
</template>


<script>
import {
    mdiCalendarMonth,
} from '@mdi/js';

export default {
    name: 'DatesPreset',
    components: {
    },
    props: {
        presetText: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            // Globals
            // Icons
            mdiCalendarMonth,
            // Data
        }
    },
    computed: {
    },
    methods: {
    },
    mounted () {
    },
}
</script>


<style scoped>
</style>
