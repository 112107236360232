<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click.stop="logout()" v-bind="attrs" v-on="on"><v-icon>{{mdiExitToApp}}</v-icon></v-btn>
            </template>    
            <span>{{System.lang('interface.logout')}}</span>
        </v-tooltip>
    </div>
</template>


<script>
import {
    mdiExitToApp,
} from '@mdi/js';

import User from '@/classes/User'
import System from '@/classes/System'

export default {
    name: 'TopMenu',
    data () {
        return {
            // Objects
            System,
            // Icons
            mdiExitToApp,
        }
    },
    methods: {
        logout () {
            User.logout();
        }
    },

}
</script>


<style scoped>
</style>
