<template>
    <div>
        <div class="d-flex justify-center align-center content-wrapper ma-5 mt-sm-16">
            <v-sheet elevation="1" rounded="lg" width="400">
                <div class="ma-8 pt-5">
                    <SimpleForm 
                        @ref="regForm.ref = $event"
                        @input="regForm.valid = $event"
                    >
                        <v-text-field 
                            :label="System.lang('register.regForm.email')"
                            :hint="System.lang('register.regForm.emailHint')"
                            type="email"
                            counter="100"
                            autocomplete="username"
                            v-model="regForm.values.username"
                            :rules="regForm.rules.username"
                            :error-messages="regForm.errors.username"
                            :append-icon="mdiAt"
                            @click="() => {regForm.resetError('username')}"
                        ></v-text-field>
                        <v-text-field 
                            :label="System.lang('register.regForm.name')"
                            :hint="System.lang('register.regForm.nameHint')"
                            counter="100"
                            v-model="regForm.values.name"
                            :rules="regForm.rules.name"
                            :error-messages="regForm.errors.name"
                            :append-icon="mdiAccount"
                            @click="() => {regForm.resetError('name')}"
                        ></v-text-field>
                        <v-text-field 
                            :label="System.lang('register.regForm.password')"
                            :hint="System.lang('register.regForm.passwordHint')"
                            :type="show ? 'text' : 'password'"
                            counter="100"
                            autocomplete="new-password"
                            v-model="regForm.values.password"
                            :rules="regForm.rules.password"
                            :error-messages="regForm.errors.password"
                            :append-icon="show ? mdiEye : mdiEyeOff"
                            @click:append="show = !show"
                            @click="() => {regForm.resetError('password')}"
                        ></v-text-field>
                        <v-text-field 
                            :disabled="show"
                            :label="System.lang('register.regForm.password2')"
                            :hint="System.lang('register.regForm.password2Hint')"
                            type="password"
                            counter="100"
                            autocomplete="new-password"
                            v-model="regForm.values.password2"
                            :rules="regForm.rules.password2"
                            :error-messages="regForm.errors.password2"
                            :append-icon="show ? mdiEye : mdiEyeOff"
                            @click:append="show = !show"
                        ></v-text-field>
                        <div class="text-center mt-6 grey--text">
                            <p class="mt-5 text-caption" v-html="System.lang('register.termsHint')"></p>
                        </div>
                        <v-btn
                            class="mt-8"
                            block
                            color="primary" 
                            @click="register"
                            :loading="loading"
                        >
                            {{System.lang('register.createNew')}}
                        </v-btn>
                    </SimpleForm>

                    <p class="mt-8"><router-link :to="{ name: 'login' }" class="text-body-2">{{System.lang('register.loginExisting')}}</router-link></p>
                </div>
            </v-sheet>
        </div>
    </div>
</template>


<script>
import {
    mdiAt,
    mdiAccount,
    mdiEye,
    mdiEyeOff,
} from '@mdi/js';

import Interface from "@/classes/Interface"
import User from '@/classes/User'
import System from '@/classes/System'
import {Form} from '@/classes/Elements'
// import Api from '@/services/api'

import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'RegisterView',
    components: {
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiAt,
            mdiAccount,
            mdiEye,
            mdiEyeOff,
            // Global
            System,
            // Internal
            loading: false,
            show: false,
            // Forms
            regForm: new Form({
                username: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || System.lang('val.email'),
                    (v) => v?.length <= 100 || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => v?.length <= 100 || System.lang('val.shorter'),
                ],
                password: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9!@#$%^&*()_+:;{}<>,.?~-]*$/.test(v) || System.lang('val.invalid'),
                    (v) => v?.length >= 8 || System.lang('val.longer'),
                    (v) => v?.length <= 100 || System.lang('val.shorter'),
                ],
                password2: [
                    (v) => (this.show || v == this.regForm.values.password) || System.lang('val.passmatch'),
                ],
                // agree: [
                //     () => (this.regForm.values.agree) || System.lang('val.agree'),
                // ],
                token: [],
            }),
        }
    },
    methods: {
        async register () {
            if (this.regForm.validate()) {
                Interface.messageOff();
                this.loading = true;

                this.regForm.values.token = await System.recaptcha('register');

                return User.register(this.regForm.values)
                    .then(() => {
                        Interface.messageSuccess(System.lang('register.messages.REG_OK'));
                        System.redirectTo('home');
                        // System.redirectToLogin(false);
                    })
                    .catch(error => {
                        if (!this.regForm.catch(error)) {
                            Interface.popupError(System.lang('register.messages.REG_FAILED'), error.message);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
    },
    watch: {
        show (newv) {
            if (newv) {
                this.regForm.values.password2 = '';
                this.regForm.resetError('password2');
            }
        },
    },
    mounted () {
        System.setTitle('titles.register');
        Interface.messageOff();
    },
    // beforeDestroy () {
    //     Interface.messageOff();
    // },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
