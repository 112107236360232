<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingKeys"></v-progress-linear>    
    
    <!-- API Keys -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="keys">
        <HeaderPanel
            :title="System.lang('apikeys.apikeys')"
            :icon="mdiKey"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiKeyPlus"
                :hint="System.lang('apikeys.buttons.new.hint')"
                :fn="() => {newKeyDialog.open()}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>

        <div v-if="keys.length" class="text-center">
            <v-simple-table 
                dense
                fixed-header
                class="mb-2"
            >
                <thead>
                    <tr class="text-no-wrap">
                        <th class="text-left">{{System.lang('apikeys.keysTable.name')}}</th>
                        <th class="text-left">{{System.lang('apikeys.keysTable.created')}}</th>
                        <th class="text-left">{{System.lang('apikeys.keysTable.permission')}}</th>
                        <th class="text-left">{{System.lang('apikeys.keysTable.expires')}}</th>
                        <th class="text-center" style="width: 100px;">{{System.lang('apikeys.keysTable.status')}}</th>
                        <th class="text-center" style="width: 170px;">{{System.lang('apikeys.keysTable.actions')}}</th>
                    </tr>
                </thead>
                <tbody class="text-no-wrap">
                    <tr v-for="key in keys" :key="key.id">
                        <td class="text-left">{{key.name}}</td>
                        <td class="text-left">{{human(key.created_at)}}</td>
                        <td class="text-left">
                            <span v-if="key.is_readonly" class="grey--text">{{System.lang('apikeys.keysTable.roPerm')}}</span>
                            <span v-else class="success--text">{{System.lang('apikeys.keysTable.fullPerm')}}</span>
                        </td>
                        <td class="text-left">
                            <span v-if="key.valid_till">{{human(key.valid_till, 1)}}</span>
                            <span v-else class="grey--text">{{System.lang('apikeys.neverExpires')}}</span>
                        </td>
                        <td class="text-center"><IconHint :icon="APIKEY_STATUSES[key.status].icon" :color="APIKEY_STATUSES[key.status].iconColor" :hint="System.lang(`apikeys.status.${key.status}`)" /></td>
                        <td class="text-center">
                            <IconButton
                                small
                                :icon="mdiContentCopy"
                                :hint="System.lang('buttons.copy.hint')"
                                color="primary"
                                :fn="() => {System.copy(key.key)}"
                            />
                            <IconButton
                                class="mr-5"
                                small
                                color="primary"
                                :icon="mdiEye"
                                :hint="System.lang('apikeys.buttons.view.hint')"
                                :fn="() => {selectedKey = key; showKeyDialog.open()}"
                            />
                            <span class="mx-1">&nbsp;</span>
                            <IconButton
                                small
                                color="error"
                                :icon="mdiCloseBoxOutline"
                                :hint="System.lang('buttons.delete')"
                                :fn="() => {deleteKey(key)}"
                                :confirm="System.lang('apikeys.confirmDel')"
                                :confirmHint="(key.name)"
                                confirmColor="error"
                                :confirmIcon="mdiCloseBoxOutline"
                            />
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>            
        <div v-else class="text-center mt-5">
            <v-divider class="my-3"></v-divider>
            <v-icon color="grey" size="30">{{mdiKey}}</v-icon>
            <p class="text-caption grey--text mt-4">{{System.lang('apikeys.noKeys1')}}</p>
            <p class="text-caption primary--text">{{System.lang('apikeys.noKeys2')}}</p>
        </div>
        <div>
            <v-divider class="my-3"></v-divider>
            <ActionButton
                :icon="mdiKeyPlus"
                color="primary" 
                :label="System.lang('apikeys.buttons.new.label')"
                :hint="System.lang('apikeys.buttons.new.hint')"
                :fn="() => {newKeyDialog.open()}"
            />
        </div>
    </v-sheet>
    
    
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-5">
        <div class="">
            <a href="https://shorten-url.readthedocs.io/" target="_blank">{{System.lang('apikeys.viewDoc')}}</a>
        </div>
    </v-sheet>




    <!-- Add new key -->
    <SimpleDialog
        :dialog="newKeyDialog"
        :title="System.lang('apikeys.newKeyDialog')"
        :icon="mdiKeyPlus"
        @close="newKeyDialog.close()"
        :width="500"
    >
        <SimpleForm 
            @ref="newKeyForm.ref = $event"
            @input="newKeyForm.valid = $event"
        >
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-text-field
                        :label="System.lang('apikeys.newKeyForm.name')"
                        :hint="System.lang('apikeys.newKeyForm.nameHint')"
                        counter="100"
                        v-model="newKeyForm.values.name"
                        :rules="newKeyForm.rules.name"
                        :prepend-icon="mdiTextBoxEditOutline"
                        :error-messages="newKeyForm.errors.name"
                        @click="() => {newKeyForm.resetError('name')}"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-switch
                        v-model="newKeyForm.values.is_readonly"
                        hide-details
                        :label="System.lang('apikeys.newKeyForm.readonly')"
                    ></v-switch>
                </v-col>
                <v-col cols="10" sm="8">
                    <v-menu
                        v-model="menuKeyExpires"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="newKeyForm.values.valid_till"
                                :label="System.lang('apikeys.newKeyForm.expiry')"
                                :hint="System.lang('apikeys.newKeyForm.expiryHint')"
                                :prepend-icon="mdiCalendarRemoveOutline"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click="() => {newKeyForm.resetError('expiry')}"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="newKeyForm.values.valid_till"
                            first-day-of-week="1"
                            :min="currentDate"
                            no-title
                            scrollable
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="menuKeyExpires = false"
                            >
                                {{System.lang('buttons.ok')}}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="success"
                                @click="newKeyForm.values.valid_till = undefined; menuKeyExpires = false"
                            >
                                {{System.lang('apikeys.neverExpires')}}
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addNewKey"
                :disabled="!newKeyForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newKeyDialog.close()}"
            />
        </template>
    </SimpleDialog>


    <!-- Show key -->
    <SimpleDialog
        v-if="selectedKey"
        :dialog="showKeyDialog"
        :title="System.lang('apikeys.showKeyDialog')"
        :hint="System.lang('apikeys.showKeyDialogHint')"
        :icon="mdiKey"
        @close="showKeyDialog.close()"
        :width="650"
    >
        <div class="text-center">
            <code>{{selectedKey.key}}</code>
        </div>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary"
                :label="System.lang('buttons.copy.label')"
                :fn="() => {System.copy(selectedKey.key)}"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.close')"
                :fn="() => {showKeyDialog.close()}"
            />
        </template>
    </SimpleDialog>


</div>
</template>


<script>
import {
    mdiKey,
    mdiKeyPlus,
    mdiRefresh,
    mdiEye,
    mdiCloseBoxOutline,
    mdiContentCopy,
    mdiCalendarRemoveOutline,
    mdiTextBoxEditOutline,
} from '@mdi/js';

import System from '@/classes/System';
import User from '@/classes/User';
import Dates from '@/classes/Dates';
import Interface from '@/classes/Interface'
import {Dialog, Form} from '@/classes/Elements'
import {APIKEY_STATUS, APIKEY_STATUSES} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
// import IconLink from '@/components/system/IconLink'
import IconHint from '@/components/system/IconHint'
import SimpleForm from '@/components/forms/SimpleForm'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'

export default {
    name: 'ApiKeysView',
    components: {
        HeaderPanel,
        IconButton,
        // IconLink,
        IconHint,
        SimpleForm,
        SimpleDialog,
        ActionButton,
    },
    data () {
        return {
            // Icons
            mdiKey,
            mdiKeyPlus,
            mdiRefresh,
            mdiEye,
            mdiCloseBoxOutline,
            mdiContentCopy,
            mdiCalendarRemoveOutline,
            mdiTextBoxEditOutline,
            // Globals
            System,
            APIKEY_STATUS,
            APIKEY_STATUSES,
            // Internal
            loadingKeys: false,
            menuKeyExpires: false,
            // Data
            keys: undefined,
            selectedKey: undefined,
            // Tables
            // Forms
            newKeyForm: new Form({
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^.{0,100}$/.test(v) || System.lang('val.shorter'),
                ],
                is_readonly: [],
                valid_till: [],
            }),
            // Dialogs
            newKeyDialog: new Dialog(() => {this.newKeyForm.reset({is_readonly: false})}),
            showKeyDialog: new Dialog(),
        }
    },
    computed: {
        currentDate () {
            return Dates.today();
        },
    },
    methods: {
        init () {
            System.setTitle('titles.api');
            this.fetchKeys();
        },
        // Data fetch functions
        async fetchKeys () {
            this.keys = undefined;
            this.loadingKeys = true;

            return Api.get('/system/api-keys')
                .then(data => {
                    this.keys = data;
                    this.newKeyForm.resetErrors();
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingKeys = false;
                });
        },
        // Data update functions
        async addNewKey () {
            if (!this.newKeyForm.validate()) return;

            return Api.post('/system/api-keys', this.newKeyForm.values)
                .then(data => {
                    this.selectedKey = data;
                    this.keys.push(data);
                    this.newKeyDialog.close();
                    this.showKeyDialog.open();
                    Interface.snackSuccess(System.lang('apikeys.messages.KEY_ADDED'));
                    return data;
                })
                .catch(error => {
                    if (!this.newKeyForm.catch(error)) {
                        if (error.code == 'PLAN_LIMIT') {
                            Interface.popupPlan(System.lang('messages.PLAN_ALLOWED'));
                        } else {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    }
                });
        },
        async deleteKey (key) {
            return Api.delete(`/system/api-keys/${key.id}`, {})
                .then(data => {
                    this.keys = data;
                    Interface.snackSuccess(System.lang('apikeys.messages.KEY_DELETED'));
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        // Helper functions
        human (date, format = 2) {
            return Dates.human(date, format);
        },
    },
    mounted () {
        this.init();
    },
    beforeDestroy () {
        User.resetSaved();
    },
}
</script>


<style scoped>
</style>
