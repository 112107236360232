<template>
    <div>
        <v-progress-linear indeterminate rounded class="my-2" v-if="loadingRules"></v-progress-linear>

        <!-- Rules -->
        <div v-else>
            <div v-if="!rules.length" class="text-center my-8">
                <v-icon color="grey" size="30">{{mdiArrowDecisionOutline}}</v-icon>
                <p class="mt-3 mb-1 text-caption grey--text">{{System.lang('rules.rulesHint')}}</p>
                <p class="text-caption orange--text">{{System.lang('rules.noRulesHint')}}</p>
            </div>
            <v-simple-table 
                v-else
                dense
                fixed-header
                class="mb-2"
                :height="(rules.length > 10 ? 400 : undefined)"
            >
                <thead>
                    <tr class="text-no-wrap">
                        <th class="text-left" style="width: 80px;">{{System.lang('rules.rulesTable.priority')}}</th>
                        <th class="text-left">{{System.lang('rules.rulesTable.if')}}</th>
                        <th class="text-left">{{System.lang('rules.rulesTable.to')}}</th>
                        <th class="text-center" style="width: 100px;">{{System.lang('rules.rulesTable.actions')}}</th>
                    </tr>
                </thead>
                <tbody class="text-no-wrap">
                    <tr v-for="rule in rules" :key="rule.id">
                        <td>{{rule.priority}}</td>
                        <td>
                            <span v-if="noRulesDefined(rule)" class="text-caption grey--text">{{System.lang('rules.rulesTable.never')}}</span>
                            <IconHint v-if="rule.rule_lang.length" :icon="RULE_TYPES.lang.icon" :color="RULE_TYPES.lang.iconColor" :hint="rule.rule_lang.map(code => langname(code)).join(', ')" />
                            <IconHint v-if="rule.rule_country.length" :icon="RULE_TYPES.country.icon" :color="RULE_TYPES.country.iconColor" :hint="rule.rule_country.map(code => countryname(code)).join(', ')" />
                            <IconHint v-if="rule.rule_device.length" :icon="RULE_TYPES.device.icon" :color="RULE_TYPES.device.iconColor" :hint="rule.rule_device.map(code => System.lang(`rules.devices.${code}`)).join(', ')" />
                            <IconHint v-if="rule.rule_os.length" :icon="RULE_TYPES.os.icon" :color="RULE_TYPES.os.iconColor" :hint="rule.rule_os.join(', ')" />
                            <IconHint v-if="rule.rule_browser.length" :icon="RULE_TYPES.browser.icon" :color="RULE_TYPES.browser.iconColor" :hint="rule.rule_browser.join(', ')" />
                        </td>
                        <td>
                            <IconHint :icon="type(rule.link_type_id).icon" :color="type(rule.link_type_id).iconColor" :hint="System.lang(`links.types.${typecode(rule.link_type_id)}.name`)" />
                            <span>{{rule.data}}</span>
                        </td>
                        <td class="text-center">
                            <IconButton
                                small
                                color="primary"
                                :icon="mdiCogOutline"
                                :hint="System.lang('buttons.config.hint')"
                                :fn="() => {openEdit(rule)}"
                            />
                            <IconButton
                                small
                                color="error"
                                :icon="mdiCloseBoxOutline"
                                :hint="System.lang('buttons.delete')"
                                :fn="() => {del(rule)}"
                                :confirm="System.lang('rules.confirmDel')"
                                confirmColor="error"
                                :confirmIcon="mdiCloseBoxOutline"
                            />
                        </td>
                    </tr>    
                </tbody>
            </v-simple-table>

            <ActionForm
                :fn="saveRules"
                @errors="ruleForm.errors = $event"
            >
                <template v-slot:actions>
                    <ActionButton 
                        color="orange" 
                        :label="System.lang('rules.buttons.add.label')"
                        :hint="System.lang('rules.buttons.add.hint')"
                        text 
                        :icon="mdiPlusBoxOutline"
                        :fn="openAdd"
                    />
                </template>
            </ActionForm>
        </div>


        <!-- Rule edit dialog -->
        <SimpleDialog
            :dialog="ruleDialog"
            :title="(editMode ? System.lang('rules.ruleDialog.titleEdit') : System.lang('rules.ruleDialog.titleAdd'))"
            :icon="(editMode ? mdiSquareEditOutline : mdiPlusBoxOutline)"
            @close="ruleDialog.close()"
            :width="750"
        >
            <SimpleForm
                v-if="rule"
                @ref="ruleForm.ref = $event"
                @input="ruleForm.valid = $event"
            >
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-select
                            v-model="selectedRuleType"
                            item-value="code"
                            return-object
                            :items="ruleTypes"
                            :label="System.lang('rules.ruleForm.if')"
                            hide-details
                        >
                            <template v-slot:item="{ item }">
                                <v-icon left :color="item.iconColor">{{item.icon}}</v-icon>
                                {{System.lang(`rules.types.${item.code}.name`)}}
                            </template>

                            <template v-slot:selection="{ item }">
                                <v-icon :color="item.iconColor" left small>{{item.icon}}</v-icon>
                                <span>{{System.lang(`rules.types.${item.code}.name`)}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-autocomplete
                            v-if="selectedRuleType?.code == 'lang'"
                            v-model="rule.rule_lang"
                            item-value="code"
                            item-text="name_ml"
                            multiple
                            chips
                            deletable-chips
                            hide-details
                            :items="langs"
                            :label="System.lang('rules.ruleForm.select')"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-if="selectedRuleType?.code == 'country'"
                            v-model="rule.rule_country"
                            item-value="code"
                            item-text="name_ml"
                            multiple
                            chips
                            deletable-chips
                            hide-details
                            :items="countries"
                            :label="System.lang('rules.ruleForm.select')"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-if="selectedRuleType?.code == 'device'"
                            v-model="rule.rule_device"
                            multiple
                            chips
                            deletable-chips
                            hide-details
                            :items="sources"
                            :label="System.lang('rules.ruleForm.select')"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-if="selectedRuleType?.code == 'os'"
                            v-model="rule.rule_os"
                            multiple
                            chips
                            deletable-chips
                            hide-details
                            :items="os"
                            :label="System.lang('rules.ruleForm.select')"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-if="selectedRuleType?.code == 'browser'"
                            v-model="rule.rule_browser"
                            multiple
                            chips
                            deletable-chips
                            hide-details
                            :items="browsers"
                            :label="System.lang('rules.ruleForm.select')"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <div class="mt-4 mb-4 pl-4">
                    <v-icon left color="orange" size="30">{{mdiArrowDownThick}}</v-icon>
                    <span class="grey--text text-caption">{{System.lang('rules.redirectto')}}</span>
                </div>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-select
                            v-model="rule.link_type_id"
                            item-value="id"
                            :items="linkTypes"
                            :label="System.lang('rules.ruleForm.type')"
                            @change="() => {$refs.linkdata.resetValidation(); $refs.linkdata.focus()}"
                            hide-details
                        >
                            <template v-slot:item="{ item }">
                                <v-icon left :color="item.iconColor">{{item.icon}}</v-icon>
                                {{System.lang(`links.types.${item.code}.name`)}}
                            </template>

                            <template v-slot:selection="{ item }">
                                <v-icon :color="item.iconColor" left small>{{item.icon}}</v-icon>
                                <span>{{System.lang(`links.types.${item.code}.name`)}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-text-field
                            v-if="rule.link_type_id"
                            counter="500"
                            ref="linkdata"
                            v-model="rule.data"
                            :label="System.lang(`links.types.${typecode(rule.link_type_id)}.dataName`)"
                            :hint="System.lang(`links.types.${typecode(rule.link_type_id)}.dataHint`)"
                            :rules="Links.type(rule.link_type_id)?.rules"
                            :error-messages="ruleForm.errors.data"
                            @click="() => {ruleForm.resetError('data')}"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="8" md="6">
                        <p class="pl-0 mt-5 mb-0 grey--text text--darken-2 text-caption">
                            {{System.lang('rules.ruleForm.priority')}}
                        </p>                        
                        <v-slider
                            v-model="rule.priority"
                            min="0"
                            max="100"
                            thumb-label
                        ></v-slider>
                    </v-col>
                </v-row>
            </SimpleForm>

            <template v-slot:actions>
                <ActionButton
                    text
                    :block="false"
                    color="primary" 
                    :label="(editMode ? System.lang('buttons.ok') : System.lang('buttons.add'))"
                    :fn="(editMode ? save : add)"
                    :disabled="!ruleForm.valid"
                />
                <v-spacer></v-spacer>
                <ActionButton
                    text
                    :block="false"
                    color="grey"
                    :label="System.lang('buttons.cancel')"
                    :fn="() => {ruleDialog.close()}"
                />
            </template>
        </SimpleDialog>

    </div>
</template>


<script>
import {
    mdiCloseBoxOutline,
    mdiPlusBoxOutline,
    mdiCogOutline,
    mdiSquareEditOutline,
    mdiArrowDownThick,
    mdiArrowDecisionOutline,
} from '@mdi/js';

import System from '@/classes/System'
import Interface from '@/classes/Interface'
import Links from '@/classes/Links'
import Api from '@/services/api'
import {Form, Dialog} from '@/classes/Elements'
import {RULE_TYPES} from '@/constants/system'

import ActionForm from '@/components/forms/ActionForm'
import IconButton from '@/components/system/IconButton'
import IconHint from '@/components/system/IconHint'
import ActionButton from '@/components/system/ActionButton'
import SimpleDialog from '@/components/system/SimpleDialog'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'LinkRules',
    components: {
        ActionForm,
        IconButton,
        IconHint,
        ActionButton,
        SimpleDialog,
        SimpleForm,
    },
    props: {
        link: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            Links,
            RULE_TYPES,
            // Icons
            mdiCloseBoxOutline,
            mdiPlusBoxOutline,
            mdiCogOutline,
            mdiSquareEditOutline,
            mdiArrowDownThick,
            mdiArrowDecisionOutline,
            // Internal
            loadingRules: false,
            selectedRuleType: undefined,
            // Data
            linkTypes: [],
            rules: [],
            rule: Links.createEmptyRule(this.link.link_type_id),
            countries: [],
            langs: [],
            browsers: [],
            // Data structures
            sources: [
                {value: 'bot', text: System.lang('rules.devices.bot')},
                {value: 'desktop', text: System.lang('rules.devices.desktop')},
                {value: 'mobile', text: System.lang('rules.devices.mobile')},
                {value: 'tv', text: System.lang('rules.devices.tv')},
                {value: 'wear', text: System.lang('rules.devices.wear')},
            ],
            os: [
                'Android',
                'iOS',
                'Linux',
                'Mac',
                'Windows',
                'Windows Mobile',
            ],
            // Forms
            ruleForm: new Form({
                id: [],
                link_type_id: [],
                data: [],
                priority: [],
            }),
            // Dialogs
            ruleDialog: new Dialog(() => {this.ruleForm.reset()}),
        }
    },
    computed: {
        editMode () {
            return !!this.rule?.id;
        },
        ruleTypes () {
            return Links.getRuleTypes();
        },
    },
    methods: {
        init () {
            this.fetchRules();
            this.fetchLinkTypes();
            this.fetchCountries();
            this.fetchLangs();
            // this.fetchOs();
            this.fetchBrowsers();
        },
        // Data fetch functions
        async fetchRules () {
            this.loadingRules = true;

            return Api.get(`/links/${this.link.id}/rules`)
                .then(data => {
                    this.rules = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingRules = false;
                });
        },
        async fetchLinkTypes () {
            return Links.getLinkTypes()
                .then(data => {
                    this.linkTypes = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async fetchCountries () {
            return Links.getCountries()
                .then(data => {
                    this.countries = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async fetchLangs () {
            return Links.getLangs()
                .then(data => {
                    this.langs = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        // async fetchOs () {
        //     return Links.getOs()
        //         .then(data => {
        //             this.os = data;
        //             return data;
        //         })
        //         .catch(error => {
        //             Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
        //         });
        // },
        async fetchBrowsers () {
            return Links.getBrowsers()
                .then(data => {
                    this.browsers = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        // Data update functions
        saveRules () {
            return Api.patch(`/links/${this.link.id}/rules`, {rules: this.rules})
                .then(data => {
                    this.rules = data;
                    return data;
                });
        },

        // Rule functions
        openAdd () {
            this.rule = Links.createEmptyRule(this.link.link_type_id);
            this.selectedRuleType = this.ruleTypes[0];
            this.ruleDialog.open();
            return;
        },
        openEdit (rule) {
            this.rule = {...rule};
            this.selectedRuleType = this.ruleTypes[0];
            this.ruleDialog.open();
            return;
        },
        add () {
            if (this.ruleForm.validate()) {
                this.rule.id = Links.generateMaxArrId(this.rules);
                this.rules.push(this.rule);
                this.rules = Links.sortPriority(this.rules);
                this.ruleDialog.close();
            }
            return;
        },
        save () {
            if (this.ruleForm.validate()) {
                this.rules = this.rules.map(rule => (rule.id == this.rule.id ? this.rule : rule));
                this.rules = Links.sortPriority(this.rules);
                this.ruleDialog.close();
            }
            return;
        },
        del (rule) {
            this.rules = this.rules.filter(r => r.id != rule.id);
            return;
        },
        // Helper functions
        type (id) {
            return Links.type(id);
        },
        typecode (id) {
            return Links.typecode(id);
        },
        langname (code) {
            return Links.langname(code);
        },
        countryname (code) {
            return Links.countryname(code);
        },
        noRulesDefined (rule) {
            return (
                !rule.rule_lang.length &&
                !rule.rule_country.length &&
                !rule.rule_device.length  &&
                !rule.rule_os.length &&
                !rule.rule_browser.length
            )
        },
    },
    mounted () {
        this.init();
    },

}
</script>


<style scoped>
</style>
