import axios from 'axios'
import store from '@/store'
import router from '@/router'

// import Interface from '@/classes/Interface'
import User from '@/classes/User'
import Interface from '@/classes/Interface'
import Err from '@/classes/Err'
import Api from '@/services/api'
import Settings from '@/constants/settings'
import {LANGS} from '@/constants/interface'


class System {
    constructor () {
        this.self = null;
        this.locale = undefined;
    }


// Initialization

    init (self) {
        // Save self
        this.self = self;

        // Set language
        this.setLanguage(this.computeLanguage());
        this.setTitle();

        // Set debug
        if (process.env.NODE_ENV == 'development' ||
            this.self.$route.query.debug === null || 
            Settings.debug
        ) {
            this.debugOn();
        } else {
            this.debugOff();
        }

        // Print settings
        this.debug('Settings: ', Settings);

        // Call backend init
        return Api.get('/init')
            .then(data => {
                this.debug('Route: ', router.currentRoute.name);
                this.setData(data.system.data);
                this.setSettings(data.system.settings);
                this.setLangs(data.langs);
                if (data.user) {
                    User.setLogin(data.user);
                    this.restoreRoute(this.isGuestRoute() ? 'home' : false);
                } else if (!this.isGuestRoute() && !this.isConfirmRoute()) {
                    this.redirectToLogin();
                }
                return data;
            });
    }

    echo () {
        return Api.get('/echo');
    }

// Data store functions

    setData (data) {
        store.commit('system/setData', data);
    }

    setSettings (data) {
        store.commit('system/setSettings', data);
    }

    getSettings (item = false) {
        if (item) return store.state.system.settings[item];
        return store.state.system.settings;
    }

    setLangs (data) {
        store.commit('system/setLangs', data);
    }

// Helper functions

    isMobile () {
        return this.self.$vuetify.breakpoint.xsOnly || false;
    }

    setTitle (title = false) {
        if (title) {
            document.title = this.lang('titles.common') + ' : ' + this.lang(title);
        } else {
            document.title = this.lang('titles.common');
        }
    }

    copy (text) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    Interface.snackSuccess(this.lang('messages.CLIPBOARD_COPIED'));
                })
                .catch(() => {
                    Interface.snackError(this.lang('messages.CLIPBOARD_FAILED'));
                });
        } else {
            Interface.snackError(this.lang('messages.CLIPBOARD_NOT_AVAIL'));
        }
    }

    async recaptcha (action) {
        let token = '';

        // this.self.$recaptchaInstance.siteKey = this.getSettings('recaptcha_site_key');
        this.debug('Captcha key: ', this.self.$recaptchaInstance.siteKey);

        try {
            await this.self.$recaptchaLoaded();
            this.debug('Captcha loaded');
            token = await this.self.$recaptcha(action);
        } catch (error) {
            this.debug('Captcha failed');
            token = false;
        }
        
        this.debug('Captcha token: ', token);

        return token;
    }



// Debug functions

    debugOn () {
        console.log('System debug enabled');
        store.commit('system/debugOn');
    }

    debugOff () {
        store.commit('system/debugOff');
    }

    debug(...args) {
        if (store.state.system.debug) {
            console.log(...args);
        }
    }


// Language functions

    getLanguage (locale) {
        let found = 0;

        LANGS.forEach((lang, index) => {
            if (lang.locale == locale) {
                found = index;
            }
        });

        return LANGS[found];
    }

    setLanguage (locale) {
        this.self.$vuetify.lang.current = locale;
        this.locale = locale;
        localStorage.setItem('locale', locale);
        document.cookie = `locale=${locale}`;
    }

    computeLanguage (locale = 'en') {
        let result = this.self.$route.query.lang || localStorage.getItem('locale') || navigator.language.split('-', 1)[0] || locale;
        return this.getLanguage(result).locale || locale;
    }

    lang (label, ...args) {
        let text = this.self?.$vuetify?.lang.t(`$vuetify.${label}`, ...args) || label;
        if (text.startsWith('$vuetify')) text = label;
        return text;
    }

    createLangsObject () {
        let langs = {};
        store.state.system.langs.forEach(lang => {
            langs[lang.code] = '';
        });
        return langs;
    }


// Network functions

    request (method, url, params = false, data = false, isAuth = false, type = 'json') {
        let config = {
            url: url,
            method: method,
            responseType: type,
            withCredentials: isAuth,
            headers: {
                'Locale': this.locale,
            },
        }

        this.debug(`Request [${config.method}][${config.url}]`);

        if (params) {
            config.params = params;
            this.debug('Params: ', config.params);
        }
        
        if (data) {
            config.data = data;
            this.debug('Data: ', config.data);
        }

        return new Promise ((resolve, reject) => {
            axios(config)
                .then(response => {
                    this.debug(`Response [${config.url}][${response.status}][${response.statusText}]`);
                    this.debug('Data: ', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    // User auth or xsrf error
                    if (error.response && ['401','419'].includes(String(error.response.status))) {
                        User.setLogout();
                        this.redirectToLogin();
                        Interface.messageWarning(this.lang('messages.SESSION_EXPIRED'));
                    // Application error
                    } else if (error.response?.data?.code) {
                        reject(new Err(error.response.data.code, error.response.data.message, error.response.data.errors));
                    // Some other HTTP response
                    } else if (error.response) {
                        reject(new Err(String(error.response.status), undefined, error.response.data?.errors));
                    // Network or connection error
                    } else if (error.request) {
                        reject(new Err('NETWORK'));
                    // Unknown error
                    } else {
                        reject(new Err('UNKNOWN'));
                    }                
                });            
        })
    }

// Router functions

    isLoginRoute () {
        return router.currentRoute.name == 'login';
    }

    isConfirmRoute () {
        return router.currentRoute.name == 'confirm';
    }

    isGuestRoute () {
        let routes = [
            'login',
            'register',
            'remind',
            'reset',
            'login-super',
        ];
        return routes.includes(router.currentRoute.name);
    }

    saveRoute () {
        let path = router.currentRoute.fullPath;
        localStorage.setItem('to_route', path);
    }

    restoreRoute (dflt = false) {
        let to = localStorage.getItem('to_route');

        if (to) {
            localStorage.removeItem('to_route');
            router.push(to).catch(() => {});
        } else if (dflt) {
            this.redirectTo(dflt);
        }
    }

    redirectTo (to, params = {}) {
        if (router.currentRoute.name != to)
            router.push({name: to, ...params}).catch(() => {});
    }

    redirectToLogin (saveCurrent = true) {
        if (saveCurrent && !this.isLoginRoute()) {
            this.saveRoute();
        }
        this.redirectTo('login');
    }

    redirectToHome () {
        this.redirectTo('home');
    }

// Local settings functions

    loadSetting (name, dflt = false) {
        let value = localStorage.getItem('settings.' + name) || dflt;

        if (typeof dflt == 'number') {
            return Number(value);
        } else if (typeof dflt == 'boolean') {
            return Boolean(value);
        } else {
            return value;
        }
    }
    
    saveSetting (name, value) {
        localStorage.setItem('settings.' + name, value);
    }

// Helpers

    saveFile (filename, data) {
        let downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = data;
        downloadLink.target = '_self';
        downloadLink.download = filename;
        downloadLink.click();
        downloadLink.remove();
    }

    svgToImage (svgString, width, height, format, callback) {
        // set default for format parameter
        format = format ? format : 'png';
        // SVG data URL from SVG string
        let svgData = svgString;
        // create canvas in memory(not in DOM)
        let canvas = document.createElement('canvas');
        // get canvas context for drawing on canvas
        let context = canvas.getContext('2d');
        // set canvas size
        canvas.width = width;
        canvas.height = height;
        // create image in memory(not in DOM)
        let image = new Image();
        // later when image loads run this
        image.onload = function () { // async (happens later)
            // clear canvas
            context.clearRect(0, 0, width, height);
            // draw image with SVG data to canvas
            context.drawImage(image, 0, 0, width, height);
            // snapshot canvas as png
            var pngData = canvas.toDataURL('image/' + format);
            // pass png data URL to callback
            callback(pngData);
        }; // end async
        // start loading SVG data into in memory image
        image.src = svgData;
    }    

}


export default new System();