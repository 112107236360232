<template>
    <div id="chart" ref="chart"></div>
</template>


<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import System from '@/classes/System'
import {STATS_GRANULA} from '@/constants/system'

export default {
    name: 'ClicksLineChart',
    components: {
    },
    props: {
        chartdata: {
            type: Array,
            required: true,
        },
        from: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
        granula: {
            type: Number,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            // Data
            root: undefined,
            observer: undefined,
        }
    },
    computed: {
    },
    methods: {
        createChart () {
            let root = am5.Root.new(this.$refs.chart);
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    layout: root.verticalLayout,
                    pinchZoomX: true,
                    panX: true,
                    panY: false,
                })
            );
            chart.set("cursor", am5xy.XYCursor.new(root, {}));
            chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    maxPrecision: 0,
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );
            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    baseInterval: {timeUnit: (this.granula == STATS_GRANULA.DAY ? "day" : "hour"), count: 1},
                    min: new Date(this.from).getTime(),
                    max: new Date(this.to).getTime(),
                    renderer: am5xy.AxisRendererX.new(root, {}),
                    tooltip: am5.Tooltip.new(root, {}),
                })
            );
            
            let legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            }));
            let seriesSuccess = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: System.lang('stats.charts.1.success'),
                    fill: am5.color(0x5C6BC0),
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "success",
                    valueXField: "when",
                })
            );
            seriesSuccess.columns.template.setAll({
                tooltipText: "{name}: {valueY}",
                tooltipY: am5.percent(10)
            });
            seriesSuccess.data.processor = am5.DataProcessor.new(root, {
                numericFields: ["success"],
                dateFields: ["when"],
                dateFormat: "yyyy-MM-dd"
            });
            legend.data.push(seriesSuccess);
            let seriesBlocked = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: System.lang('stats.charts.1.blocked'),
                    fill: am5.color(0xF44336),
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "blocked",
                    valueXField: "when",
                })
            );
            seriesBlocked.columns.template.setAll({
                tooltipText: "{name}: {valueY}",
                tooltipY: am5.percent(10)
            });
            seriesBlocked.data.processor = am5.DataProcessor.new(root, {
                numericFields: ["blocked"],
                dateFields: ["when"],
                dateFormat: "yyyy-MM-dd"
            });
            legend.data.push(seriesBlocked);
            // seriesBlocked.appear();

            xAxis.data.setAll(this.chartdata);
            seriesSuccess.data.setAll(this.chartdata);
            seriesBlocked.data.setAll(this.chartdata);

            this.root = root;
        },
    },
    mounted () {
        this.observer = new IntersectionObserver(entries => {
            if (!this.root && entries[0].isIntersecting) {
                this.createChart();
            }
        });
        this.observer.observe(this.$refs.chart);
        
        // this.createChart();
        // this.$nextTick(() => this.createChart());
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
    },
}
</script>


<style scoped>
#chart {
    height: 350px;
    font-size: 0.8rem;
}
</style>
