<template>
    <div>
        <v-progress-linear indeterminate rounded class="my-2" v-if="loading"></v-progress-linear>

        <ActionForm
            v-if="filter"
            :fn="updateFilter"
            @errors="filterForm.errors = $event"
        >
            <v-row dense class="mb-3">
                <v-col cols="12" sm="8" lg="7">
                    <p class="text-body-2 mt-2 mb-0 grey--text text--darken-1">{{System.lang('filters.types.device.allowed')}}</p>
                    <v-switch
                        v-model="allow_desktop"
                        hide-details
                        :label="System.lang('filters.types.device.allow_desktop')"
                    ></v-switch>                    
                    <v-switch
                        v-model="allow_mobile"
                        hide-details
                        :label="System.lang('filters.types.device.allow_mobile')"
                    ></v-switch>                    
                    <v-switch
                        v-model="allow_tv"
                        hide-details
                        :label="System.lang('filters.types.device.allow_tv')"
                    ></v-switch>                    
                    <v-switch
                        v-model="allow_wear"
                        hide-details
                        :label="System.lang('filters.types.device.allow_wear')"
                    ></v-switch>                    
                    <v-switch
                        v-model="allow_other"
                        hide-details
                        :label="System.lang('filters.types.device.allow_other')"
                    ></v-switch>                    
                </v-col>
            </v-row>

        </ActionForm>
    </div>
</template>


<script>
import {
    mdiEye,
} from '@mdi/js';

import System from '@/classes/System'
import Interface from '@/classes/Interface'
import Api from '@/services/api'
import {Form} from '@/classes/Elements'
import {FILTER_ACTION} from '@/constants/system'

import ActionForm from '@/components/forms/ActionForm'

export default {
    name: 'FilterDevice',
    components: {
        ActionForm,
    },
    props: {
        link: {
            type: [Object, Boolean],
            default: false,
        },
        group: {
            type: [Object, Boolean],
            default: false,
        },
        filterType: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            // Globals
            System,
            FILTER_ACTION,
            // Icons
            mdiEye,
            // Internal
            loading: false,
            // Data
            allow_desktop: true,
            allow_mobile: true,
            allow_tv: true,
            allow_wear: true,
            allow_other: true,
            filter: undefined,
            // Forms
            filterForm: new Form({
                action: [],
                data: [],
            }),
        }
    },
    computed: {
        url () {
            if (this.link) return `/links/${this.link.id}/filter/${this.filterType.type}`;
            return `/groups/${this.group.id}/filter/${this.filterType.type}`;
        },
        filterData () {
            return {
                allow_desktop: this.allow_desktop,
                allow_mobile: this.allow_mobile,
                allow_tv: this.allow_tv,
                allow_wear: this.allow_wear,
                allow_other: this.allow_other,
            }
        },
    },
    methods: {
        init () {
            this.fetchFilter();
        },
        // Data fetch functions
        async fetchFilter () {
            this.loading = true;
            this.filter = undefined;

            return Api.get(this.url)
                .then(data => {
                    this.filter = data;
                    this.allow_desktop = data.data?.allow_desktop ?? true;
                    this.allow_mobile = data.data?.allow_mobile ?? true;
                    this.allow_tv = data.data?.allow_tv ?? true;
                    this.allow_wear = data.data?.allow_wear ?? true;
                    this.allow_other = data.data?.allow_other ?? true;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // Data update functions
        async updateFilter () {
            this.filter.data = {};
            Object.assign(this.filter.data, this.filterData);

            return Api.patch(this.url, this.filter)
                .then(data => {
                    this.filter = data;
                    this.allow_desktop = data.data?.allow_desktop ?? true;
                    this.allow_mobile = data.data?.allow_mobile ?? true;
                    this.allow_tv = data.data?.allow_tv ?? true;
                    this.allow_wear = data.data?.allow_wear ?? true;
                    this.allow_other = data.data?.allow_other ?? true;
                    return data;
                });
        },
    },
    mounted () {
        this.init();
    },

}
</script>


<style scoped>
</style>
