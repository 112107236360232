<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingDomains"></v-progress-linear>    
    
    <!-- Domains -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="domains">
        <HeaderPanel
            :title="System.lang('domains.domains')"
            :icon="mdiDomain"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiDomainPlus"
                :hint="System.lang('domains.buttons.new.hint')"
                :fn="() => {newDomainDialog.open()}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>

        <div v-if="hasAdding" class="mb-5">
            <v-alert
                border="left"
                colored-border
                type="info"
                elevation="2"
            >
                <p>{{System.lang('domains.nsHint')}}</p>
                <ul>
                    <li v-for="i in 4" :key="i">
                        <code class="transparent">{{systemSettings[`ns${i}`]}}</code>
                        <IconButton
                            x-small
                            :icon="mdiContentCopy"
                            :hint="System.lang('buttons.copy.hint')"
                            color="primary"
                            :fn="() => {return System.copy(systemSettings[`ns${i}`].split(' ')[0])}"
                        />
                    </li>
                </ul>
            </v-alert>
        </div>
        <div v-if="domains.length" class="text-center">
            <v-simple-table 
                dense
                fixed-header
                class="mb-2"
            >
                <thead>
                    <tr class="text-no-wrap">
                        <th class="text-left">{{System.lang('domains.domainsTable.domain')}}</th>
                        <th class="text-left">{{System.lang('domains.domainsTable.added')}}</th>
                        <th class="text-center">{{System.lang('domains.domainsTable.count')}}</th>
                        <th class="text-center" style="width: 100px;">{{System.lang('domains.domainsTable.status')}}</th>
                        <th class="text-center" style="width: 170px;">{{System.lang('domains.domainsTable.actions')}}</th>
                    </tr>
                </thead>
                <tbody class="text-no-wrap">
                    <tr v-for="domain in domains" :key="domain.id">
                        <td class="text-left">{{domain.domain}}</td>
                        <td class="text-left">{{human(domain.created_at)}}</td>
                        <td class="text-center">{{domain.links_count}}</td>
                        <td class="text-center"><IconHint :icon="DOMAIN_STATUSES[domain.status].icon" :color="DOMAIN_STATUSES[domain.status].iconColor" :hint="System.lang(`domains.status.${domain.status}`)" /></td>
                        <td class="text-center">
                            <IconButton
                                small
                                :disabled="domain.status == DOMAIN_STATUS.DELETING"
                                color="error"
                                :icon="mdiCloseBoxOutline"
                                :hint="System.lang('buttons.delete')"
                                :fn="() => {deleteDomain(domain)}"
                                :confirm="System.lang('domains.confirmDel')"
                                :confirmHint="(domain.domain)"
                                confirmColor="error"
                                :confirmIcon="mdiCloseBoxOutline"
                            />
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>            
        <div v-else class="text-center mt-5">
            <v-divider class="my-3"></v-divider>
            <v-icon color="grey" size="30">{{mdiDomain}}</v-icon>
            <p class="text-caption grey--text mb-3 mt-4">{{System.lang('domains.noDomains1')}}</p>
            <p class="text-caption grey--text mb-3">{{System.lang('domains.noDomains2')}}</p>
            <p class="text-caption grey--text">{{System.lang('domains.noDomains3')}}</p>
            <p class="text-caption primary--text">{{System.lang('domains.noDomains5')}}</p>
        </div>
        <div>
            <v-divider class="my-3"></v-divider>
            <ActionButton
                :icon="mdiDomainPlus"
                color="primary" 
                :label="System.lang('domains.buttons.new.label')"
                :hint="System.lang('domains.buttons.new.hint')"
                :fn="() => {newDomainDialog.open()}"
            />
        </div>
    </v-sheet>


    <!-- Add new domain -->
    <SimpleDialog
        :dialog="newDomainDialog"
        :title="System.lang('domains.newDomainDialog')"
        :icon="mdiDomainPlus"
        @close="newDomainDialog.close()"
        :width="350"
    >
        <SimpleForm 
            @ref="newDomainForm.ref = $event"
            @input="newDomainForm.valid = $event"
        >
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-text-field
                        :label="System.lang('domains.newDomainForm.domain')"
                        :hint="System.lang('domains.newDomainForm.domainHint')"
                        counter="100"
                        v-model="newDomainForm.values.domain"
                        :rules="newDomainForm.rules.domain"
                        :prepend-icon="mdiDomain"
                        :error-messages="newDomainForm.errors.domain"
                        @click="() => {newDomainForm.resetError('domain')}"
                    ></v-text-field>
                    <p class="text-caption text-center grey--text mt-4">{{System.lang('domains.newDomainForm.helpText')}}</p>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addDomain"
                :disabled="!newDomainForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newDomainDialog.close()}"
            />
        </template>
    </SimpleDialog>


</div>
</template>


<script>
import {
    mdiDomain,
    mdiDomainPlus,
    mdiRefresh,
    mdiCloseBoxOutline,
    mdiContentCopy,
} from '@mdi/js';

import System from '@/classes/System';
import User from '@/classes/User';
import Dates from '@/classes/Dates';
import Interface from '@/classes/Interface'
import {Dialog, Form} from '@/classes/Elements'
import {DOMAIN_STATUS, DOMAIN_STATUSES} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import IconHint from '@/components/system/IconHint'
import SimpleForm from '@/components/forms/SimpleForm'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'

export default {
    name: 'DomainsView',
    components: {
        HeaderPanel,
        IconButton,
        IconHint,
        SimpleForm,
        SimpleDialog,
        ActionButton,
    },
    data () {
        return {
            // Icons
            mdiDomain,
            mdiDomainPlus,
            mdiRefresh,
            mdiCloseBoxOutline,
            mdiContentCopy,
            // Globals
            System,
            DOMAIN_STATUS,
            DOMAIN_STATUSES,
            // Internal
            loadingDomains: false,
            // Data
            domains: undefined,
            selectedDomain: undefined,
            // Tables
            // Forms
            newDomainForm: new Form({
                domain: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/.test(v) || System.lang('val.domain'),
                ],
            }),
            // Dialogs
            newDomainDialog: new Dialog(() => {this.newDomainForm.reset()}),
        }
    },
    computed: {
        systemSettings () {
            return this.$store.state.system.settings;
        },
        hasAdding () {
            return this.domains?.find(d => d.status == DOMAIN_STATUS.ADDING);
        },
    },
    methods: {
        init () {
            System.setTitle('titles.domains');
            this.fetchDomains();
        },
        // Data fetch functions
        async fetchDomains () {
            this.domains = undefined;
            this.loadingDomains = true;

            return Api.get('/system/custom-domains')
                .then(data => {
                    this.domains = data;
                    this.newDomainForm.resetErrors();
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingDomains = false;
                });
        },
        // Data update functions
        async addDomain () {
            if (!this.newDomainForm.validate()) return;

            return Api.post('/system/custom-domains', this.newDomainForm.values)
                .then(data => {
                    data.links_count = 0;
                    this.selectedDomain = data;
                    this.domains.push(data);
                    this.newDomainDialog.close();
                    Interface.snackSuccess(System.lang('domains.messages.DOMAIN_ADDED'));
                    return data;
                })
                .catch(error => {
                    if (!this.newDomainForm.catch(error)) {
                        if (error.code == 'PLAN_LIMIT') {
                            Interface.popupPlan(System.lang('messages.PLAN_ALLOWED'));
                        } else {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    }
                });
        },
        async deleteDomain (domain) {
            return Api.delete(`/system/custom-domains/${domain.id}`, {})
                .then(data => {
                    this.domains = data;
                    Interface.snackSuccess(System.lang('domains.messages.DOMAIN_DELETED'));
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        // Helper functions
        human (date, format = 2) {
            return Dates.human(date, format);
        },
    },
    mounted () {
        this.init();
    },
    beforeDestroy () {
        User.resetSaved();
    },
}
</script>


<style scoped>
</style>
