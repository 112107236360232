import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import Settings from '@/constants/settings'

Vue.use(VueReCaptcha, { 
    siteKey: Settings.captchaSiteKey,
    // siteKey: 'xxx',
    loaderOptions: {
        autoHideBadge: true,
    }
});
