import {ru} from 'vuetify/lib/locale'

export default {
    ...ru,

    // Errors
    errors: {
        'DEFAULT': 'Ошибка {0}',
        '0': 'Ошибка сети или соединения',
        '403': 'Доступ запрещен',
        '404': 'Запрошенный ресурс не найден',
        '422': 'Форма содержит ошибки',
        '500': 'Ошибка сервера',
        'UNKNOWN': 'Неизвестная ошибка',
        'NETWORK': 'Ошибка сети или соединения',
        'BACKEND_FAILED': 'Сбой подключения к серверу',
        'LOGIN_FAILED': 'Неверный пароль',
    },

    // Global messages
    messages: {
        'REQUEST_FAILED': 'Ошибка запроса',
        'SAVE_OK': 'Сохранено',
        'SAVE_ERROR': 'Не удалось сохранить',
        'SESSION_EXPIRED': 'Ваша сессия истекла. Пожалуйста, войдите снова.',
        'CLIPBOARD_COPIED': 'Скопировано в буфер обмена',
        'CLIPBOARD_FAILED': 'Не удалось скопировать',
        'CLIPBOARD_NOT_AVAIL': 'Буфер обмена недоступен',
        'PLAN_LIMIT': 'Вы достигли лимита вашей подписки',
        'PLAN_ALLOWED': 'Функция недоступна в вашей подписке',
    },

    // Global buttons
    buttons: {
        ok: 'OK',
        cancel: 'Отмена',
        close: 'Закрыть',
        delete: 'Удалить',
        copy: {
            label: 'Копировать',
            hint: 'Копировать в буфер обмена',
        },
        confirm: 'Подтвердить',
        refresh: 'Обновить',
        add: 'Добавить',
        save: {
            label: 'Сохранить',
            hint: 'Сохранить изменения',
        },
        help: {
            label: 'Справка',
            hint: 'Показать справку',
        },
        config: {
            label: 'Настроить',
            hint: 'Настроить',
        },
        translate: {
            label: 'Перевести',
            hint: 'Переводы',
        },
        upgrade: 'Обновить',
    },

    // Interface
    interface: {
        logout: 'Выйти',
        info: 'Информация',
        success: 'Успех',
        warning: 'Предупреждение',
        error: 'Ошибка',
        rowsPerPage: 'На странице:',
        allItems: 'Все',
    },

    // Page titles
    titles: {
        common: 'shorten-url',
        account: 'Настройки аккаунта',
        api: 'API доступ',
        domains: 'Мои домены',
        group: 'Настройки группы',
        links: 'Мои ссылки',
        link: 'Настройки ссылки',
        logs: 'Логи ссылок',
        plans: 'Настройки подписки',
        login: 'Мой аккаунт',
        register: 'Создать новый аккаунт',
        forgotPass: 'Сброс пароля',
        stats: 'Статистика ссылок',
        support: 'Поддержка',
    },

    // Main menu
    menu: {
        urls: 'Короткие ссылки',
        myurls: 'Мои ссылки',
        domains: 'Мои домены',
        settings: 'Настройки',
        account: 'Мой аккаунт',
        plans: 'Моя подписка',
        apikeys: 'API доступ',
        help: 'Справка',
        support: 'Поддержка',
    },

    // Form validation messages
    val: {
        invalid: 'Недопустимые символы',
        required: 'Поле обязательно',
        shorter: 'Должно быть короче',
        longer: 'Должно быть длиннее',
        less: 'Должно быть меньше',
        more: 'Должно быть больше',
        numeric: 'Должно быть числовым',
        alphanumeric: 'Только буквы и цифры',
        url: 'Должен быть веб адресом и начинаться с http:// или https://',
        ip: 'Должен быть IP адресом',
        domain: 'Должен быть доменом',
        domainpart: 'Должен быть именем домена или частью',
        phone: 'Должен быть номером телефона',
        email: 'Должен быть адресом электронной почты',
        linkkey: 'Только буквы, цифры, - и _ символы',
        passmatch: 'Пароли не совпадают',
        agree: 'Вы должны согласиться с условиями обслуживания',
        telegram: 'Должен быть никнеймом Telegram',
        whatsapp: 'Должен быть номером телефона Whatsapp',
        instagram: 'Должен быть никнеймом Instagram',
        linkedin: 'Должен быть никнеймом LinkedIn',
        youtube: 'Должен быть идентификатором канала или хэндлом',
        facebook: 'Должен быть именем профиля Facebook',
    },

    // Login screen
    login: {
        username: {
            label: 'Электронная почта',
            hint: 'Введите вашу электронную почту',
            errors: [
                'Введите вашу электронную почту',
                'Должен быть адрес электронной почты',
            ],
        },
        password: {
            label: 'Пароль',
            hint: 'Введите ваш пароль',
            errors: [
                'Введите ваш пароль',
            ],
        },
        buttons: {
            login: "Войти",
        },
        errors: [
            'Неверный пароль',
            'Ошибка входа',
        ],
        remember: 'Запомнить меня',
        forgot: 'Забыли пароль?',
        register: 'Создать новый аккаунт',
        user: 'Пользователь',
    },

    // Register
    register: {
        regForm: {
            email: 'Электронная почта',
            emailHint: 'Электронная почта будет использоваться для входа',
            name: 'Ваше имя',
            nameHint: 'Как вас называть?',
            password: 'Пароль',
            passwordHint: 'Выберите свой пароль',
            password2: 'Подтверждение пароля',
            password2Hint: 'Подтвердите свой пароль',
        },
        createNew: 'Создать новый аккаунт',
        agreeTerms: 'Принять условия обслуживания и политику конфиденциальности',
        termsHint: 'Используя эту услугу, вы соглашаетесь с <a href="https://shorten-url.com/terms" target="_blank">Условиями</a> и <a href="https://shorten-url.com/policy" target="_blank">Политикой конфиденциальности</a>.',
        loginExisting: 'Войти с существующим аккаунтом',
        messages: {
            CAPTCHA_FAILED: 'Проверка капчи не удалась',
            REG_SUCCESS: 'Проверьте свою электронную почту, чтобы завершить регистрацию...',
            REG_FAILED: 'Регистрация не удалась',
            REG_OK: 'Проверьте свою электронную почту, чтобы завершить регистрацию...',
        },
    },

    // Remind password
    remind: {
        remindForm: {
            email: 'Электронная почта',
            emailHint: 'Ваша электронная почта для входа',
        },
        messages: {
            SEND_OK: 'Пожалуйста, проверьте свою почту для сброса пароля',
            SEND_FAILED: 'Не удалось отправить',
        },
        sendRemind: 'Отправить ссылку для сброса',
        sendRemindHint: 'Мы отправим вам ссылку для сброса пароля и инструкции, если указанный адрес электронной почты верен.',
        loginExisting: 'Вернуться к входу',
    },

    // Reset password
    reset: {
        resetForm: {
            password: 'Пароль',
            passwordHint: 'Выберите свой новый пароль',
            password2: 'Подтверждение пароля',
            password2Hint: 'Подтвердите свой новый пароль',
        },
        messages: {
            SET_OK: 'Ваш пароль изменен, используйте его для входа',
            SET_FAILED: 'Не удалось установить новый пароль',
        },
        setPass: 'Установить новый пароль',
        newPassHint: 'Пожалуйста, установите свой новый пароль',
        loginExisting: 'Вернуться к входу',
    },

    // Confirm new user email
    confirm: {
        messages: {
            CONFIRM_OK: 'Ваш адрес электронной почты успешно подтвержден.',
            CONFIRM_FAILED: 'Не удалось подтвердить адрес электронной почты',
        },
    },

    // Groups
    groups: {
        groups: 'Группы',
        default: 'Основная группа',
        disabled: 'Группа отключена. Все ссылки в этой группе будут неактивны, пока вы не включите группу.',
        newGroupHint: 'Создать новую группу',
        groupSettingsHint: 'Настройки группы',
        newGroupDialog: 'Новая группа',
        newGroupDialogHint: 'Создавайте новые группы ссылок для более удобного управления своими ссылками.',
        messages: {
            GROUP_ADDED: 'Группа создана',
        },
        newGroupForm: {
            name: 'Название группы',
            nameHint: 'Дайте группе любое название',
        },
    },

    // Group screen
    group: {
        backHint: 'Назад к ссылкам',
        group: 'Настройки группы',
        groupHint: 'Управление настройками группы, изменение названия группы и статуса. Если вы отключите группу, все ссылки в этой группе также будут отключены.',
        filters: 'Фильтры трафика',
        filtersHint: 'Фильтрация нежелательных посетителей по стране, IP-адресу или используемому устройству и программному обеспечению. Фильтры зависят от данных, отправляемых программным обеспечением посетителя, и могут быть неточными.',
        filterType: 'Тип фильтра группы:',
        messages: {
            GROUP_DELETED: 'Группа удалена',
        },
        status: {
            0: 'Все',
            1: 'Включено',
            2: 'Отключено',
        },
        groupForm: {
            name: 'Название группы',
            nameHint: 'Название вашей группы',
            color: 'Цвет',
            status: 'Статус группы',
            disabledWarn: 'Все ссылки в этой группе будут неактивны, если вы отключите группу.',
        },
        delete: 'Удалить',
        deleteHint: 'Удалить эту группу, все ссылки в этой группе и связанную статистику.',
        confirmDelete: 'Удалить группу и ее ссылки?',
        confirmDeleteHint: 'Это удалит эту группу и все ссылки в этой группе',
    },

    // Links screen
    links: {
        links: 'Мои ссылки',
        linksHint: 'Управление вашими короткими ссылками в этой группе. Нажмите кнопки справа, чтобы просмотреть статистику кликов по короткой ссылке, журнал запросов и сгенерировать QR-код для вашей ссылки. Выберите ссылки с помощью флажка слева, чтобы удалить или отключить ссылку или переместить её в другую группу.',
        newLinkDialog: 'Новая короткая ссылка',
        newLinkDialogHint: 'Создайте новую короткую ссылку. Щелкните "Дополнительные настройки", чтобы установить описание вашей ссылки, срок действия, пароль доступа и домен.',
        selected: 'Выбранные',
        newLinkForm: {
            type: 'Тип',
            advanced: 'Дополнительные настройки',
            advancedHint: 'Добавьте описание ссылки, настройте пароль доступа, укажите срок действия и многое другое...',
            domain: 'Домен короткой ссылки',
            key: 'Ключ ссылки (необяз.)',
            keyHint: 'Оставьте пустым для случайного ключа',
            expiry: 'Срок годности (необяз.)',
            expiryHint: 'Оставьте пустым для бессрочной',
            descr: 'Описание (необяз.)',
            descrHint: 'Добавьте краткое примечание для себя',
            password: 'Пароль доступа (необяз.)',
            passwordHint: 'Защитите ссылку паролем',
            yourNewLink: 'Ваша новая короткая ссылка:',
        },
        linksTable: {
            type: 'Тип',
            url: 'URL ссылки',
            clicks: 'Клики',
            status: 'Статус',
            actions: 'Действия',
            qrHint: 'Создать QR-код',
            statsHint: 'Просмотр статистики',
            logHint: 'Журнал логов',
            search: 'Поиск',
            resetSearch: 'Очистить параметры поиска',
            noData1: 'Нет ссылок в этой группе...',
            noData2: 'Нажмите кнопку "Создать новую..." для создания новой короткой ссылки.',
            noResults: 'Нет ссылок, соответствующих вашему запросу...',
            loading: 'Загрузка данных...',
        },
        buttons: {
            unsetDate: "Бессрочно",
            addmore: "Добавить еще",
            move: {
                label: 'Переместить',
                hint: 'Переместить выбранные ссылки в другую группу',
            },
            disable: {
                label: 'Отключить',
                hint: 'Временно отключить выбранные ссылки',
            },
            delete: {
                label: 'Удалить',
                hint: 'Удалить выбранные ссылки',
            },
            new: {
                label: 'Создать новую...',
                hint: 'Создать новую короткую ссылку',
            },
            search: {
                label: 'Поиск',
                hint: 'Поиск ссылок',
            },
            enable: {
                label: 'Включить',
                hint: 'Включить выбранные ссылки',
            },
        },
        messages: {
            LINK_ADDED: 'Создана новая короткая ссылка',
            LINKS_DELETED: 'Ссылки удалены',
            LINKS_DISABLED: 'Ссылки отключены',
            LINKS_ENABLED: 'Ссылки включены',
            LINKS_MOVED: 'Ссылки перемещены',
        },
        status: {
            0: 'Все',
            1: 'Включены',
            2: 'Отключены',
            3: 'Истекшие',
            4: 'Неактивные',
        },
        types: {
            all: {
                name: 'Все',
            },
            web: {
                name: 'Веб-страница',
                descr: 'Ссылка на любую веб-страницу',
                dataName: 'Адрес страницы',
                dataHint: 'Введите полный адрес страницы, начиная с http:// или https://',
            },
            phone: {
                name: 'Номер телефона',
                descr: 'Ссылка на номер телефона',
                dataName: 'Номер телефона',
                dataHint: 'Введите полный номер телефона без пробелов и тире',
            },
            email: {
                name: 'Адрес электронной почты',
                descr: 'Ссылка на адрес электронной почты',
                dataName: 'Email',
                dataHint: 'Введите адрес электронной почты',
            },
            telegram: {
                name: 'Telegram',
                descr: 'Ссылка на профиль в Telegram',
                dataName: 'Никнейм в Telegram',
                dataHint: 'Введите никнейм в Telegram',
            },
            whatsapp: {
                name: 'WhatsApp',
                descr: 'Ссылка на профиль в WhatsApp',
                dataName: 'Номер телефона',
                dataHint: 'Введите полный номер телефона WhatsApp без пробелов и тире',
            },
            instagram: {
                name: 'Instagram',
                descr: 'Ссылка на профиль в Instagram',
                dataName: 'Никнейм в Instagram',
                dataHint: 'Введите никнейм профиля в Instagram',
            },
            linkedin: {
                name: 'LinkedIn',
                descr: 'Ссылка на профиль в LinkedIn',
                dataName: 'Никнейм профиля',
                dataHint: 'Введите никнейм профиля в LinkedIn',
            },
            youtube: {
                name: 'Youtube',
                descr: 'Ссылка на канал YouTube',
                dataName: 'Идентификатор канала или обработка',
                dataHint: 'Введите идентификатор канала YouTube или хэндл, начиная с @',
            },
            facebook: {
                name: 'Facebook',
                descr: 'Ссылка на профиль в Facebook',
                dataName: 'Имя профиля',
                dataHint: 'Введите имя профиля в Facebook',
            },
        },
        confirmDelete: 'Удалить выбранные ссылки?',
        confirmDeleteHint: 'Это удалит выбранные короткие ссылки и все связанные статистические данные',
        confirmDisable: 'Отключить выбранные ссылки?',
        confirmDisableHint: 'Это временно отключит выбранные короткие ссылки без их удаления',
        confirmEnable: 'Включить выбранные ссылки?',
        confirmEnableHint: 'Это включит выбранные короткие ссылки',
        moveDialog: 'Переместить ссылки',
        moveDialogHint: 'Переместите выбранные ссылки в другую группу.',
        moveForm: {
            name: 'Название группы',
            nameHint: 'Дайте группе любое название',
            newGroup: 'Создать новую группу...',
        },
    },

    // Link screen
    link: {
        backHint: 'Назад к ссылкам',
        link: 'Настройки ссылки',
        linkHint: 'Управление данными и настройками ссылки.',
        filters: 'Фильтры трафика',
        filtersHint: 'Фильтрация нежелательных посетителей по стране, IP-адресу или используемому устройству и программному обеспечению посетителя. Фильтры зависят от данных, отправляемых программным обеспечением посетителя, и могут быть неточными.',
        filterType: 'Тип фильтра:',
        deactivated: 'Ссылка деактивирована',
        linkForm: {
            created: 'Ссылка создана:',
            clicks: 'Всего кликов:',
            clicks_ok: 'Всего одобрено:',
            clicks_blocked: 'Всего заблокировано:',
            type: 'Тип:',
            descr: 'Описание (необяз.)',
            descrHint: 'Добавьте краткое примечание для себя',
            group: 'Группа',
            status: 'Статус',
            expiry: 'Дата истечения срока (необяз.)',
            expiryHint: 'Оставьте пустым для бессрочной',
            password: 'Пароль доступа (необяз.)',
            passwordHint: 'Защитите ссылку паролем',
        },
        buttons: {
            unsetDate: "Бессрочно",
        },
        messages: {
            LINK_DELETED: 'Короткая ссылка удалена',
        },
        delete: 'Удалить',
        deleteHint: 'Удалить эту ссылку и всю собранную статистику.',
        confirmDelete: 'Удалить короткую ссылку?',
        confirmDeleteHint: 'Это удалит вашу короткую ссылку и всю связанную статистику',
    },


    // Filters
    filters: {
        types: {
            all: {
                name: 'Все',
            },
            source: {
                name: 'Источник трафика',
                descr: 'Блокировать ботов, прямой трафик и ссылки с веб-сайтов',
                allow_bots: 'Разрешить ботов поисковых систем',
                allow_direct: 'Разрешить прямой трафик',
                allow_sites: 'Разрешить переходы с других веб-сайтов',
                filterSites: 'Фильтр сайтов-рефереров:',
                addSite: 'Добавить веб-сайт',
                wldescr: 'блокировать все домены, кроме перечисленных ниже',
                bldescr: 'разрешить все домены, кроме перечисленных ниже',
                domains: {
                    0: '',
                    1: 'Разрешенные домены веб-сайтов:',
                    2: 'Заблокированные домены веб-сайтов:',
                },
                addDialog: {
                    title: 'Добавить веб-сайт',
                    hint: 'Добавить новый домен веб-сайта в список',
                    addButton: 'Добавить',
                    domain: 'Домен веб-сайта',
                    helpText: 'Введите полное имя домена веб-сайта или его часть. Например: "google.com", "google", ".com" ... Если вы вводите только часть домена, это затронет все домены, содержащие эту часть. Например: ".com" означает все домены в зоне .com.',
                },
                noSelectedWarn: 'Добавьте разрешенные веб-сайты в список, в противном случае все запросы будут заблокированы.',
            },
            country: {
                name: 'Страны',
                descr: 'Блокировать выбранные страны',
                wldescr: 'блокировать все страны, кроме выбранных',
                bldescr: 'разрешить все страны, кроме выбранных',
                countries: {
                    0: 'Страны',
                    1: 'Разрешенные страны:',
                    2: 'Заблокированные страны:',
                },
                noSelectedWarn: 'Добавьте разрешенные страны в список, в противном случае все запросы будут заблокированы.',
            },
            ip: {
                name: 'IP-адрес',
                descr: 'Блокировать выбранные диапазоны IP-адресов',
                wldescr: 'блокировать все IP-адреса, кроме перечисленных ниже',
                bldescr: 'разрешить все IP-адреса, кроме перечисленных ниже',
                addresses: {
                    0: '',
                    1: 'Разрешенные IP-адреса:',
                    2: 'Заблокированные IP-адреса:',
                },
                addIP: 'Добавить IP-адрес',
                addDialog: {
                    title: 'Добавить IP-адрес',
                    hint: 'Добавить новый IP-адрес или диапазон в список',
                    addButton: 'Добавить',
                    single: 'Одиночный IP-адрес',
                    range: 'Диапазон IP-адресов',
                    from: 'Начало диапазона',
                    to: 'Конец диапазона',
                    ip: 'IP-адрес',
                    mustBeLarger: 'Должен быть больше начала диапазона',
                },
                noSelectedWarn: 'Добавьте разрешенные IP-адреса в список, в противном случае все запросы будут заблокированы.',
            },
            device: {
                name: 'Тип устройства',
                descr: 'Блокировать выбранные типы устройств',
                allowed: 'Разрешенные устройства:',
                allow_desktop: 'Компьютеры и ноутбуки',
                allow_mobile: 'Мобильные устройства',
                allow_tv: 'ТВ, автомультимедиа, приставки',
                allow_wear: 'Носимые и периферийные устройства',
                allow_other: 'Другие и неизвестные устройства',
            },
            software: {
                name: 'Программное обеспечение',
                descr: 'Блокировать операционные системы и браузеры',
            },
        },
        action: 'Действие:',
        actions: {
            0: 'Не фильтровать',
            1: 'Белый список',
            2: 'Черный список',
        },
    },

    // Rules
    rules: {
        rules: 'Правила перенаправления',
        rulesHint: 'Создавайте альтернативные места назначения ссылок в зависимости от языка браузера посетителя, страны посетителя и программного обеспечения браузера.',
        noRulesHint: 'Нажмите кнопку "Новое правило", чтобы создать свое первое правило...',
        buttons: {
            add: {
                label: 'Новое правило',
                hint: 'Создать новое правило перенаправления',
            },
        },
        ruleDialog: {
            titleAdd: 'Добавить правило',
            hintAdd: 'Добавить новое правило в список',
            titleEdit: 'Изменить правило',
            hintEdit: 'Изменить настройки правила',
            priority: 'Приоритет',
        },
        ruleForm: {
            type: 'Тип',
            priority: 'Приоритет правила: ',
            if: 'Если...',
            select: 'Выберите:',
        },
        rulesTable: {
            priority: 'Пр-т',
            if: 'Если...',
            to: 'Перенаправить на',
            actions: 'Действия',
            never: 'Никогда',
        },
        confirmDel: 'Удалить правило?',
        redirectto: 'Перенаправить на:',
        types: {
            all: {
                name: 'Все',
            },
            lang: {
                name: 'Язык браузера',
            },
            country: {
                name: 'Страна посетителя',
            },
            device: {
                name: 'Источник трафика',
            },
            os: {
                name: 'Операционная система',
            },
            browser: {
                name: 'Браузер',
            },
        },
        devices: {
            bot: 'Поисковые боты',
            desktop: 'Компьютеры и ноутбуки',
            mobile: 'Мобильные устройства',
            tv: 'ТВ, автомультимедиа, приставки',
            wear: 'Носимые и периферийные устройства',
        },
    },


    // Link stats screen
    stats: {
        backHint: 'Назад к ссылкам',
        linkSettings: 'Настройки ссылки',
        linkLogs: 'Журнал логов',
        stats: 'Статистика ссылки',
        statsHint: 'Просмотр статистики по кликам на ссылку. Выберите период, за который хотите видеть статистику, нажав на предустановленные значения ниже.',
        noStatsHint: 'Ничего за этот период',
        statsFor: 'Показать статистику за:',
        periods: {
            today: 'Сегодня',
            yesterday: 'Вчера',
            day: 'Дата...',
            thisMonth: 'Этот месяц',
            prevMonth: 'Прошлый месяц',
            month: 'Месяц...',
            selectDates: 'Выбрать даты...',
        },
        sections: {
            clicks: 'Клики',
            clicksHint: 'Узнайте, сколько у вас кликов, сколько из них были одобрены и заблокированы из-за фильтров или пароля. Посмотрите, откуда приходят ваши пользователи.',
            sources: 'Источники трафика:',
            // sourcesHint: 'This chart shows where your link visitors come from, links from other websites, qr code or directly typed address.',
            results: 'Одобрено / Заблокировано:',
            devices: 'Устройства и ПО',
            devicesHint: 'Узнайте, какие устройства и программное обеспечение используют ваши посетители.',
            deviceTypes: 'Типы устройств:',
            os: 'Операционные системы:',
            browsers: 'Браузеры:',
            clientTypes: 'Типы клиентов:',
            searchBots: 'Поисковые боты:',
            clientLangs: 'Языки браузера:',
            geo: 'География',
            geoHint: 'География кликов. Узнайте, из каких стран приходят ваши пользователи. Эта информация может быть неточной, если используется служба VPN.',
            showBots: 'Показать поисковых ботов',
        },
        charts: {
            1: {
                clicks: 'Клики:',
                clicks_success: 'Одобрено:',
                clicks_blocked: 'Заблокировано:',
                success: 'Одобрено',
                blocked: 'Заблокировано',
            },
            2: {
                success: 'одобрено',
                blocked: 'заблокировано',
            },
        },
    },

    // Logs screen
    logs: {
        backHint: 'Назад к ссылкам',
        linkSettings: 'Настройки ссылки',
        linkStats: 'Статистика ссылки',
        logs: 'Журнал логов',
        logsHint: 'Просмотр журнала логов по ссылке. Выберите период, за который хотите видеть журнал, нажав на предустановленные значения ниже.',
        logsFor: 'Показать логи за:',
        logDialog: 'Полный лог',
        periods: {
            today: 'Сегодня',
            yesterday: 'Вчера',
            day: 'Дата...',
        },
        buttons: {
            search: {
                label: 'Поиск',
                hint: 'Поиск записей в логах',
            },
            view: {
                label: 'Просмотр',
                hint: 'Просмотр полной записи лога',
            },
        },
        logsTable: {
            when: 'Когда',
            ip: 'IP-адрес',
            country: 'Страна',
            locale: 'Локаль',
            source: 'Источник',
            device: 'Устройство',
            os: 'ОС',
            browser: 'Браузер',
            result: 'Результат',
            actions: 'Действия',
        },
        devices: {
            0: 'Неизвестно',
            1: 'Поисковый бот',
            2: 'Компьютер/Ноутбук',
            3: 'Смартфон',
            4: 'Планшет',
            5: 'Функциональный телефон',
            6: 'Консоль',
            7: 'ТВ',
            8: 'Автомультимедиа',
            9: 'Смарт-дисплей',
            10: 'Камера',
            11: 'Медиаплеер',
            12: 'Фаблет',
            13: 'Умный динамик',
            14: 'Носимое устройство',
            15: 'Периферийное устройство',
        },
        clients: {
            0: 'Неизвестно',
            1: 'Поисковый бот',
            2: 'Браузер',
            3: 'Читалка',
            4: 'Библиотека',
            5: 'Медиаплеер',
            6: 'Мобильное приложение',
            7: 'Мессенджер',
        },
        results: {
            1: 'Одобрено',
            2: 'Нет пароля',
            3: 'Неверный пароль',
            11: 'Фильтр страны',
            12: 'Фильтр трафика',
            13: 'Фильтр устройства',
            14: 'Фильтр IP',
        },
        sources: {
            bot: 'Поисковой бот',
            direct: 'Прямой трафик',
            qr: 'QR-код',
        },
        tables: {
            when: 'Когда:',
            source: 'Источник:',
            destination: 'Перенаправлено на:',
            blocked: 'Заблокировано: ',
            device: {
                label: 'Устройство',
                type: 'Тип устройства:',
                brand: 'Марка устройства:',
                model: 'Модель устройства:',
            },
            os: {
                label: 'Операционная система',
                name: 'Название:',
                version: 'Версия:',
            },
            geo: {
                label: 'Гео IP',
                ip: 'IP-адрес:',
                country: 'Код страны:',
                coords: 'Координаты:',
            },
            soft: {
                label: 'Программное обеспечение',
                clientType: 'Тип клиента:',
                locale: 'Языковая локаль:',
                browserName: 'Название браузера:',
                browserVer: 'Версия браузера:',
                clientName: 'Название клиента:',
                clientVer: 'Версия клиента:',
            },
            bot: {
                label: 'Поисковой бот',
                name: 'Название бота:',
            },
        },
    },

    // API keys screen
    apikeys: {
        apikeys: 'Ключи API',
        apikeysHint: 'Управление вашими ключами API. Ключ API необходим для доступа к вашей учетной записи через API. Доступ к API позволяет управлять ваши ссылками и группами программным образом. Каждый ключ API также может быть ограничен на чтение. Держите свои ключи в секрете.',
        noKeys1: 'Для доступа к вашей учетной записи через API требуются ключи API.',
        noKeys2: 'Нажмите кнопку "Создать новый..." для создания вашего первого ключа...',
        neverExpires: 'Бессрочно',
        newKeyDialog: 'Создать новый ключ',
        newKeyDialogHint: 'Создайте новый ключ API.',
        showKeyDialog: 'Просмотр ключа',
        showKeyDialogHint: 'Здесь вы можете просмотреть ключ API и скопировать его в буфер обмена. Храните его в безопасном месте.',
        confirmDel: 'Удалить ключ API?',
        viewDoc: 'API документация',
        status: {
            0: 'Все',
            1: 'Включены',
            2: 'Отключены',
            3: 'Истекшие',
        },
        buttons: {
            new: {
                label: 'Создать новый...',
                hint: 'Создать новый ключ API',
            },
            view: {
                label: 'Просмотр ключа',
                hint: 'Просмотр ключа API',
            },
        },
        messages: {
            KEY_ADDED: 'Ключ API создан',
            KEY_DELETED: 'Ключ API удален',
        },
        keysTable: {
            name: 'Название ключа',
            created: 'Создан',
            permission: 'Разрешение',
            expires: 'Истекает',
            status: 'Статус',
            actions: 'Действия',
            fullPerm: 'Полный',
            roPerm: 'Только чтение',
        },
        newKeyForm: {
            name: 'Название ключа',
            nameHint: 'Дайте новому ключу имя',
            readonly: 'Разрешение только на чтение',
            expiry: 'Срок годности',
            expiryHint: 'Оставьте пустым для бессрочного',
        },
    },

    // Domains screen
    domains: {
        domains: 'Мои домены',
        domainsHint: 'Вы можете использовать свои собственные домены для ваших коротких ссылок. С помощью собственного домена у вас есть полный контроль над URL-адресами ваших ссылок и ключами, усиливая маркетинг вашего бренда. Вы должны быть владельцем домена, который вы хотите добавить. Для начала работы потребуется изменение конфигурации серверов имен для указания вашего домена на наши серверы.',
        noDomains1: 'Вы можете добавить свои собственные домены для ваших коротких ссылок.',
        noDomains2: 'Собственный домен будет доступен только вам и обеспечит эксклюзивный контроль над ключами коротких ссылок. Вы сможете создавать ваши короткие ссылки как https://ваш_домен/ваш_ключ',
        noDomains3: 'Вы должны быть владельцем домена, который вы хотите использовать, и домен не должен использоваться в других веб-сайтах. Вам нужно будет настроить домен на наши DNS сервера для начала использования.',
        noDomains5: 'Нажмите кнопку "Добавить домен", чтобы начать процедуру добавления...',
        newDomainDialog: 'Добавить свой домен',
        newDomainDialogHint: 'Добавьте свой новый собственный домен.',
        confirmDel: 'Запросить удаление домена и всех его ссылок?',
        nsHint: 'Для продолжения добавления нового домена настройте следующие DNS сервера на вашем регистраторе доменов:',
        status: {
            0: 'Все',
            1: 'Включен',
            2: 'Отключен',
            3: 'Обрабатывается',
            4: 'На удалении',
        },
        buttons: {
            new: {
                label: 'Добавить домен',
                hint: 'Добавить свой домен',
            },
        },
        messages: {
            DOMAIN_ADDED: 'Домен добавлен',
            DOMAIN_DELETED: 'Отмечен для удаления',
        },
        domainsTable: {
            domain: 'Домен',
            added: 'Добавлен',
            count: 'Ссылки',
            status: 'Статус',
            actions: 'Действия',
        },
        newDomainForm: {
            domain: 'Имя домена',
            domainHint: 'Имя вашего домена',
            helpText: 'Вы должны быть владельцем домена, который вы хотите добавить. Добавленный домен может использоваться только для коротких ссылок. Для начала использования домена потребуется изменение конфигурации DNS серверов у регистратора.',
        },
    },

    // Account screen
    account: {
        account: 'Моя учетная запись',
        accountHint: 'Просмотр настроек вашей учетной записи, изменение вашего имени и пароля.',
        accountForm: {
            email: 'Электронная почта',
            name: 'Имя',
            nameHint: 'Скажите нам, как нам вас звать',
        },
        passForm: {
            current: 'Текущий пароль',
            password: 'Новый пароль',
            password2: 'Подтвердите новый пароль',
        },
        buttons: {
            resend: 'Переслать электронное письмо',
            chpass: 'Изменить пароль',
            change: 'Изменить',
        },
        messages: {
            EMAIL_RESENT: 'Электронное письмо отправлено',
            EMAIL_FAILED: 'Не удалось отправить электронное письмо',
            USER_DELETED: 'Аккаунт помечен для удаления и будет удален через несколько дней.',
            PASSWORD_CHANGED: 'Пароль изменен',
            PASSWORD_FAILED: 'Не удалось изменить пароль',
        },
        emailOk: 'Электронная почта подтверждена',
        emailNok: 'Электронная почта не подтверждена',
        emailNokHint: 'Электронная почта не подтверждена. Пожалуйста, проверьте ваш почтовый ящик на наличие письма с подтверждением.',
        delete: 'Удалить аккаунт',
        deleteHint: 'Запросите полное удаление учетной записи и удаление всех ваших данных.',
        confirmDelete: 'Удалить ваш аккаунт?',
        confirmDeleteHint: 'Это удалит ваш аккаунт, все ваши данные и статистику. Аккаунт не может быть восстановлен после удаления.',
        markedDeletion: 'Этот аккаунт помечен для удаления и будет удален через несколько дней. Свяжитесь с поддержкой, если это действие нужно отменить. Восстановление невозможно после удаления аккаунта.',
        passDialog: 'Изменить пароль',
    },

    // Plans
    plans: {
        betaTestingInfo: 'Этот сервис находится на стадии раскрутки. Мы включим оплату позже. А пока вы можете использовать план PRO бесплатно...',
        upgradeMsg: 'Пожалуйста, повысьте ваш тарифный план, чтобы разблокировать эту функцию...',
        emailNotConfirmed: 'Ваш адрес электронной почты не подтвержден. Для изменения тарифного плана необходимо подтвердить адрес электронной почты. Пожалуйста, проверьте свой почтовый ящик на наличие письма с подтверждением или отправьте его заново из настроек вашей учетной записи...',
        goToSettings: 'Открыть настройки',
        subscription: 'Моя подписка',
        subscriptionHint: 'Управление вашим тарифным планом. Нажмите кнопку "Активировать", чтобы изменить его.',
        curMonth: 'EUR/месяц',
        noCharge: 'БЕСПЛАТНО',
        active: 'Активен',
        unlimited: 'Безлимит',
        months: 'мес.',
        days: 'дн.',
        plans: {
            free: 'Бесплатный',
            basic: 'Базовый',
            pro: 'Про',
            system: 'Системный',
        },
        buttons: {
            activate: {
                label: 'Активировать',
                hint: 'Изменить тарифный план',
            },
        },
        limit_links: 'Всего ссылок:',
        limit_groups: 'Всего групп:',
        limit_life: 'Время жизни ссылок:',
        limit_history: 'История статистики:',
        limit_logs: 'История логов:',
        allow_api: 'Доступ к API:',
        allow_domains: 'Свои домены:',
        allow_keys: 'Свои ключи ссылок:',
        offer: 'Если вам нужны большие лимиты, безлимитный план или опция корпоративной поддержки, свяжитесь с нами по электронной почте, чтобы получить индивидуальное предложение по контракту.',
    },

    // Support
    support: {
        support: 'Поддержка',
        supportHint: 'Свяжитесь с поддержкой, используя указанный ниже адрес электронной почты в случае каких-либо проблем или вопросов. Отправьте свое письмо с адреса, который вы использовали для создания вашей учетной записи, и мы ответим как можно скорее.',
        emailHint: 'Адрес поддержки:',
    },

    // Qr
    qr: {
        qrDialog: 'QR-код',
        qrDialogHint: 'Создайте QR-код для вашей ссылки. Выберите различные настройки, чтобы изменить внешний вид вашего QR-кода. Нажмите кнопку "Сохранить файл", чтобы сохранить изображение QR-кода на ваш диск.',
        eccLevel: 'Уровень коррекции ошибок',
        ecc: {
            1: {label: 'Уровень L (Низкий)', hint: '7% данных могут быть восстановлены'},
            2: {label: 'Уровень M (Средний)', hint: '15% данных могут быть восстановлены'},
            3: {label: 'Уровень Q (Квартальный)', hint: '25% данных могут быть восстановлены'},
            4: {label: 'Уровень H (Высокий)', hint: '30% данных могут быть восстановлены'},
        },
        style: 'Стиль',
        styles: {
            1: 'Классический',
            2: 'Закругленные точки',
            3: 'Закругленное все',
        },
        color: 'Цвет',
        colors: {
            1: 'Черный',
            2: 'Сплошной цвет',
            3: 'Градиент из 2 цветов',
            4: 'Градиент из 3 цветов',
        },
        buttons: {
            save: {
                label: 'Сохранить файл',
                hint: 'Сохранить SVG файл на ваш диск',
            },
        },
        saveSVG: 'Как SVG файл',
        savePNG1: 'Как маленький PNG файл',
        savePNG2: 'Как большой PNG файл',
    },
}